import { useMyndmapApi } from '@/modules/api'
import { useConfigStore } from '@/modules/mynd-config'
import { FormRenderError, apiError } from './types'

import { flow } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'
import * as PIXI from 'pixi.js-legacy'

import { UUID } from 'io-ts-types/lib/UUID'
import { computed } from 'vue'

const configStore = useConfigStore()
const config = computed(() => configStore.getters['getConfig'])

/**
 * Returns the list of image URLs of a form.
 */
function getPageUrls(id: UUID): TE.TaskEither<FormRenderError, string[]> {
	const { getForm, getFormPageEndpoints } = useMyndmapApi(config)

	return pipe(
		getForm(id),
		TE.map((form) => {
			return form.pageCount
		}),
		TE.chain(flow(getFormPageEndpoints(id), TE.fromEither)),
		TE.mapLeft(apiError)
	)
}

/**
 * Positions the form pages in a "Single Page Continuous" layout style.
 */
function positionPages(pages: PIXI.Sprite[]): PIXI.Sprite[] {
	const margin = 50
	let pageTop = 0

	pages.forEach((page) => {
		page.anchor.set(0.5, 0)
		page.x = 0
		page.y = pageTop
		pageTop += page.height + margin
	})

	return pages
}

/**
 * Composition function that loads a form's images as sprites and positions
 * them into a vertical column.
 */
export function usePageLoader(id: UUID) {
	const configStore = useConfigStore()
	const config = computed(() => configStore.getters['getConfig'])
	const { getFormPages } = useMyndmapApi(config)
	return pipe(getPageUrls(id), TE.chainW(getFormPages), TE.map(positionPages))
}
