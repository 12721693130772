import {
	Property,
	IntegerProperty,
	NumberProperty,
	StringProperty,
	EnumProperty,
	isGroupProperty,
	isIntegerProperty,
	isNumberProperty,
	isStringProperty,
	isEnumProperty,
} from '@/models/form/additional-information'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { createNewGenericProperty } from '@/modules/editor/additional-information/properties'
const MAX_RECURSION_DEPTH = 10
const MAX_FIELD_LABEL_LENGTH = 2048
const MAX_DESCRIPTION_LENGTH = 100
const MAX_TITLE_LENGTH = 50
const MIN_TITLE_LENGTH = 3
const MAX_DIGITS_LENGTH = 10
const MIN_ENUM_LENGTH = 1

// Checks for characters between space and tilde on ASCII table (All characters visisble on US keyboard)
const ALL_CHAR_REGEX = /^[ -~]+$/
// Checks for any double underscores, underscores in the front or back, or special chars
const TEXT_INPUT_REGEX = /([^a-zA-Z_ ])|(_{2})|(^[^a-zA-Z]{0}_)|(_[^a-zA-Z]{0}$)/
// Allows only pos integer values for use in non neg number fields
const POS_INTEGER_INPUT = /^(([0-9]*)(\.0)?)$/
// Allows pos/neg integer values ex: -1232.0 | 1232.0 | 1232 | -1232
const INTEGER_INPUT = /^(-?([0-9]*)(\.0)?)$/
// Allows pos/neg number values ex -1232.12 | 1232.12 | 1232 | -1232
const DECIMAL_INPUT = /^(-?([0-9]*)\.?\d+)$/
// Get all the non digits from a string
const NON_DIGIT_REGEX = /[^\d]+/gm
// Checks for all whitespace (spaces, tabs, vertical tabs, formfeeds, line breaks, etc)
const ALL_WHITESPACES = /\s/
// Checks for leading, trailing, and multiple spaces
const LEADING_TRAILING_DOUBLE_SPACES = /^\s+.*|.*\s+$|.*\s{2,}.*/

export interface InvalidProperty {
	property: Property | Property[]
	errorMessage: string
}

function createInvalidProperty(
	property: Property | Property[] | null | undefined,
	errorMessage: string
): InvalidProperty {
	if (!property) property = createNewGenericProperty()
	return {
		property,
		errorMessage,
	}
}

/**
 * Checks is the modelValue is in the proper format
 * @param str
 */
function validateModelKey(str: string, property: Property): E.Either<InvalidProperty[], true> {
	if (!str) {
		const result = createInvalidProperty(property, `Model value is empty`)
		return E.left([result])
	}

	const result = !TEXT_INPUT_REGEX.test(str) && !str.includes(' ')
	if (!result) {
		const result = createInvalidProperty(property, `Property contains spaces`)
		return E.left([result])
	}
	return E.right(true)
}

/**
 * Validates a string length between MIN_TITLE_LENGTH and MAX_TITLE_LENGTH
 * @param item
 * @param maxLength
 * @param minLength
 */
function validateStringLength(
	item: string,
	maxLength: number = MAX_TITLE_LENGTH,
	minLength: number = MIN_TITLE_LENGTH
): boolean {
	return item.trim().length >= minLength && item.trim().length <= maxLength
}

/**
 * Check to see if the input string matches the validation criteria
 * @param property
 * @param item
 * @param allowEmpty
 * @param nullable
 * @param allowSpaces
 */
function validateStringInput(
	property?: Property | null,
	item?: string | null,
	allowEmpty?: boolean | null,
	nullable?: boolean | null,
	allowSpaces?: boolean | null
): E.Either<InvalidProperty[], true> {
	if (item === null && !nullable) {
		const result = createInvalidProperty(property, `Input is null`)
		return E.left([result])
	} else if (item === '' && !allowEmpty) {
		const result = createInvalidProperty(property, 'Input is empty')
		return E.left([result])
	} else if (!!item && (!validateStringLength(item) || TEXT_INPUT_REGEX.test(item))) {
		const result = createInvalidProperty(
			property,
			`Property does not meet the length requirement (${item})`
		)
		return E.left([result])
	} else if (allowSpaces !== null && allowSpaces === false && item && ALL_WHITESPACES.test(item)) {
		const result = createInvalidProperty(property, 'Property contains whitespaces')
		return E.left([result])
	}

	return E.right(true)
}

const maxLength: { [key: string]: number } = {
	title: MAX_TITLE_LENGTH,
	label: MAX_FIELD_LABEL_LENGTH,
	description: MAX_DESCRIPTION_LENGTH,
	enum: MAX_TITLE_LENGTH,
}
const minLength: { [key: string]: number } = {
	title: MIN_TITLE_LENGTH,
	label: MIN_TITLE_LENGTH,
	description: MIN_TITLE_LENGTH,
	enum: MIN_ENUM_LENGTH,
}

/**
 * Check to see if the Section Title matches the validation criteria
 * @param item
 * @param lengthKey
 * @param property
 */
function validateAllCharStringInput(
	item?: string | null,
	lengthKey?: string | null,
	property?: Property
): E.Either<InvalidProperty[], true> {
	const maxLen = maxLength[lengthKey ?? 'title']
	const minLen = minLength[lengthKey ?? 'title']

	if (item === null || item === '' || !item) {
		const result = createInvalidProperty(property, `${lengthKey} is empty`)
		return E.left([result])
	} else if (
		!!item &&
		(!validateStringLength(item, maxLen, minLen) || !ALL_CHAR_REGEX.test(item))
	) {
		const result = createInvalidProperty(
			property,
			`'${item}' does not meet the length criteria for ${lengthKey}, which is ${
				maxLength[lengthKey ?? 'title']
			}`
		)
		return E.left([result])
	} else if (
		['label', 'title', 'enum'].includes(lengthKey ?? '') &&
		LEADING_TRAILING_DOUBLE_SPACES.test(item)
	) {
		const result = createInvalidProperty(
			property,
			`'${item}' contains leading, trailing, or multiple spaces`
		)
		return E.left([result])
	}

	return E.right(true)
}

/**
 * Validates a number is not null/undefined/negative optional params accepted for min/max size and null/negative
 * @param property
 * @param item
 * @param minimum
 * @param maximum
 * @param nullable
 * @param canBeNegative
 * @param canBeZero
 * @param numberIsInteger determine whether incoming number could be a decimal, eg minimum length requirement
 */
function validateNumberInput(
	property?: Property | null,
	item?: number | null | string,
	minimum?: number | null | string,
	maximum?: number | null | string,
	nullable?: boolean | null,
	canBeNegative?: boolean | null,
	canBeZero?: boolean | null,
	numberIsInteger?: boolean | null
): E.Either<InvalidProperty[], true> {
	if (item && (Number.isNaN(item) || typeof item === 'string')) {
		const result = createInvalidProperty(property, `Entered value is not a valid number (${item})`)
		return E.left([result])
	}

	if (item && numberIsInteger && !INTEGER_INPUT.test(item + '')) {
		const result = createInvalidProperty(property, `Entered value has to be an integer (${item})`)
		return E.left([result])
	}

	if (canBeZero === false && item == 0) {
		const result = createInvalidProperty(property, `Entered value cannot be a zero`)
		return E.left([result])
	}

	if (!item && item !== 0 && nullable !== true) {
		const result = createInvalidProperty(property, `Number is null`)
		return E.left([result])
	}
	if (item && item < 0 && !canBeNegative) {
		const result = createInvalidProperty(property, `Number is negative (${item})`)
		return E.left([result])
	}
	if (item && item.toString().replace(NON_DIGIT_REGEX, '').length > MAX_DIGITS_LENGTH) {
		const result = createInvalidProperty(
			property,
			`Number exceeds max allowed digits (${MAX_DIGITS_LENGTH})`
		)
		return E.left([result])
	}
	if (item && item.toString().includes('e')) {
		const result = createInvalidProperty(property, `Number contains 'e'`)
		return E.left([result])
	}

	minimum =
		(minimum && minimum >= Number.MIN_SAFE_INTEGER) || minimum === 0
			? minimum
			: Number.MIN_SAFE_INTEGER
	maximum =
		(maximum && maximum <= Number.MAX_SAFE_INTEGER) || maximum === 0
			? maximum
			: Number.MAX_SAFE_INTEGER

	if (item && item < minimum) {
		const result = createInvalidProperty(property, `Number is below the minimum (${minimum})`)
		return E.left([result])
	}

	if (item && item > maximum) {
		const result = createInvalidProperty(property, `Number is above the maximum (${maximum})`)
		return E.left([result])
	}

	return E.right(true)
}

/**
 *
 * @param property
 * @returns E.right(true) if type is valid, E.left(InvalidProeprty) if not
 */
function validatePropertyType(property: Property): E.Either<InvalidProperty[], true> {
	if (!property.type) {
		const result = createInvalidProperty(property, `Property type is empty`)
		return E.left([result])
	}
	return E.right(true)
}

/**
 * Validate the the given key's value is unique and meets input criteria
 * @param property
 * @param key
 * @param properties
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 */
function validateKeyValueIsUnique(
	property: Property,
	key: keyof Property,
	properties: Property[],
	depth?: number
): E.Either<InvalidProperty[], true> {
	const currentDepth = depth ?? 0
	const propertyValueAsString: string = property[key] as string

	if (currentDepth >= MAX_RECURSION_DEPTH) {
		const result = createInvalidProperty(property, `Property is too deeply nested`)
		return E.left([result])
	}

	for (const checkProperty of properties) {
		const checkPropertyValueAsString: string = checkProperty[key] as string
		if (property.id !== checkProperty.id) {
			if (
				checkProperty.parentId === property.parentId &&
				propertyValueAsString?.toLowerCase() === checkPropertyValueAsString?.toLowerCase()
			) {
				const result = createInvalidProperty(
					property,
					`Property contains a duplicate ${key} in the same group`
				)
				return E.left([result])
			} else if (isGroupProperty(checkProperty)) {
				if (
					E.isLeft(
						validateKeyValueIsUnique(property, key, checkProperty.properties, currentDepth + 1)
					)
				) {
					const result = createInvalidProperty(
						property,
						`Group property contains a duplicate ${key} with another group`
					)
					return E.left([result])
				}
			}
		}
	}
	return E.right(true)
}

/**
 * Check property title, modelValue, and type are valid
 * @param property
 * @param properties
 */
function genericPropertyValuesIsValid(
	property: Property,
	properties: Property[]
): E.Either<InvalidProperty[], true> {
	const labelKey = 'title' in property ? 'title' : 'label'

	const labelCharactersAreValid = validateAllCharStringInput(property[labelKey], labelKey, property)
	const labelValuesAreUnique = validateKeyValueIsUnique(property, labelKey, properties)
	const propertyTypeIsValid = validatePropertyType(property)
	const modelValuesAreValid = validateStringInput(property, property.modelValue)
	const modelKeysAreValid = validateModelKey(property.modelValue, property)
	const modelKeyValuesAreUnique = validateKeyValueIsUnique(property, 'modelValue', properties)
	const descriptionIsValid = isGroupProperty(property)
		? E.right(true)
		: validateAllCharStringInput(
				property.description || 'descriptionFillerToAllowNullValue',
				'description',
				property
		  )

	const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
	const allResults = [
		labelCharactersAreValid,
		labelValuesAreUnique,
		modelValuesAreValid,
		modelKeysAreValid,
		modelKeyValuesAreUnique,
		descriptionIsValid,
		propertyTypeIsValid,
	]
	const result: E.Either<InvalidProperty[], true> = pipe(
		allResults,
		A.sequence(allErrors),
		E.map(() => true)
	)
	return result
}

/**
 * Check text attributes: minLength, and maxLength are valid
 * @param property
 */
function textPropertyIsValid(property: StringProperty): E.Either<InvalidProperty[], true> {
	const minLengthExists = property.minLength != null
	const isMinLengthPositive = Number(property.minLength) > 0
	const maxLengthExists = property.maxLength != null
	const isMaxLengthPositive = Number(property.maxLength) > 0
	const minLengthRequirementIsMet = validateNumberInput(
		property,
		property?.minLength,
		null,
		property?.maxLength,
		true,
		false,
		false
	)
	const maxLengthRequirementIsMet = validateNumberInput(
		property,
		property?.maxLength,
		property?.minLength,
		null,
		true,
		false,
		false
	)
	const isMinLengthValidAndPositive = (): E.Either<InvalidProperty[], true> => {
		if (!minLengthExists) {
			return E.right(true)
		} else {
			return isMinLengthPositive
				? E.right(true)
				: E.left([
						createInvalidProperty(
							property,
							`Minimum length is 0 or negative (${property.minLength})`
						),
				  ])
		}
	}

	const isMaxLengthValidAndPositive = (): E.Either<InvalidProperty[], true> => {
		if (!maxLengthExists) {
			return E.right(true)
		} else {
			return isMaxLengthPositive
				? E.right(true)
				: E.left([
						createInvalidProperty(
							property,
							`Maximum length is 0 or negative (${property.maxLength})`
						),
				  ])
		}
	}

	const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
	const allResults = [
		minLengthRequirementIsMet,
		maxLengthRequirementIsMet,
		isMinLengthValidAndPositive(),
		isMaxLengthValidAndPositive(),
	]
	const result: E.Either<InvalidProperty[], true> = pipe(
		allResults,
		A.sequence(allErrors),
		E.map(() => true)
	)
	return result
}

/**
 * Check number|integer attributes: minimum, and maximum are valid
 * @param property
 */
function numberPropertyIsValid(
	property: NumberProperty | IntegerProperty
): E.Either<InvalidProperty[], true> {
	const minimumIsValid = validateNumberInput(
		property,
		property?.minimum,
		null,
		property?.maximum,
		true,
		true,
		true,
		isIntegerProperty(property)
	)
	const maximumIsValid = validateNumberInput(
		property,
		property?.maximum,
		property?.minimum,
		null,
		true,
		true,
		true,
		isIntegerProperty(property)
	)

	const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
	const allResults = [minimumIsValid, maximumIsValid]
	const result: E.Either<InvalidProperty[], true> = pipe(
		allResults,
		A.sequence(allErrors),
		E.map(() => true)
	)
	return result
}

/**
 * Check enum attribute is valid
 * @param property
 * @param input
 */
function enumPropertyIsValid(
	property: EnumProperty,
	input?: string
): E.Either<InvalidProperty[], true> {
	const options = [...property.enum]

	if (input) {
		if (options.some((option) => option.toLowerCase() === input.toLowerCase())) {
			const result = createInvalidProperty(property, `This property contains duplicates`)
			return E.left([result])
		}
		options.push(input)
	} else {
		if (new Set(options).size !== options.length) {
			const result = createInvalidProperty(property, `This property contains duplicates`)
			return E.left([result])
		}
	}

	if (!options.length || (options.length === 1 && !options[0])) {
		const result = createInvalidProperty(property, `This property is empty`)
		return E.left([result])
	}

	for (const value of options) {
		const validationPassed = pipe(validateAllCharStringInput(value, 'enum', property), E.isRight)

		if (!validationPassed) {
			const result = createInvalidProperty(
				property,
				`Title does not meet the length requirement (${value})`
			)
			return E.left([result])
		}
	}

	return E.right(true)
}

/**
 * Checks to see if a property is valid
 * @param property
 * @param properties
 * returns true if property is valid, InvalidProperty if not
 */
function additionalPropertyIsValid(
	property: Property,
	properties: Property[]
): E.Either<InvalidProperty[], true> {
	const genericPropertyIsValid = genericPropertyValuesIsValid(property, properties)
	let specificPropertyTypeIsValid: E.Either<InvalidProperty[], true> = E.of(true)
	if (isStringProperty(property)) {
		specificPropertyTypeIsValid = textPropertyIsValid(property)
	} else if (isNumberProperty(property) || isIntegerProperty(property)) {
		specificPropertyTypeIsValid = numberPropertyIsValid(property)
	} else if (isEnumProperty(property)) {
		specificPropertyTypeIsValid = enumPropertyIsValid(property)
	}

	const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
	const allResults = [genericPropertyIsValid, specificPropertyTypeIsValid]
	const result: E.Either<InvalidProperty[], true> = pipe(
		allResults,
		A.sequence(allErrors),
		E.map(() => true)
	)

	return result
}

/**
 * Iterates through all properties to check that all are valid
 * @param properties
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 * returns true if property is valid, InvalidProperty if not
 */
function additionalInformationIsValid(
	properties: Property[],
	depth?: number
): E.Either<InvalidProperty[], true> {
	const currentDepth = depth ?? 0

	if (currentDepth >= MAX_RECURSION_DEPTH) {
		const result = createInvalidProperty(null, `Property is too deeply nested`)
		return E.left([result])
	}

	function getChildProperties(property: Property[]): Property[] {
		return property.flatMap((p) => {
			if (isGroupProperty(p)) {
				return getChildProperties(p.properties)
			} else {
				return [p]
			}
		})
	}

	const allPropertiesToValidate = getChildProperties(properties).concat(properties)
	const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
	const propertiesAreValid = pipe(
		allPropertiesToValidate,
		A.map((p) => additionalPropertyIsValid(p, properties)),
		A.sequence(allErrors),
		E.map(() => true)
	)

	const propertyTitlesAreUnique: E.Either<InvalidProperty[], true> = pipe(
		properties,
		A.map((p) => validateKeyValueIsUnique(p, 'title', properties || [])),
		A.sequence(allErrors),
		E.map(() => true)
	)

	const propertyModelValuesAreUnique: E.Either<InvalidProperty[], true> = pipe(
		properties,
		A.map((p) => validateKeyValueIsUnique(p, 'modelValue', properties || [])),
		A.sequence(allErrors),
		E.map(() => true)
	)

	const allResults = [propertiesAreValid, propertyTitlesAreUnique, propertyModelValuesAreUnique]

	const result: E.Either<InvalidProperty[], true> = pipe(
		allResults,
		A.sequence(allErrors),
		E.map(() => true)
	)

	return result
}

export {
	MAX_RECURSION_DEPTH,
	MAX_TITLE_LENGTH,
	POS_INTEGER_INPUT,
	INTEGER_INPUT,
	DECIMAL_INPUT,
	validateStringLength,
	validateStringInput,
	validateAllCharStringInput,
	validateNumberInput,
	additionalInformationIsValid,
	validateKeyValueIsUnique,
	validateModelKey,
	additionalPropertyIsValid,
	textPropertyIsValid,
	enumPropertyIsValid,
	validatePropertyType,
}
