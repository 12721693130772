import { v4 as uuidv4 } from 'uuid'
import {
	BASE_CP_DATA_ELEMENT_PATH,
	GroupProperty,
	Property,
	isGroupProperty,
	isDateProperty,
	isPhoneProperty,
	EnumProperty,
} from '@/models/form/additional-information'
import { QualifiedDataElement, DataElement } from '@/models/data/elements'
import { MAX_RECURSION_DEPTH } from '@/modules/editor/additional-information/validation'

function createNewGenericProperty(
	id?: string | null,
	parentId?: string | null,
	type?: string | null,
	label?: string | null,
	modelValue?: string | null,
	fullModelPath?: string | null,
	description?: string | null,
	required?: boolean | null
): Property {
	const idValue = id ?? uuidv4()
	return {
		id: idValue,
		parentId: parentId ?? null,
		type: type ?? null,
		label: label ?? '',
		modelValue: modelValue ?? '',
		fullModelPath: fullModelPath ?? '',
		description: description ?? null,
		required: required ?? false,
	} as Property
}

function createNewGroupProperty(
	id?: string | null,
	parentId?: string | null,
	title?: string | null,
	modelValue?: string | null,
	fullModelPath?: string | null,
	description?: string | null,
	required?: boolean | null,
	properties?: Property[] | null
): GroupProperty {
	const idValue = id ?? uuidv4()
	return {
		id: idValue,
		parentId: parentId ?? null,
		type: 'object',
		title: title ?? '',
		modelValue: modelValue ?? '',
		fullModelPath: fullModelPath ?? '',
		description: description ?? null,
		required: required ?? false,
		properties: properties ?? [],
	} as GroupProperty
}

/**
 * Take an existing model and update the value
 * Ex. (test.test, test1) => test.test1
 * @param oldModel
 * @param name
 */
function updateModelString(oldModel: string, name: string): string {
	const stringArray = oldModel.split('.')
	stringArray[stringArray.length - 1] = name
	return stringArray.join('.')
}

/**
 * Take a property and update the model for it
 * @param property: Property
 * @param parentModel: string?
 */
function updatePropertyModel(property: Property, parentModel?: string): Property {
	const fullModelPath = updateModelString(
		parentModel ? `${parentModel}.${property.modelValue}` : property.fullModelPath,
		property.modelValue
	)
	return { ...property, modelValue: property.modelValue, fullModelPath }
}

/**
 * Map required properties to schema format "required: [string]"
 * @param properties: Property[]
 * @param depth: number?
 */
function mapRequiredFields(properties: Property[] = [], depth?: number): string[] {
	const currentDepth = depth ?? 0
	if (currentDepth >= MAX_RECURSION_DEPTH) return []

	return properties
		.filter((property) => {
			if (isGroupProperty(property)) {
				return mapRequiredFields(property.properties, currentDepth + 1).length
			}
			return property.required
		})
		.map((property) => property.modelValue)
}

const propertyIdentifier = (property: any): string | null => {
	if (isDateProperty(property)) return 'date'
	if (isPhoneProperty(property)) return 'phone'
	return null
}

/**
 * Converts a json schema representation into a Property[]
 * @param properties: This is a json schema representation as any
 * @param parentModel: string?
 * @param parentId: string?
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 */
const mapPropertiesForDisplay = (
	properties: any,
	parentModel?: string,
	parentId?: string,
	depth?: number
): Property[] => {
	const currentDepth = depth ?? 0
	const propertiesForDisplay: Property[] = []
	const required: string[] = properties.required ?? []

	if (currentDepth >= MAX_RECURSION_DEPTH) return []

	Object.entries((properties.properties as Record<string, any>) ?? {}).map(
		([propertyKey, propertyObject]) => {
			const modelString = `${parentModel?.length ? parentModel + '.' : ''}${propertyKey}`
			const isRequired = required?.includes(propertyKey) ?? false

			if (propertyObject.type === 'object') {
				const id = uuidv4()
				propertiesForDisplay.push({
					...propertyObject,
					id,
					parentId: parentId ?? null,
					type: 'object',
					title: propertyObject.title ?? propertyKey,
					modelValue: propertyKey,
					fullModelPath: modelString,
					properties: mapPropertiesForDisplay(propertyObject, modelString, id, currentDepth + 1),
					required: isRequired,
				})
			} else {
				if (propertyObject?.type === 'string' && !!propertyObject.format)
					propertyObject.identifier = propertyIdentifier(propertyObject)
				propertiesForDisplay.push({
					...propertyObject,
					id: uuidv4(),
					parentId: parentId ?? null,
					label: propertyObject.label ?? propertyKey,
					modelValue: propertyKey,
					fullModelPath: modelString,
					required: isRequired,
				})
			}
		}
	)
	return propertiesForDisplay
}

/**
 * May an individual property as a Json schema property for save ignoring not needed key/values
 * @param property
 */
const mapPropertyForSave = (property: Property) => {
	const labelKey = 'title' in property ? 'title' : 'label'
	property[labelKey] = property[labelKey]?.trim()
	const keysToIgnore: string[] = [
		'id',
		'parentId',
		'modelValue',
		'fullModelPath',
		'properties',
		'valid',
		'required',
		'identifier',
	]
	const propertyKeys = Object.keys(property).filter((key) => {
		return !keysToIgnore.includes(key)
	})
	const tempObj: any = {}
	for (const key of propertyKeys) {
		const propertyKey = key as keyof Property
		if (property[propertyKey] !== null && property[propertyKey] !== undefined) {
			tempObj[key] = property[propertyKey]
		}
	}
	return tempObj
}

/**
 * Map additional information to a Json Schema for save
 * @param properties
 * @param depth
 */
const mapPropertiesForSave = (properties: Property[], depth?: number): any => {
	const currentDepth = depth ?? 0
	const propertiesForSave: any = {}

	if (currentDepth >= MAX_RECURSION_DEPTH) return {}

	for (const property of properties) {
		if (!isGroupProperty(property)) {
			propertiesForSave[property.modelValue] = mapPropertyForSave(property)
		} else {
			propertiesForSave[property.modelValue] = {
				...mapPropertyForSave(property),
				required: mapRequiredFields(property.properties, currentDepth),
				properties: mapPropertiesForSave(property.properties, currentDepth + 1),
			}
		}
	}
	return propertiesForSave
}

/**
 * Find a Property by id in the given Property[]
 * @param id: uuid
 * @param properties: Property[]
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 */
const findPropertyByID = (id: string, properties: Property[], depth?: number): Property | null => {
	const currentDepth = depth ?? 0

	if (currentDepth >= MAX_RECURSION_DEPTH) return null

	for (const property of properties) {
		if (property.id === id) {
			return property
		} else if (isGroupProperty(property)) {
			const foundProperty = findPropertyByID(id, property.properties, currentDepth + 1)
			if (foundProperty) {
				return foundProperty
			}
		}
	}

	return null
}

/**
 * Deep copies Property[] contents recursively while inserting the new property in the parents properties.
 * @param parentId: uuid
 * @param properties: Property[]
 * @param newProperty: Property
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 */
const addProperty = (
	parentId: string,
	properties: Property[],
	newProperty: Property,
	depth?: number
): Property[] => {
	const currentDepth = depth ?? 0
	const newProperties: Property[] = []

	if (currentDepth >= MAX_RECURSION_DEPTH) return []

	for (const property of properties) {
		if (!isGroupProperty(property)) {
			newProperties.push(property)
		} else {
			const propertyHolder: GroupProperty = { ...property }
			if (property.id === parentId) {
				newProperty.fullModelPath = `${propertyHolder?.fullModelPath}${
					propertyHolder?.fullModelPath.length ? '.' : ''
				}${newProperty?.modelValue ? newProperty.modelValue : ''}`
				newProperty.parentId = parentId
				propertyHolder.properties.push(newProperty)
			}
			propertyHolder.properties = addProperty(
				parentId,
				property.properties,
				newProperty,
				currentDepth + 1
			)
			newProperties.push(propertyHolder)
		}
	}

	return newProperties
}

/**
 * Deep copies Property[] contents recursively except for the deleted property and children.
 * @param id: uuid
 * @param properties: Property[]
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 */
const deleteProperty = (id: string, properties: Property[], depth?: number): Property[] => {
	const currentDepth = depth ?? 0
	const newProperties: Property[] = []

	if (currentDepth >= MAX_RECURSION_DEPTH) return []

	for (const property of properties) {
		if (property.id !== id && isGroupProperty(property)) {
			const propertyHolder = {
				...property,
				...{ properties: deleteProperty(id, property.properties, currentDepth + 1) },
			}
			newProperties.push(propertyHolder)
		} else if (property.id !== id && !isGroupProperty(property)) {
			newProperties.push({ ...property })
		}
	}
	return newProperties
}

/**
 * Update the property at the given id
 * Description: This method deep copies recursively updating the property that matches the newProperty id.
 * This also ensures the model strings are updated, taking into account group level changes.
 * @param newProperty: Property
 * @param properties: Property[]
 * @param parent: Property?
 * @param depth: number, makes sure the recursion does not go deeper than the MAX_DEPTH
 */
const updateProperty = (
	newProperty: Property,
	properties: Property[],
	parent?: Property,
	depth?: number
): Property[] => {
	const currentDepth = depth ?? 0
	const newProperties: Property[] = []

	if (currentDepth >= MAX_RECURSION_DEPTH) return []

	for (const property of properties) {
		const isMatch = property.id === newProperty.id
		if (!isGroupProperty(property)) {
			const updatedProperty = updatePropertyModel(
				isMatch ? newProperty : property,
				parent?.fullModelPath
			)
			newProperties.push(updatedProperty)
		} else {
			const updatedProperty = updatePropertyModel(
				isMatch ? newProperty : property,
				parent?.fullModelPath
			) as GroupProperty
			const innerProperties = updateProperty(
				newProperty,
				updatedProperty?.properties,
				updatedProperty,
				currentDepth + 1
			)
			newProperties.push({ ...updatedProperty, ...{ properties: innerProperties } })
		}
	}
	return newProperties
}

const mapEnumValues = (property: EnumProperty) => {
	return property.enum.map((value) => ({
		id: value,
		value,
	}))
}

const getDataElementType = (property: Property) => {
	if (isDateProperty(property)) return 'DATE'
	if (isPhoneProperty(property)) return 'PHONE_NUMBER_FIELD'
	return 'STRING'
}

function mapQualifiedDataElement(property: Property): DataElement | null {
	const base = {
		id: `${BASE_CP_DATA_ELEMENT_PATH}.${property.fullModelPath}`,
		name: property.fullModelPath,
	}

	switch (property.type) {
		case 'string':
			return {
				...base,
				type: getDataElementType(property),
			} as DataElement
		case 'number':
			return {
				...base,
				type: 'NUMBER',
			} as DataElement
		case 'integer':
			return {
				...base,
				type: 'NUMBER',
			} as DataElement
		case 'boolean':
			return {
				...base,
				type: 'BOOLEAN',
			}
		case 'array':
			return {
				...base,
				type: 'LIST',
			}
		case 'enum': {
			return {
				...base,
				type: 'ENUM',
				values: mapEnumValues(property),
			} as DataElement
		}
		default:
			return null
	}
}

const mapAdditionalInformationModel = (
	properties: Property[],
	depth?: number
): QualifiedDataElement[] => {
	const currentDepth = depth ?? 0
	let qualifiedDataProperties: QualifiedDataElement[] = []
	if (currentDepth >= MAX_RECURSION_DEPTH) return []

	for (const property of properties) {
		if (property.type !== 'object') {
			const dataElement = mapQualifiedDataElement(property)
			if (dataElement !== null) qualifiedDataProperties.push(dataElement)
		} else {
			const innerPropertiesModel = mapAdditionalInformationModel(
				property.properties,
				currentDepth + 1
			)
			qualifiedDataProperties = qualifiedDataProperties.concat(innerPropertiesModel)
		}
	}
	return qualifiedDataProperties
}

const getChildProperties = (properties: Property[]): string[] => {
	return properties.flatMap((p: Property) => {
		return isGroupProperty(p)
			? getChildProperties(p.properties)
			: [`${BASE_CP_DATA_ELEMENT_PATH}.${p.fullModelPath}`]
	})
}

export {
	mapPropertiesForDisplay,
	findPropertyByID,
	addProperty,
	deleteProperty,
	updateProperty,
	createNewGenericProperty,
	createNewGroupProperty,
	mapPropertiesForSave,
	mapAdditionalInformationModel,
	mapRequiredFields,
	getChildProperties,
}
