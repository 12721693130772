import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
})

export const useEditorStore = () => store
export const createTestEditorStore = () => {
	return new Vuex.Store({
		state,
		getters,
		mutations,
		actions,
	})
}
