
import { defineComponent, computed, ref, onBeforeUnmount } from 'vue'
import { useToolbox } from '@/modules/editor/toolbox/hook'
import Tool from '@/components/Toolbar/Tool'
import { useRouter } from 'vue-router'
import { Event } from '@/modules/editor/toolbox/types'

export default defineComponent({
	name: 'Toolbox',
	components: {
		Tool,
	},

	setup(_, context) {
		const { state, send } = useToolbox()
		const router = useRouter()
		const toolSelect = ref<HTMLElement | null>(null)

		const drawToolIcon = computed(() => {
			if (state.value.matches('fieldType.text')) {
				return 'text-outline'
			} else if (state.value.matches('fieldType.checkbox')) {
				return 'checkbox-outline'
			} else if (state.value.matches('fieldType.radio')) {
				return 'radio-button-on-outline'
			} else if (state.value.matches('fieldType.date')) {
				return 'calendar-outline'
			} else if (state.value.matches('fieldType.phoneNumber')) {
				return 'call-outline'
			} else {
				return 'help-outline'
			}
		})

		const navigateBack = () => {
			router.push('/files')
		}

		const showShortcutsModal = (e: MouseEvent) => {
			context.emit('show-shortcuts-modal', e)
		}

		const dropdownOpen = ref<boolean>(false)

		const closeListener = (e?: MouseEvent) => {
			if (dropdownOpen.value || !toolSelect.value?.contains(e?.target as HTMLElement)) {
				window.removeEventListener('click', closeListener)
				dropdownOpen.value = false
			}
		}

		const handleToolClick = () => {
			send('SELECT_DRAW')
			window.addEventListener('click', closeListener)
			dropdownOpen.value = !dropdownOpen.value
		}

		const dropdownOptionSelect = (event: Event) => {
			send(event)
			closeListener()
		}

		onBeforeUnmount(() => {
			window.removeEventListener('click', closeListener)
		})

		return {
			state,
			toolSelect,
			drawToolIcon,
			dropdownOpen,
			send,
			navigateBack,
			handleToolClick,
			showShortcutsModal,
			dropdownOptionSelect,
		}
	},
})
