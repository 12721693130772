<template>
	<div v-if="mappable">
		<section>
			<div class="toggle-labels">
				<label
					:toggled="!showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Data Model
				</label>
				<label
					:toggled="showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Additional Information
				</label>
			</div>
			<div v-if="showAdditionalInformationModel">
				<vue-select
					label="name"
					@update="setElement($event)"
					:value="element"
					:options="additionalInformationModel"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
			<div v-else>
				<vue-select
					label="name"
					@update="setElement($event)"
					:value="element"
					:options="filteredDataModel"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
		</section>
		<section>
			<label>Format</label>
			<input
				@input="setFormat($event.target.value)"
				:value="field.format"
				placeholder="yyyy-MM-dd"
			/>
			<div class="date-preview">
				<span class="preview">preview</span>
				{{ formatPreview }}
			</div>
		</section>
		<section>
			<table @click="openDocumentation">
				<tr>
					<th>Symbol</th>
					<th>Meaning</th>
					<th>Examples</th>
				</tr>
				<tr>
					<td>y</td>
					<td>Year</td>
					<td>2004;04;2004;2004</td>
				</tr>
				<tr>
					<td>M</td>
					<td>Month</td>
					<td>9;09;Sep;September</td>
				</tr>
				<tr>
					<td>d</td>
					<td>Day</td>
					<td>4;04</td>
				</tr>
			</table>
		</section>

		<UnmappableConversion />
	</div>
	<div v-else>
		<MappableConversion />
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onBeforeMount, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import { DateField, dateFieldFormat } from '@/models/form/definition/field/variants/date'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { findByID } from '@/models/data/model'
import { element } from '@/models/form/definition/field/traits/single-mappable'
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'

export default defineComponent({
	name: 'DateFieldEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<DateField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref<boolean>(false)

		const filterOptions = (options: QualifiedDataElement[]): QualifiedDataElement[] => {
			return options.filter((x) => x.type === 'DATE' || x.type === 'TIME').sort(sortOptions)
		}

		const filteredDataModel = computed<QualifiedDataElement[]>(() => filterOptions(props.dataModel))

		const additionalInformationModel = computed<QualifiedDataElement[]>(() =>
			filterOptions(props.additionalDataModel)
		)

		const elementPath = computed(() => props.field.element ?? O.none)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const setElement = (value: FullyQualifiedPath | null) =>
			context.emit('update', element.set(O.fromNullable(value))(props.field))

		function previewDate(pattern: string) {
			return E.tryCatch(
				() =>
					LocalDateTime.now().format(
						DateTimeFormatter.ofPattern(pattern || 'yyyy-MM-dd').withLocale(Locale.US)
					),
				(error) => error
			)
		}

		const setFormat = (value: string) =>
			context.emit('update', dateFieldFormat.set(value ?? '')(props.field))

		const formatPreview = computed(() =>
			pipe(
				previewDate(props.field.format),
				E.fold(
					() => 'Invalid pattern',
					(s) => s
				)
			)
		)

		const openDocumentation = () => {
			window.open(
				'https://docs.oracle.com/en/java/javase/11/docs/api/java.base/java/time/format/DateTimeFormatter.html',
				'_blank'
			)
		}

		const toggleModelType = () => {
			context.emit('update', element.set(O.none)(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value = pipe(
				elementPath.value,
				O.toNullable,
				(val: FullyQualifiedPath | null) => val?.includes(BASE_CP_DATA_ELEMENT_PATH) ?? false
			)
		}

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		onBeforeMount(() => checkModelType())

		return {
			element: computed(() => O.toNullable(elementRef.value)),
			filteredDataModel,
			setFormat,
			setElement,
			formatPreview,
			openDocumentation,
			showAdditionalInformationModel,
			toggleModelType,
			additionalInformationModel,
		}
	},
})
</script>

<style lang="scss" scoped>
section {
	margin: 8px;
}

input {
	display: block;
	width: 100%;
	padding: 5px;
	border: 1px solid rgba(60, 60, 60, 0.26);
	border-radius: 4px;
	outline: none;
}

ion-icon {
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.element {
	display: grid;
	grid-template-columns: 1fr 24px;
	margin-bottom: 8px;
}

.date-preview {
	margin-top: 8px;
	font-size: 0.9em;
}

.preview {
	color: white;
	padding: 2px 8px;
	margin-right: 4px;
	border-radius: 8px;
	background: blue;
}

table {
	cursor: pointer;
	margin-top: 24px;
	color: #333;
	transition: background 200ms ease-out;

	&:hover {
		background: #eee;
	}
}

table,
th,
td {
	text-align: left;
	border-collapse: collapse;
	padding: 4px 12px;
}
</style>
