import { Locale } from '@js-joda/locale_en-us'
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core'

import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as E from 'fp-ts/lib/Either'
import * as M from 'fp-ts/lib/Monoid'
import * as O from 'fp-ts/lib/Option'

import { State } from './state'
import {
	formField,
	formFields,
	checkboxConditionalId,
	selection,
	radioOptionParentId,
	radioGroupId,
	textFieldDefaultValue,
	textFieldDelimiter,
	dateFieldFormat,
	phoneNumberFieldFormat,
	mappableFlag,
	textFieldMultiline,
	formFieldElements,
	formFieldElement,
} from './optics'

import {
	findPropertyByID,
	getChildProperties,
} from '@/modules/editor/additional-information/properties'
import { Property } from '@/models/form/additional-information'
import { FormField, FormRegistry, radioOption, VisibleField } from '@/models/form/definition/field'
import { TextField } from '@/models/form/definition/field/variants/text'
import { CheckboxField } from '@/models/form/definition/field/variants/checkbox'
import { RadioOption, RadioGroup } from '@/models/form/definition/field/variants/radio'
import { QualifiedDataElement } from '@/models/data/elements'
import { findByID } from '@/models/data/model'
import { isUnmappedDateField } from '@/models/form/definition/field/variants/date'
import { isUnmappedPhoneNumberField } from '@/models/form/definition/field/variants/phone'
import { filterRadioGroups } from '@/models/form/definition/field/filters'
import {
	UnmappableField,
	MappableField,
	SingleMappableField,
} from '@/models/form/definition/field/field'
import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import { UUID } from 'io-ts-types/UUID'
import { DiffDelta, diffModel } from '@/modules/editor/additional-information/diff'
import { ElementRef } from '@/models/form/definition/field/element'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'

/**
 * Returns the list of all fields that exist on the currently loaded form.
 */
function allFields(state: State) {
	return pipe(
		formFields.getOption(state),
		O.getOrElse(() => ({} as FormRegistry)),
		Object.values
	) as FormField[]
}

function visibleFields(state: State) {
	return pipe(
		formFields.getOption(state),
		O.getOrElse(() => ({} as FormRegistry)),
		Object.values,
		A.filter(VisibleField.is)
	)
}

function fieldRegistry(state: State) {
	return pipe(
		formFields.getOption(state),
		O.getOrElse(() => ({} as FormRegistry))
	)
}

const handleMappedField = (element: ElementRef, arr: string[]) => {
	const regex = new RegExp(BASE_CP_DATA_ELEMENT_PATH + '.')
	const value = O.toNullable(element)
	if (value && regex.test(value)) {
		arr.push(value.replace(regex, ''))
	}
}

function allFieldsMappedToAdditionalInformation(state: State) {
	const mappedAditionalInformationProperties: string[] = []
	allFields(state).forEach((field: FormField) => {
		if (SingleMappableField.is(field)) {
			handleMappedField(field.element, mappedAditionalInformationProperties)
		} else if (MappableField.is(field) && field.element.length) {
			field.element.forEach((element) =>
				handleMappedField(element, mappedAditionalInformationProperties)
			)
		}
	})
	return mappedAditionalInformationProperties
}

/**
 * Returns the list of fields that are currently selected.
 */
function selectedFields(state: State) {
	return pipe(
		selection.get(state),
		A.map((id) => formField(id).getOption(state)),
		A.compact
	)
}

function multiline(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => textFieldMultiline(id).getOption(state))
	)
}

function defaultValue(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => textFieldDefaultValue(id).getOption(state))
	)
}

function delimiter(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => textFieldDelimiter(id).getOption(state))
	)
}

function dateFormat(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => dateFieldFormat(id).getOption(state))
	)
}

function formatDate(pattern: string) {
	return E.tryCatch(
		() =>
			LocalDateTime.now().format(
				DateTimeFormatter.ofPattern(pattern || 'yyyy-MM-dd').withLocale(Locale.US)
			),
		(error) => error
	)
}

function dateFormatPreview(state: State) {
	return pipe(
		dateFormat(state),
		E.fromOption(() => new Error('no pattern specified')),
		E.chain(formatDate)
	)
}

function phoneNumberFormat(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => phoneNumberFieldFormat(id).getOption(state)),
		O.flatten
	)
}

function checkboxConditionalEnumId(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => checkboxConditionalId(id).getOption(state))
	)
}

function selectedRadioGroup(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => formField(id).composePrism(radioOption).getOption(state)),
		O.chain((field) =>
			pipe(
				formFields.getOption(state),
				O.map((fields) => Object.values(fields)),
				O.map(filterRadioGroups),
				O.chain(A.findFirst((g) => field.parent === g.id))
			)
		)
	)
}

function selectedRadioGroupId(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) =>
			pipe(
				radioGroupId(id).getOption(state),
				O.alt(() => radioOptionParentId(id).getOption(state))
			)
		)
	)
}

const singleMappableFieldElementByFieldId = (state: State) => (id: UUID) => {
	return pipe(formFieldElement(id).getOption(state), O.flatten)
}

const mappableFieldElementsByFieldId = (state: State) => (id: UUID) => {
	return pipe(
		formFieldElements(id).getOption(state),
		O.fold(
			() => [],
			(elements) => elements
		)
	)
}

function mappable(state: State) {
	return pipe(
		selection.get(state),
		A.head,
		O.chain((id) => mappableFlag(id).getOption(state))
	)
}

const isUnmappedTextField = (field: TextField) =>
	A.compact(field.element).length === 0 && field.defaultValue === ''

const hasBooleanElement = (model: QualifiedDataElement[]) => (checkbox: CheckboxField) => {
	return pipe(
		checkbox.element,
		A.compact,
		A.head,
		O.chain((id) => findByID(model)(id)),
		O.map((t) => t.type === 'BOOLEAN'),
		O.getOrElse(() => false)
	)
}

export const isUnmappedCheckbox = (model: QualifiedDataElement[]) => (checkbox: CheckboxField) =>
	!hasBooleanElement(model)(checkbox) && A.compact(checkbox.conditionalId).length === 0

export const isUnmappedRadioOption = (option: RadioOption) => {
	if (O.isNone(option.conditional)) {
		return true
	} else {
		switch (option.conditional.value.type) {
			case 'ENUM':
				return A.compact(option.conditional.value.ids).length === 0
			case 'BOOLEAN':
				return false
		}
	}
}

export const isUnmappedRadioGroup = (fields: FormField[]) => (group: RadioGroup) => {
	const isRadioGroupUnmapped = group.element.length === 0
	const areRadioOptionsUnmapped = pipe(
		fields,
		A.filter((o): o is RadioOption => RadioOption.is(o) && o.parent === group.id),
		A.map(isUnmappedRadioOption)
	)
	return pipe(areRadioOptionsUnmapped.concat(isRadioGroupUnmapped), M.fold(M.monoidAny))
}

function isUnmappedField(state: State) {
	return (field: FormField) => {
		switch (field.type) {
			case 'TEXT_FIELD':
				return isUnmappedTextField(field)
			case 'CHECK_BOX':
				return isUnmappedCheckbox([...state.model, ...state.additionalInformationModel])(field)
			case 'RADIO_GROUP':
				return isUnmappedRadioGroup(allFields(state))(field)
			case 'RADIO_OPTION':
				return isUnmappedRadioOption(field)
			case 'DATE_FIELD':
				return isUnmappedDateField(field)
			case 'PHONE_NUMBER_FIELD':
				return isUnmappedPhoneNumberField(field)
		}
	}
}

function isUnmappableFieldType(state: State) {
	return (field: FormField) => {
		if (UnmappableField.is(field)) {
			return !field.mappable
		} else if (RadioOption.is(field)) {
			// A radio option is unmappable if the radio group is unmappable.
			return pipe(
				allFields(state),
				filterRadioGroups,
				A.findFirst((g) => field.parent === g.id),
				O.map((g) => !g.mappable),
				O.getOrElse(() => false)
			)
		} else {
			return false
		}
	}
}

function formHasPendingChanges(state: State): boolean {
	return (
		JSON.stringify(state.initialFormData) !== JSON.stringify(state.form) ||
		additionalInformationHasPendingChanges(state)
	)
}

function additionalInformationHasPendingChanges(state: State): boolean {
	return (
		JSON.stringify(state.cachedAdditionalInformationData) !==
		JSON.stringify(state.additionalInformation)
	)
}

function additionalInformation(state: State): Property[] {
	return state.additionalInformation
}

const additionalPropertyByID = (state: State) => (id: string) => {
	return findPropertyByID(id, state.additionalInformation)
}

function selectedAdditionalProperty(state: State): Property {
	return { ...state.selectedProperty } as Property
}

function propertiesValidForSave(state: State): boolean {
	return E.isRight(state.additionalInformationAreValid)
}

function additionalInformationModel(state: State): QualifiedDataElement[] {
	return state.additionalInformationModel
}

function additionalInformationAreValid(state: State) {
	return state.additionalInformationAreValid
}

const getFieldIdsByPropertyId = (state: State) => (propertyId: string) => {
	const property = findPropertyByID(propertyId, [...state.additionalInformation])
	const allChildPropertiesPathsToUpdate = property ? getChildProperties([property]) : []

	function childPropertyFieldIds(propertyPath: string): string[] {
		function matchesPropertyPath(element: FullyQualifiedPath): boolean {
			// propertyPath here is defined in the getChildProperties function definition of properties.ts
			return propertyPath === element
		}

		function isPropertyInSingleMappableField(field: SingleMappableField): boolean {
			return O.fold(() => false, matchesPropertyPath)(field.element)
		}

		function isPropertyInMappableField(field: MappableField): boolean {
			return A.compact(field.element).some(matchesPropertyPath)
		}

		const allFormFields = pipe(
			formFields.getOption(state),
			O.getOrElse(() => ({} as FormRegistry)),
			Object.values
		)

		const singleMappableFieldIds = pipe(
			allFormFields,
			A.filter(SingleMappableField.is),
			A.filter(isPropertyInSingleMappableField),
			A.map((f) => f.id)
		)

		const mappableFieldIds = pipe(
			allFormFields,
			A.filter(MappableField.is),
			A.filter(isPropertyInMappableField),
			A.map((f) => f.id)
		)

		return mappableFieldIds.concat(singleMappableFieldIds)
	}

	return [...new Set(allChildPropertiesPathsToUpdate.flatMap(childPropertyFieldIds))] // convert to set to remove duplicates, then convert back to array
}

function additionalInformationDiff(state: State): DiffDelta[] {
	return diffModel(state.cachedAdditionalInformationData ?? [], state.additionalInformation)
}

export default {
	allFields,
	visibleFields,
	fieldRegistry,
	selectedFields,
	multiline,
	defaultValue,
	delimiter,
	dateFormat,
	dateFormatPreview,
	phoneNumberFormat,
	checkboxConditionalEnumId,
	selectedRadioGroup,
	selectedRadioGroupId,
	mappable,
	isUnmappedField,
	isUnmappableFieldType,
	formHasPendingChanges,
	additionalInformation,
	additionalPropertyByID,
	selectedAdditionalProperty,
	propertiesValidForSave,
	additionalInformationModel,
	getFieldIdsByPropertyId,
	singleMappableFieldElementByFieldId,
	mappableFieldElementsByFieldId,
	additionalInformationDiff,
	additionalInformationHasPendingChanges,
	allFieldsMappedToAdditionalInformation,
	additionalInformationAreValid,
}
