<template>
	<div class="container">
		<div class="unmapped-filter">
			<div>
				<div class="search-row">
					<search-bar :value="searchText" @search="searchValue"></search-bar>
					<button @click="clearFilters" class="ms-button">Clear</button>
				</div>
				<div class="filters">
					<label>
						<input type="radio" name="filter" :value="filters.all" v-model="filterType" />
						<span>All Fields</span>
					</label>
					<br />

					<label>
						<input type="radio" name="filter" :value="filters.unmapped" v-model="filterType" />
						<span>Only Unmapped Fields</span>
					</label>
					<br />

					<label>
						<input type="radio" name="filter" :value="filters.unmappable" v-model="filterType" />
						<span>Only Unmappable Fields</span>
					</label>
					<br />
				</div>
			</div>
		</div>

		<div :data-filters-visible="true" class="navigator-list">
			<div v-for="field of fields" :key="field.id">
				<navigator-item :field="field" :filter="filterType" :search="searchText" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useEditorStore } from '@/modules/editor/store'
import { formFields } from '@/modules/editor/store/optics'
import { FormField } from '@/models/form/definition/field'
import { FieldFilter } from './filter-type'
import NavigatorItem from '@/components/NavigatorItem'

import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import SearchBar from '@/components/SearchBar/SearchBar.vue'

export default defineComponent({
	name: 'NavigatorList',
	components: {
		SearchBar,
		[NavigatorItem.name]: NavigatorItem,
	},
	setup() {
		const store = useEditorStore()
		const searchText = ref<string>('')
		const fields = computed(() => {
			return pipe(
				formFields.getOption(store.state),
				O.map(Object.values),
				O.getOrElse(() => [] as FormField[]),
				A.filter((f) => f.type !== 'RADIO_OPTION')
			)
		})

		const filterType = ref<FieldFilter>(FieldFilter.ALL)
		const searchValue = (search: string) => (searchText.value = search)
		const clearFilters = () => {
			filterType.value = FieldFilter.ALL
			searchText.value = ''
		}

		return {
			fields,
			filterType,
			filters: {
				all: FieldFilter.ALL,
				unmapped: FieldFilter.UNMAPPED,
				unmappable: FieldFilter.UNMAPPABLE,
			},
			searchText,
			searchValue,
			clearFilters,
		}
	},
})
</script>

<style lang="scss">
$filters-height: 120px;

.container {
	height: calc(100vh - 50px);
	display: flex;
	flex-direction: column;
}

.navigator-list {
	flex: 1;
	overflow-y: scroll;
}

.unmapped-filter {
	margin: 8px;
	color: lighten(black, 40%);
	cursor: pointer;
	user-select: none;

	input {
		margin-right: 8px;
		cursor: pointer;
	}
}

.filters {
	font-size: 0.9em;
	pointer-events: auto;
	max-height: $filters-height;
	transition: all 100ms ease-out;
}

.filters-header {
	font-size: 1em;
	padding: 4px 16px;
	width: 100%;
	background: #eee;
	color: #444;
	margin-bottom: 4px;
}

.search-row {
	display: grid;
	grid-template-columns: 80% 20%;
	column-gap: 2px;
}
</style>
