import { useHotkeys } from '@/modules/hotkeys'
import { useEditorStore } from '@/modules/editor/store'

export function useStoreHotkeys() {
	const store = useEditorStore()

	useHotkeys('delete', () => {
		store.commit('removeSelectedFields')
	})

	useHotkeys('backspace', () => {
		store.commit('removeSelectedFields')
	})
}
