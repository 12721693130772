<template>
	<div class="form-mapping-change">
		<span class="delta-label">{{ deltaLabel }}</span>
		<span class="full-model-path">{{ fullModelPath }}</span>

		<ul>
			<li v-for="(change, i) of propertyUpdates" :key="i">
				{{ change }}
			</li>
			<li v-for="field of change.affectedFields ?? []" :key="field.id">
				{{ getFormChangeText(field) }}
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import {
	DiffDelta,
	FormFieldSummary,
	FormMappingChange,
} from '@/modules/editor/additional-information/diff'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'

export default defineComponent({
	name: 'PreviewFormMappingChange',
	props: {
		change: {
			type: Object as PropType<FormMappingChange>,
			required: true,
		},
	},
	setup(props) {
		const changeLabelMap: Record<DiffDelta['type'], string> = {
			PROPERTY_ADDITION: 'ADD',
			PROPERTY_DELETION: 'DELETE',
			PROPERTY_UPDATE: 'UPDATE',
		}

		const deltaLabel = computed(() => {
			return changeLabelMap[props.change.delta.type]
		})

		const propertyLabel = computed(() => {
			switch (props.change.delta.type) {
				case 'PROPERTY_ADDITION':
				case 'PROPERTY_DELETION':
					return props.change.delta.property.label ?? props.change.delta.property.title
				case 'PROPERTY_UPDATE':
					return props.change.delta.newProperty.label ?? props.change.delta.newProperty.title
				default:
					return 'Unknown Property Name'
			}
		})

		const fullModelPath = computed(() => {
			let path = 'Unknown Property Name'
			switch (props.change.delta.type) {
				case 'PROPERTY_ADDITION':
				case 'PROPERTY_DELETION':
					path = props.change.delta.property.fullModelPath
					break
				case 'PROPERTY_UPDATE':
					path = props.change.delta.newProperty.fullModelPath
					break
			}

			const additionalInformationPrefix = new RegExp(`^${BASE_CP_DATA_ELEMENT_PATH}\\.`)
			return path.replace(additionalInformationPrefix, '')
		})

		const propertyUpdates = computed(() => {
			const changes = []

			if (props.change.delta.type === 'PROPERTY_UPDATE') {
				const { oldProperty, newProperty } = props.change.delta

				if (props.change.type === 'FIELD_PROPERTY_RENAME') {
					const message = 'The property full model path was updated'
					changes.push(`${message}: ${oldProperty.fullModelPath} 🠒 ${newProperty.fullModelPath}`)
				}

				if (
					props.change.type === 'FIELD_ENUM_UNMAP' &&
					props.change.enumValuesToBeUnmapped.length > 0
				) {
					const message = 'The following mapped enum values were removed from the property'
					changes.push(`${message}: ${props.change.enumValuesToBeUnmapped.join(', ')}`)
				}
			}

			return changes
		})

		function getFormChangeText(field: FormFieldSummary): string {
			switch (props.change.type as FormMappingChange['type']) {
				case 'FIELD_PROPERTY_NOOP':
					return 'No form mapping changes'
				case 'FIELD_GROUP_PROPERTY_CHANGE':
					return 'A descendant property was updated'
				case 'FIELD_PROPERTY_UNMAP':
					return `Will be unmapped from ${field.name} (${field.type})`
				case 'FIELD_ENUM_UNMAP':
					return `Will be unmapped from ${field.name} (${field.type})`
				case 'FIELD_PROPERTY_RENAME':
					return `Will be renamed in ${field.name} (${field.type})`
			}
		}

		return {
			deltaLabel,
			propertyLabel,
			fullModelPath,
			propertyUpdates,
			getFormChangeText,
		}
	},
})
</script>

<style lang="scss" scoped>
.form-mapping-change {
	margin-bottom: 8px;
	margin-top: 20px;
	color: #333;
}

.delta-label {
	margin: 0 8px;
	padding: 4px 8px;
	border-radius: 4px;
	background-color: black;
	font-size: 0.9em;
	color: white;
}

.full-model-path {
	padding: 4px 8px;
	background-color: #eee;
	border-radius: 4px;
	font-family: 'Lucida Console', monospace;
	font-size: 0.9em;
	color: #666;
}
</style>
