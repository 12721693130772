
import { defineComponent, computed, ref, watch, onBeforeMount, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import { DateField, dateFieldFormat } from '@/models/form/definition/field/variants/date'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { findByID } from '@/models/data/model'
import { element } from '@/models/form/definition/field/traits/single-mappable'
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'

export default defineComponent({
	name: 'DateFieldEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<DateField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref<boolean>(false)

		const filterOptions = (options: QualifiedDataElement[]): QualifiedDataElement[] => {
			return options.filter((x) => x.type === 'DATE' || x.type === 'TIME').sort(sortOptions)
		}

		const filteredDataModel = computed<QualifiedDataElement[]>(() => filterOptions(props.dataModel))

		const additionalInformationModel = computed<QualifiedDataElement[]>(() =>
			filterOptions(props.additionalDataModel)
		)

		const elementPath = computed(() => props.field.element ?? O.none)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const setElement = (value: FullyQualifiedPath | null) =>
			context.emit('update', element.set(O.fromNullable(value))(props.field))

		function previewDate(pattern: string) {
			return E.tryCatch(
				() =>
					LocalDateTime.now().format(
						DateTimeFormatter.ofPattern(pattern || 'yyyy-MM-dd').withLocale(Locale.US)
					),
				(error) => error
			)
		}

		const setFormat = (value: string) =>
			context.emit('update', dateFieldFormat.set(value ?? '')(props.field))

		const formatPreview = computed(() =>
			pipe(
				previewDate(props.field.format),
				E.fold(
					() => 'Invalid pattern',
					(s) => s
				)
			)
		)

		const openDocumentation = () => {
			window.open(
				'https://docs.oracle.com/en/java/javase/11/docs/api/java.base/java/time/format/DateTimeFormatter.html',
				'_blank'
			)
		}

		const toggleModelType = () => {
			context.emit('update', element.set(O.none)(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value = pipe(
				elementPath.value,
				O.toNullable,
				(val: FullyQualifiedPath | null) => val?.includes(BASE_CP_DATA_ELEMENT_PATH) ?? false
			)
		}

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		onBeforeMount(() => checkModelType())

		return {
			element: computed(() => O.toNullable(elementRef.value)),
			filteredDataModel,
			setFormat,
			setElement,
			formatPreview,
			openDocumentation,
			showAdditionalInformationModel,
			toggleModelType,
			additionalInformationModel,
		}
	},
})
