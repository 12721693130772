
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'Tool',
	props: {
		active: Boolean,
		dropdownOpen: {
			type: Boolean,
			default: false,
		},
		icon: { type: String, required: true },
	},
	setup(props, context) {
		const iconStyle = computed(() =>
			props.active ? props.icon.replace(/-outline$/, '') : props.icon
		)

		const selectTool = (e: MouseEvent) => {
			context.emit('select', e)
		}

		return {
			selectTool,
			iconStyle,
		}
	},
})
