
import PropertyPanel from '@/components/AdditionalInformation/Panels/Properties/PropertyPanel.vue'
import AttributePanel from '@/components/AdditionalInformation/Panels/Attributes/AttributePanel.vue'
import PreviewPanel from '@/components/AdditionalInformation/Panels/Preview/PreviewPanel.vue'

import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useEditorStore } from '@/modules/editor/store'
import { useFormSaving } from '@/modules/editor/save'
import { UUID } from 'io-ts-types/lib/UUID'
import { useRoute } from 'vue-router'
import { toast } from '@/components/Toast'
import { Property } from '@/models/form/additional-information'

export default defineComponent({
	name: 'AdditionalInformation',
	components: {
		PropertyPanel,
		AttributePanel,
		PreviewPanel,
	},
	props: {
		closeHandler: {
			type: Function,
			required: true,
		},
	},
	setup() {
		const store = useEditorStore()
		const isValid = computed<boolean>(() => {
			return store.getters['propertiesValidForSave']
		})
		const formId = ref<UUID | null>(null)
		const isSaving = ref<boolean>(false)
		const mappedFieldsChanges = ref<boolean>(false)
		let saveChanges = (_?: boolean) => {
			toast('formId does not exist')
		}
		const selectedAdditionalProperty = computed<Property>(() => {
			return store.getters['selectedAdditionalProperty']
		})

		enum WizardStep {
			EDIT,
			PREVIEW,
		}

		const wizardStep = ref<WizardStep>(WizardStep.EDIT)
		const switchToEditStep = () => (wizardStep.value = WizardStep.EDIT)
		const switchToPreviewStep = () => (wizardStep.value = WizardStep.PREVIEW)

		const save = () => {
			if (isValid.value) {
				store.commit('saveAdditionalInformation', null)
				saveChanges(mappedFieldsChanges.value)
			}
		}

		onBeforeMount(() => {
			store.commit('setSelectedAdditionalProperty', null)
			store.commit('revalidateAdditionalProperties')
		})

		const route = useRoute()
		onMounted(() => {
			formId.value = route.params.id as UUID
			mappedFieldsChanges.value = false
			const context = useFormSaving(formId.value)
			saveChanges = context.saveAdditionalInformationChanges
			watch(
				() => context.isSaving.value,
				() => (isSaving.value = context.isSaving.value)
			)
		})

		const updateAdditionalProperty = (property: Property) => {
			store.commit('updateAdditionalProperty', property)
		}

		const updateMappedFieldsNeeded = (event: boolean) => {
			mappedFieldsChanges.value = event
		}

		return {
			isValid,
			isSaving,
			updateMappedFieldsNeeded,
			save,

			updateAdditionalProperty,
			selectedAdditionalProperty,

			WizardStep,
			wizardStep,
			switchToEditStep,
			switchToPreviewStep,
		}
	},
})
