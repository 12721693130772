<template>
	<div>
		<text-field-editor
			v-if="fieldType === 'TEXT_FIELD'"
			@update="updateField($event)"
			:field="field"
			:data-model="dataModel"
			:additional-data-model="additionalInformationModel"
			:mappable="mappable"
		/>

		<checkbox-editor
			v-else-if="fieldType === 'CHECK_BOX'"
			@update="updateField($event)"
			:field="field"
			:data-model="dataModel"
			:additional-data-model="additionalInformationModel"
			:mappable="mappable"
		/>

		<radio-group-editor
			v-else-if="fieldType === 'RADIO_GROUP'"
			@update="updateField($event)"
			@resetRadioOptions="resetRadioOptions($event)"
			:field="field"
			:data-model="dataModel"
			:additional-data-model="additionalInformationModel"
			:mappable="mappable"
		/>

		<radio-option-editor
			v-else-if="fieldType === 'RADIO_OPTION'"
			@update="updateField($event)"
			:field="field"
			:group="radioGroup"
			:data-model="dataModel"
			:additional-data-model="additionalInformationModel"
		/>

		<date-field-editor
			v-else-if="fieldType === 'DATE_FIELD'"
			@update="updateField($event)"
			:field="field"
			:data-model="dataModel"
			:additional-data-model="additionalInformationModel"
			:mappable="mappable"
		/>

		<phone-number-field-editor
			v-else-if="fieldType === 'PHONE_NUMBER_FIELD'"
			@update="updateField($event)"
			:field="field"
			:data-model="dataModel"
			:additional-data-model="additionalInformationModel"
			:mappable="mappable"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

import * as O from 'fp-ts/lib/Option'

import { FormField } from '@/models/form/definition/field'
import { useEditorStore } from '@/modules/editor/store'

import TextFieldEditor from './variants/TextField.vue'
import CheckboxEditor from './variants/Checkbox.vue'
import RadioGroupEditor from './variants/RadioGroup.vue'
import RadioOptionEditor from './variants/RadioOption.vue'
import DateFieldEditor from './variants/DateField.vue'
import PhoneNumberFieldEditor from './variants/PhoneNumberField.vue'
import { UUID } from 'io-ts-types/UUID'

export default defineComponent({
	name: 'DataModel',
	components: {
		TextFieldEditor,
		CheckboxEditor,
		RadioGroupEditor,
		RadioOptionEditor,
		DateFieldEditor,
		PhoneNumberFieldEditor,
	},
	setup() {
		const store = useEditorStore()

		const selectedFields = computed(() => store.getters.selectedFields as FormField[])
		const field = computed(() => selectedFields.value?.[0])
		const fieldType = computed(() => field.value?.type)

		const dataModel = computed(() => store.state.model)
		const additionalInformationModel = computed(() => store.state.additionalInformationModel)
		const radioGroup = computed(() => O.toNullable(store.getters.selectedRadioGroup))
		const mappable = computed(() => O.toNullable(store.getters.mappable) ?? false)

		function updateField(field: FormField) {
			store.commit('setField', field)
		}

		function resetRadioOptions(radioGroupId: UUID) {
			store.commit('resetRadioOptions', radioGroupId)
		}

		return {
			field,
			fieldType,
			radioGroup,
			mappable,
			dataModel,
			additionalInformationModel,
			updateField,
			resetRadioOptions,
		}
	},
})
</script>

<style lang="scss">
@import '@/styles/colors.scss';

.toggle-labels {
	margin-bottom: 2px;
}

.toggle-label {
	margin-right: 2px;
	margin-bottom: 2px;
	border: 1px solid $myndshft-orange-300;
	border-radius: 10px;
	outline: none;
	padding: 2px 4px;
	&[toggled='true'] {
		color: white;
		background-color: $myndshft-orange-300;
	}
}
</style>
