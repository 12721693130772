
import { ref, defineComponent, watch, onBeforeMount, PropType } from 'vue'
import { NumberProperty, IntegerProperty } from '@/models/form/additional-information'
import {
	validateNumberInput,
	DECIMAL_INPUT,
	INTEGER_INPUT,
} from '@/modules/editor/additional-information/validation'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'

type NumberPropertyType = NumberProperty | IntegerProperty

export default defineComponent({
	name: 'NumberAttributes',
	props: {
		numberProperty: {
			type: Object as PropType<NumberPropertyType>,
			required: true,
		},
		isInteger: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, context) {
		const property = ref({} as NumberPropertyType)
		const valid = ref<{
			minimum: boolean
			maximum: boolean
		}>({
			minimum: true,
			maximum: true,
		})

		const minimumInput = ref<string | null>(null)
		const maximumInput = ref<string | null>(null)

		function validate(property: NumberPropertyType) {
			const min = property.minimum !== undefined ? property.minimum : null
			const max = property.maximum !== undefined ? property.maximum : null

			valid.value = {
				minimum: pipe(
					validateNumberInput(property, min, null, max, true, true, true, props.isInteger),
					E.isRight
				),
				maximum: pipe(
					validateNumberInput(property, max, min, null, true, true, true, props.isInteger),
					E.isRight
				),
			}
		}

		function isNumber(numberString: string, isInteger: boolean): boolean {
			return (
				(isInteger && INTEGER_INPUT.test(numberString)) ||
				(!isInteger && DECIMAL_INPUT.test(numberString))
			)
		}

		function updateMinimum(numberString: string) {
			if (isNumber(numberString, props.isInteger)) {
				property.value.minimum = minimumInput.value ? Number.parseFloat(minimumInput.value) : null
			} else {
				property.value.minimum = minimumInput.value
			}
			context.emit('update', { ...property.value })
		}

		function updateMaximum(numberString: string) {
			if (isNumber(numberString, props.isInteger)) {
				property.value.maximum = maximumInput.value ? Number.parseFloat(maximumInput.value) : null
			} else {
				property.value.maximum = maximumInput.value
			}
			context.emit('update', { ...property.value })
		}

		watch(
			() => props.numberProperty,
			(numberProperty) => {
				if (property.value.type !== numberProperty?.type) {
					minimumInput.value = null
					maximumInput.value = null
				}

				if (property.value.id !== numberProperty?.id) {
					minimumInput.value =
						numberProperty?.minimum || numberProperty?.minimum === 0
							? `${numberProperty.minimum}`
							: null
					maximumInput.value =
						numberProperty?.maximum || numberProperty?.maximum === 0
							? `${numberProperty.maximum}`
							: null
				}
				property.value = numberProperty as NumberPropertyType
				validate(property.value)
			}
		)

		onBeforeMount(() => {
			property.value = props.numberProperty as NumberPropertyType
			minimumInput.value =
				property.value.minimum || property.value.minimum === 0 ? `${property.value.minimum}` : null
			maximumInput.value =
				property.value.maximum || property.value.maximum === 0 ? `${property.value.maximum}` : null
			validate(property.value)
		})

		return {
			DECIMAL_INPUT,
			INTEGER_INPUT,
			property,
			valid,
			minimumInput,
			maximumInput,
			updateMinimum,
			updateMaximum,
		}
	},
})
