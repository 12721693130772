import { useEditorStore } from '@/modules/editor/store'
import { usePageLoader } from './loader'
import * as PIXI from 'pixi.js-legacy'

import { UUID } from 'io-ts-types/lib/UUID'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as E from 'fp-ts/lib/Either'
import * as IO from 'fp-ts/lib/IO'

/**
 * Mounts the PIXI application onto the DOM.
 */
export function mountApplication(parent: HTMLElement) {
	return (app: PIXI.Application) => () => {
		parent.appendChild(app.view)
	}
}

/**
 * Render a background onto the canvas. The background will handle mouse events
 * for panning and zooming operations.
 */
export function renderBackground(app: PIXI.Application): IO.IO<void> {
	return () => {
		const backgroundSize = 10e6
		const background = new PIXI.Graphics()
			.beginFill(0x333333)
			.drawRect(0, 0, backgroundSize, backgroundSize)
			.endFill()

		background.x = -backgroundSize / 2
		background.y = -backgroundSize / 2
		background.zIndex = 0

		app.stage.addChild(background)
	}
}

/**
 * Render and position the form pages on the canvas.
 */
export function renderPages(formId: UUID) {
	return (app: PIXI.Application): IO.IO<void> =>
		() => {
			const loadFormPages = usePageLoader(formId)
			loadFormPages().then(
				E.fold(
					() => {
						// TODO Refactor so that the renderer receives the loaded form
						// return pipe(error, getFormRenderErrorMessage, toast)
					},
					(pages) => {
						pages.forEach((page, index) => {
							// Store page dimensions
							const store = useEditorStore()
							store.commit('addPage', { index, page })

							// Render the page
							page.zIndex = 1
							app.stage.addChild(page)
						})
					}
				)
			)
		}
}

/**
 * Pan the camera to the first page of the form.
 */
export function panToFirstPage(app: PIXI.Application): IO.IO<void> {
	return () => {
		app.stage.position.x = app.renderer.width / 2
		app.stage.position.y = 50
	}
}

/**
 * Initializes the PIXI application, mounts it onto the DOM, and renders the
 * specified form onto the canvas.
 */
export function initialize(parent: HTMLDivElement, formId: UUID): IO.IO<PIXI.Application> {
	const app = new PIXI.Application({
		backgroundColor: 0x333333,
		resizeTo: parent,
	})

	// Enable interactions
	app.stage.interactive = true

	// Enable z-index sorting
	app.stage.sortableChildren = true

	// prettier-ignore
	return pipe(
		[
			mountApplication(parent),
			renderBackground,
			renderPages(formId),
			panToFirstPage
		],
		A.map((fn) => fn(app)),
		A.array.sequence(IO.io),
		IO.map(() => app)
	)
}
