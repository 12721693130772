<template>
	<div class="property-attributes-container">
		<div class="property-attributes-header">
			<span>Attributes</span>
		</div>
		<div v-if="property.id" class="property-attributes-body">
			<div class="pa-row">
				<div v-if="property.type === 'object'" class="pa-col padding-10">
					<label :for="property.id + '-title'" :valid="`${valid.title}`"> Section Title* </label>
					<textarea
						:id="property.id + '-title'"
						cols="25"
						rows="2"
						v-model="property.title"
						@input="updateProperty(property)"
						@keydown.space="preventLeadingSpace"
					/>
				</div>
				<div v-if="property.type !== 'object'" class="pa-col padding-10">
					<label :for="property.id + '-label'" :valid="`${valid.label}`"> Field Label* </label>
					<textarea
						:id="property.id + '-label'"
						cols="25"
						rows="2"
						:value="property.label"
						@input="updatePropertyLabel($event.target.value, property)"
						@keydown.space="preventLeadingSpace"
					/>
				</div>
				<div class="pa-col padding-10">
					<label :for="property.id + '-model-name'" :valid="`${valid.modelValue}`"> Name* </label>
					<textarea
						:id="property.id + '-model-name'"
						:value="property.modelValue"
						@input="updatePropertyName($event.target.value, property)"
						spellcheck="false"
					/>
				</div>
			</div>
			<div class="pa-row padding-10">
				<label :for="property.id + '-example'"> Full Model Path: </label>
				<div class="pa-model-example" :id="property.id + '-example'">
					{{ property.fullModelPath ? property.fullModelPath : 'No model yet' }}
				</div>
			</div>
			<div v-if="property.type !== 'object'" class="pa-row">
				<label class="type-select" :valid="`${valid.type}`">
					Property Type*
					<vue-select
						label="label"
						@update="typeSelected($event)"
						:value="property.identifier ?? property.type"
						:options="types"
						:reducer="(value) => value.value"
					></vue-select>
				</label>
				<div class="inner-row">
					<div class="tooltip">
						<div v-if="property.identifier ?? property.type" class="tooltip-icon">
							<font-awesome-icon icon="info-circle" />
							<span class="tooltiptext tooltipbottom">{{
								propertyTypeDescriptions[property.identifier ?? property.type]
							}}</span>
						</div>
					</div>
					<div class="pa-checkbox">
						<input
							type="checkbox"
							:id="property.id + '-required'"
							:checked="property.required"
							@change="updateRequired($event.target.checked)"
						/>
						<label :for="property.id + '-required'">Required</label>
					</div>
				</div>
			</div>
			<div v-if="property.type !== 'object'" class="pa-col padding-10">
				<label :for="property.id + '-description'" :valid="`${valid.description}`"
					>Field Description:</label
				>
				<input
					:id="property.id + '-description'"
					v-model.trim="property.description"
					@input="updateProperty(property)"
				/>
			</div>
			<text-attributes
				class="padding-10"
				v-if="isStringProperty(property)"
				:text-property="property"
				@update="updateProperty"
			></text-attributes>
			<number-attributes
				v-if="isIntegerProperty(property) || isNumberProperty(property)"
				:is-integer="isIntegerProperty(property)"
				:number-property="property"
				@update="updateProperty"
			></number-attributes>
			<date-attributes
				v-if="isDateProperty(property)"
				:date-property="property"
				@update="updateProperty"
			></date-attributes>
			<enum-attributes
				v-if="isEnumProperty(property)"
				:enum-property="property"
				@update="updateProperty"
			></enum-attributes>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType, onBeforeMount, watch } from 'vue'
import { createNewGenericProperty } from '@/modules/editor/additional-information/properties'
import {
	Property,
	PropertyInputSelectionTypes,
	PropertySchemaTypes,
} from '@/models/form/additional-information'
import VueSelect from '@/components/Select/VueSelect.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	validateStringInput,
	validateAllCharStringInput,
	validatePropertyType,
} from '@/modules/editor/additional-information/validation'
import TextAttributes from '@/components/AdditionalInformation/Panels/Attributes/TextAttributes.vue'
import NumberAttributes from '@/components/AdditionalInformation/Panels/Attributes/NumberAttributes.vue'
import DateAttributes from '@/components/AdditionalInformation/Panels/Attributes/DateAttributes.vue'
import EnumAttributes from '@/components/AdditionalInformation/Panels/Attributes/EnumAttributes.vue'
import {
	isStringProperty,
	isIntegerProperty,
	isNumberProperty,
	isDateProperty,
	isArrayProperty,
	isEnumProperty,
	NullProperty,
} from '@/models/form/additional-information/property-types'
import { propertyTypeDescriptions } from '@/models/form/additional-information/type-descriptions'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'
import * as A from 'fp-ts/Array'
import { InvalidProperty, validateKeyValueIsUnique } from '@/modules/editor/additional-information'
import { useEditorStore } from '@/modules/editor/store'

export default defineComponent({
	name: 'AttributePanel',
	components: {
		VueSelect,
		DateAttributes,
		TextAttributes,
		NumberAttributes,
		EnumAttributes,
		FontAwesomeIcon,
	},
	emits: ['update'],
	props: {
		selectedAdditionalProperty: {
			type: Object as PropType<Property>,
			required: true,
		},
	},
	setup(props, context) {
		const editToggle = ref<boolean>(false)
		const valid = ref<{
			title?: boolean
			label?: boolean
			modelValue: boolean
			type: boolean
			description: boolean
		}>({
			modelValue: false,
			type: false,
			description: true,
		})

		const store = useEditorStore()

		const property = computed<Property>(() => {
			return props.selectedAdditionalProperty ?? ({} as NullProperty)
		})

		const types: { label: string; value: PropertyInputSelectionTypes }[] = [
			{ label: 'Text', value: 'string' },
			{ label: 'Number', value: 'number' },
			{ label: 'Integer', value: 'integer' },
			{ label: 'Boolean', value: 'boolean' },
			{ label: 'Date', value: 'date' },
			{ label: 'Phone', value: 'phone' },
			{ label: 'Array', value: 'array' },
			{ label: 'Enum', value: 'enum' },
		]

		const labelKey = computed<'title' | 'label'>(() => {
			return 'title' in props.selectedAdditionalProperty ? 'title' : 'label'
		})

		function preventLeadingSpace(event: Event) {
			if (!property.value[labelKey.value]?.length) {
				event.preventDefault()
			}
		}

		// Validates common attributes
		function validate(property: Property) {
			valid.value = {
				[labelKey.value]: pipe(handleLabelValueValidation(), E.isRight),
				modelValue: pipe(handleModelValueValidation(), E.isRight),
				type: E.isRight(validatePropertyType(property)),
				description: pipe(
					validateAllCharStringInput(
						property.description || 'descriptionPlaceholder',
						'description'
					),
					E.isRight
				),
			}
		}

		function handleModelValueValidation() {
			const stringInputIsValid = validateStringInput(
				property.value,
				property.value.modelValue,
				null,
				null,
				false
			)

			const parentProperty = store.getters['additionalPropertyByID'](property.value.parentId)
			const allChildModelValuesAreUnique = validateKeyValueIsUnique(
				property.value,
				'modelValue',
				parentProperty?.properties || []
			)

			const allGroupProperties = store.getters['additionalInformation']
			const allGroupTitlesAreUnique = validateKeyValueIsUnique(
				property.value,
				'modelValue',
				allGroupProperties || []
			)

			const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
			const allResults = [stringInputIsValid, allChildModelValuesAreUnique, allGroupTitlesAreUnique]
			const result = pipe(allResults, A.sequence(allErrors))
			return result
		}

		function handleLabelValueValidation() {
			const allLabelCharactersAreValid = validateAllCharStringInput(
				property.value[labelKey.value],
				labelKey.value,
				property.value
			)
			const parentProperty = store.getters['additionalPropertyByID'](property.value.parentId)
			const allGroupPropertiesToCheck = parentProperty
				? parentProperty?.properties || []
				: store.getters['additionalInformation']
			const singlePropertyToCheck = parentProperty ? 'label' : 'title'
			const allLabelsAndNamesAreUnique = validateKeyValueIsUnique(
				property.value,
				singlePropertyToCheck,
				allGroupPropertiesToCheck
			)

			const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
			const allResults = [allLabelCharactersAreValid, allLabelsAndNamesAreUnique]
			const result = pipe(allResults, A.sequence(allErrors))
			return result
		}

		function updateProperty(property: Property) {
			context.emit('update', property)
			validate(property)
		}

		function updatePropertyName(update: string, property: Property) {
			if (update) {
				let snakeCasedUpdate = update.toLowerCase().replace(/\s+/gm, '_').replace(/-+/gm, '_')
				property.modelValue = snakeCasedUpdate
			} else {
				property.modelValue = ''
			}
			updateProperty(property)
		}

		function updatePropertyLabel(update: string, property: Property) {
			property.label = update
			if (update) {
				updatePropertyName(update, property)
			} else {
				updateProperty(property)
			}
		}

		// Type change resets the property with the new values
		const typeSelected = (type: PropertyInputSelectionTypes | null) => {
			const identifierList = ['date', 'phone']
			const newProperty = createNewGenericProperty()
			newProperty.type = identifierList.includes(type ?? '')
				? 'string'
				: (type as PropertySchemaTypes)
			newProperty.id = property.value.id
			newProperty.label = property.value.label
			newProperty.modelValue = property.value.modelValue
			newProperty.fullModelPath = property.value.fullModelPath
			newProperty.description = property.value.description
			newProperty.required = property.value.required
			newProperty.parentId = property.value.parentId
			if (isStringProperty(newProperty)) {
				const identifier = identifierList.includes(type ?? '') ? type : null
				newProperty.format = identifier
				newProperty.identifier = identifier
			} else if (isArrayProperty(newProperty)) {
				newProperty.items = { type: 'string' }
			}
			if (isEnumProperty(newProperty)) {
				newProperty.enum = []
			}
			validate(newProperty)
			context.emit('update', newProperty)
		}

		const updateRequired = (required: boolean) => updateProperty({ ...property.value, required })

		onBeforeMount(() => {
			validate(property.value)
		})

		watch(
			() => property.value.id,
			() => {
				validate(property.value)
			}
		)

		return {
			property,
			valid,
			types,
			editToggle,
			typeSelected,
			updateRequired,
			updateProperty,
			isStringProperty,
			isIntegerProperty,
			isNumberProperty,
			isDateProperty,
			isEnumProperty,
			preventLeadingSpace,
			updatePropertyLabel,
			updatePropertyName,
			propertyTypeDescriptions,
		}
	},
})
</script>

<style lang="scss">
@import '@/styles/colors.scss';

.property-attributes-container {
	display: flex;
	flex-direction: column;
	width: 700px;
}

.property-attributes-header {
	padding: 8px;
	margin-bottom: 8px;
	color: white;
	font-size: 18px;
	background-color: $myndshft-orange-300;
}

.property-attributes-body {
	padding: 8px;
	overflow-y: scroll;
	overflow-x: auto;
	height: 470px;
}

.pa-row {
	display: flex;
	flex-direction: row;
}

.inner-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 22px;
}

.pa-col {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.type-select {
	width: 40%;
	padding: 0 10px;
}

.pa-checkbox {
	padding: 0 10px;
}

.padding-2 {
	padding: 2px;
}

.padding-10 {
	padding: 10px;
}

.width-50 {
	width: 50%;
}

.width-75 {
	width: 75%;
}

textarea {
	resize: none;
}

.pa-model-example {
	overflow-wrap: break-word;
	width: 400px;
	font-size: 14px;
	&:hover {
		cursor: default;
	}
}

.pa-button {
	margin-left: 5px;
	margin-bottom: 1px;
	font-size: 10px;
}

label {
	&[valid='false'] {
		color: red;
	}
	margin-right: 5px;
}

.tooltip {
	min-width: 16px;

	&-icon {
		position: relative;
		display: inline-block;
	}
}

.tooltip-icon .tooltiptext {
	visibility: hidden;
	width: 275px;
	background-color: $myndshft-gray-800;
	color: #000;
	padding: 5px 8px;
	border-radius: 5px;
}

/* Position of the tooltip text */
.tooltipbottom {
	position: absolute;
	top: 100%;
	left: 50%;
	z-index: 1;
}

.tooltip-icon:hover .tooltiptext {
	visibility: visible;
}
</style>
