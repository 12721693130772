// Additional Information Property Type Descriptions
export const propertyTypeDescriptions: { [key: string]: string } = {
	string:
		'For the user to enter any text. Minimum and maximum length (number of characters) can be optionally set. Can be mapped to text fields.',
	integer:
		'For the user to enter whole numbers (no decimals). Minimum and maximum acceptable values (including negative values) can be optionally set. Can be mapped to text fields.',
	number:
		'For the user to enter numbers (including decimals). Minimum and maximum acceptable values (including negative values) can be optionally set. Can be mapped to text fields.',
	boolean:
		'For the user to enter a true or false (yes or no) value. Can be mapped to checkbox and radio fields.',
	date: 'For the user to enter dates, using a date-picker. Past, past including today, future, and future including today can be optionally set. Can be mapped to date fields.',
	phone:
		'For the user to enter phone numbers (US only). Can be mapped to text and phone number fields.',
	enum: 'For the user to select a single value from the list of values defined here. Can be mapped to text, checkbox, and radio fields.',
	array: 'For the user to enter multiple values, using any text. Can be mapped to text fields.',
}
