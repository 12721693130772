
import { computed, defineComponent, ref, PropType, onBeforeMount, watch } from 'vue'
import { createNewGenericProperty } from '@/modules/editor/additional-information/properties'
import {
	Property,
	PropertyInputSelectionTypes,
	PropertySchemaTypes,
} from '@/models/form/additional-information'
import VueSelect from '@/components/Select/VueSelect.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	validateStringInput,
	validateAllCharStringInput,
	validatePropertyType,
} from '@/modules/editor/additional-information/validation'
import TextAttributes from '@/components/AdditionalInformation/Panels/Attributes/TextAttributes.vue'
import NumberAttributes from '@/components/AdditionalInformation/Panels/Attributes/NumberAttributes.vue'
import DateAttributes from '@/components/AdditionalInformation/Panels/Attributes/DateAttributes.vue'
import EnumAttributes from '@/components/AdditionalInformation/Panels/Attributes/EnumAttributes.vue'
import {
	isStringProperty,
	isIntegerProperty,
	isNumberProperty,
	isDateProperty,
	isArrayProperty,
	isEnumProperty,
	NullProperty,
} from '@/models/form/additional-information/property-types'
import { propertyTypeDescriptions } from '@/models/form/additional-information/type-descriptions'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'
import * as A from 'fp-ts/Array'
import { InvalidProperty, validateKeyValueIsUnique } from '@/modules/editor/additional-information'
import { useEditorStore } from '@/modules/editor/store'

export default defineComponent({
	name: 'AttributePanel',
	components: {
		VueSelect,
		DateAttributes,
		TextAttributes,
		NumberAttributes,
		EnumAttributes,
		FontAwesomeIcon,
	},
	emits: ['update'],
	props: {
		selectedAdditionalProperty: {
			type: Object as PropType<Property>,
			required: true,
		},
	},
	setup(props, context) {
		const editToggle = ref<boolean>(false)
		const valid = ref<{
			title?: boolean
			label?: boolean
			modelValue: boolean
			type: boolean
			description: boolean
		}>({
			modelValue: false,
			type: false,
			description: true,
		})

		const store = useEditorStore()

		const property = computed<Property>(() => {
			return props.selectedAdditionalProperty ?? ({} as NullProperty)
		})

		const types: { label: string; value: PropertyInputSelectionTypes }[] = [
			{ label: 'Text', value: 'string' },
			{ label: 'Number', value: 'number' },
			{ label: 'Integer', value: 'integer' },
			{ label: 'Boolean', value: 'boolean' },
			{ label: 'Date', value: 'date' },
			{ label: 'Phone', value: 'phone' },
			{ label: 'Array', value: 'array' },
			{ label: 'Enum', value: 'enum' },
		]

		const labelKey = computed<'title' | 'label'>(() => {
			return 'title' in props.selectedAdditionalProperty ? 'title' : 'label'
		})

		function preventLeadingSpace(event: Event) {
			if (!property.value[labelKey.value]?.length) {
				event.preventDefault()
			}
		}

		// Validates common attributes
		function validate(property: Property) {
			valid.value = {
				[labelKey.value]: pipe(handleLabelValueValidation(), E.isRight),
				modelValue: pipe(handleModelValueValidation(), E.isRight),
				type: E.isRight(validatePropertyType(property)),
				description: pipe(
					validateAllCharStringInput(
						property.description || 'descriptionPlaceholder',
						'description'
					),
					E.isRight
				),
			}
		}

		function handleModelValueValidation() {
			const stringInputIsValid = validateStringInput(
				property.value,
				property.value.modelValue,
				null,
				null,
				false
			)

			const parentProperty = store.getters['additionalPropertyByID'](property.value.parentId)
			const allChildModelValuesAreUnique = validateKeyValueIsUnique(
				property.value,
				'modelValue',
				parentProperty?.properties || []
			)

			const allGroupProperties = store.getters['additionalInformation']
			const allGroupTitlesAreUnique = validateKeyValueIsUnique(
				property.value,
				'modelValue',
				allGroupProperties || []
			)

			const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
			const allResults = [stringInputIsValid, allChildModelValuesAreUnique, allGroupTitlesAreUnique]
			const result = pipe(allResults, A.sequence(allErrors))
			return result
		}

		function handleLabelValueValidation() {
			const allLabelCharactersAreValid = validateAllCharStringInput(
				property.value[labelKey.value],
				labelKey.value,
				property.value
			)
			const parentProperty = store.getters['additionalPropertyByID'](property.value.parentId)
			const allGroupPropertiesToCheck = parentProperty
				? parentProperty?.properties || []
				: store.getters['additionalInformation']
			const singlePropertyToCheck = parentProperty ? 'label' : 'title'
			const allLabelsAndNamesAreUnique = validateKeyValueIsUnique(
				property.value,
				singlePropertyToCheck,
				allGroupPropertiesToCheck
			)

			const allErrors = E.getApplicativeValidation(A.getSemigroup<InvalidProperty>())
			const allResults = [allLabelCharactersAreValid, allLabelsAndNamesAreUnique]
			const result = pipe(allResults, A.sequence(allErrors))
			return result
		}

		function updateProperty(property: Property) {
			context.emit('update', property)
			validate(property)
		}

		function updatePropertyName(update: string, property: Property) {
			if (update) {
				let snakeCasedUpdate = update.toLowerCase().replace(/\s+/gm, '_').replace(/-+/gm, '_')
				property.modelValue = snakeCasedUpdate
			} else {
				property.modelValue = ''
			}
			updateProperty(property)
		}

		function updatePropertyLabel(update: string, property: Property) {
			property.label = update
			if (update) {
				updatePropertyName(update, property)
			} else {
				updateProperty(property)
			}
		}

		// Type change resets the property with the new values
		const typeSelected = (type: PropertyInputSelectionTypes | null) => {
			const identifierList = ['date', 'phone']
			const newProperty = createNewGenericProperty()
			newProperty.type = identifierList.includes(type ?? '')
				? 'string'
				: (type as PropertySchemaTypes)
			newProperty.id = property.value.id
			newProperty.label = property.value.label
			newProperty.modelValue = property.value.modelValue
			newProperty.fullModelPath = property.value.fullModelPath
			newProperty.description = property.value.description
			newProperty.required = property.value.required
			newProperty.parentId = property.value.parentId
			if (isStringProperty(newProperty)) {
				const identifier = identifierList.includes(type ?? '') ? type : null
				newProperty.format = identifier
				newProperty.identifier = identifier
			} else if (isArrayProperty(newProperty)) {
				newProperty.items = { type: 'string' }
			}
			if (isEnumProperty(newProperty)) {
				newProperty.enum = []
			}
			validate(newProperty)
			context.emit('update', newProperty)
		}

		const updateRequired = (required: boolean) => updateProperty({ ...property.value, required })

		onBeforeMount(() => {
			validate(property.value)
		})

		watch(
			() => property.value.id,
			() => {
				validate(property.value)
			}
		)

		return {
			property,
			valid,
			types,
			editToggle,
			typeSelected,
			updateRequired,
			updateProperty,
			isStringProperty,
			isIntegerProperty,
			isNumberProperty,
			isDateProperty,
			isEnumProperty,
			preventLeadingSpace,
			updatePropertyLabel,
			updatePropertyName,
			propertyTypeDescriptions,
		}
	},
})
