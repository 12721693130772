import { useHotkeys } from '@/modules/hotkeys'
import { useService } from '@xstate/vue'
import { toolboxService } from './machine'

export function useToolbox() {
	const { state, send } = useService(toolboxService)

	useHotkeys('a', () => {
		send('SELECT_MOVE')
	})
	useHotkeys('s', () => {
		send('SELECT_DRAW')
	})
	useHotkeys('d', () => {
		send('SELECT_HAND')
	})
	useHotkeys('q', () => {
		send('SELECT_DRAW')
		send('SELECT_TEXT_FIELD')
	})
	useHotkeys('w', () => {
		send('SELECT_DRAW')
		send('SELECT_DATE_FIELD')
	})
	useHotkeys('e', () => {
		send('SELECT_DRAW')
		send('SELECT_PHONE_NUMBER_FIELD')
	})
	useHotkeys('r', () => {
		send('SELECT_DRAW')
		send('SELECT_CHECKBOX')
	})
	useHotkeys('t', () => {
		send('SELECT_DRAW')
		send('SELECT_RADIO_BUTTON')
	})

	useHotkeys(
		'*',
		(event) => {
			if (event.key === ' ') {
				if (!state.value.matches('tool.hand.temporary.active')) {
					send('SELECT_TEMP_HAND')
				}
			}
		},
		{
			keydown: true,
			keyup: false,
		}
	)

	useHotkeys(
		'*',
		(event) => {
			if (event.key === ' ') {
				if (state.value.matches('tool.hand.temporary.active')) {
					send('SELECT_PREVIOUS')
				}
			}
		},
		{
			keydown: false,
			keyup: true,
		}
	)

	useHotkeys(
		'*',
		(event) => {
			if (event.key === 'Control') {
				send('CONTROL_DOWN')
			}
		},
		{
			keydown: true,
			keyup: false,
		}
	)

	useHotkeys(
		'*',
		(event) => {
			if (event.key === 'Control') {
				send('CONTROL_UP')
			}
		},
		{
			keydown: false,
			keyup: true,
		}
	)

	return {
		state,
		send,
	}
}
