import {
	BoxColorSettings,
	FormField,
	additionalInformationMappedColors,
	mappedFieldColors,
	unmappableFieldColors,
	unmappedFieldColors,
	MappableField,
} from '@/models/form/definition/field'

import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'

import { RadioGroup, RadioOption } from '@/models/form/definition/field/variants/radio'
import { ElementRef } from '@/models/form/definition/field/element'
import { SingleMappableField } from '@/models/form/definition/field/field'
import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { SingleMappableTrait } from '@/models/form/definition/field/traits/single-mappable'
import { MappableTrait } from '@/models/form/definition/field/traits/mappable'
import { UnmappableTrait } from '@/models/form/definition/field/traits/unmappable'
import { RadioEnumConditional } from '@/models/form/definition/field/variants/radio/radio'
import { TextField } from '@/models/form/definition/field/variants/text'

type MappableUnMappableSingleMappableTrait = UnmappableTrait & (SingleMappableTrait | MappableTrait)

const getFieldElements = (field: FormField): ElementRef[] => {
	const singleMappableFieldElementRef: ElementRef = SingleMappableField.is(field)
		? field.element
		: O.none
	const mappableFieldElementRefs: ElementRef[] = MappableField.is(field) ? field.element : []
	return [...mappableFieldElementRefs, singleMappableFieldElementRef]
}

const containsAdditionalInformationPath = (element: FullyQualifiedPath) =>
	element.includes(BASE_CP_DATA_ELEMENT_PATH)

const containsAdditionalInformationElements = (field: FormField): boolean => {
	const elementPaths: FullyQualifiedPath[] = A.compact(getFieldElements(field))
	return elementPaths.some((elementPath) => containsAdditionalInformationPath(elementPath))
}

const isUnmappedFieldTrait = (field: MappableUnMappableSingleMappableTrait) => {
	if (SingleMappableTrait.is(field)) {
		return !O.isSome(field.element)
	} else if (MappableTrait.is(field)) {
		return !A.compact(field.element).length
	} else {
		return false
	}
}

const isUnmappableFieldTrait = (field?: UnmappableTrait): boolean => {
	return !field?.mappable ?? false
}

const containsConditionalOrIds = (radioOptionField: RadioOption): boolean => {
	return pipe(
		radioOptionField,
		(field) => field.conditional,
		O.fold(
			() => false,
			(conditional) => {
				return !(RadioEnumConditional.is(conditional) && !A.compact(conditional.ids).length)
			}
		)
	)
}

const textFieldHasDefaultValue = (field: FormField): boolean => {
	return TextField.is(field) ? !!field.defaultValue : false
}

export const getFieldBoxColors = (field: FormField, parentField?: FormField): BoxColorSettings => {
	if (RadioOption.is(field) && RadioGroup.is(parentField)) {
		if (isUnmappableFieldTrait(parentField)) {
			return unmappableFieldColors
		} else if (!containsConditionalOrIds(field)) {
			return unmappedFieldColors
		} else if (containsAdditionalInformationElements(parentField)) {
			return additionalInformationMappedColors
		} else {
			return mappedFieldColors
		}
	} else if (!RadioOption.is(field)) {
		if (isUnmappableFieldTrait(field)) {
			return unmappableFieldColors
		} else if (isUnmappedFieldTrait(field) && !textFieldHasDefaultValue(field)) {
			return unmappedFieldColors
		} else if (containsAdditionalInformationElements(field)) {
			return additionalInformationMappedColors
		} else {
			return mappedFieldColors
		}
	} else {
		return unmappableFieldColors
	}
}
