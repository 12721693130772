import { Ref, watch } from 'vue'
import { FormFieldPosition } from '@/models/form/definition/field/position'
import {
	additionalInformationMappedColors,
	mappedFieldColors,
	unmappableFieldColors,
	unmappedFieldColors,
	VisibleField,
} from '@/models/form/definition/field'
import { useEditorStore } from '@/modules/editor/store'
import { drawHandles } from './draw'
import * as PIXI from 'pixi.js-legacy'
import { UUID } from 'io-ts-types/UUID'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'

import { ElementRef } from '@/models/form/definition/field/element'
import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { Store } from 'vuex'
import { RadioOption } from '@/models/form/definition/field/variants/radio'

// GraphicsRegistry will handle all things related to bounding boxes
class GraphicsRegistry {
	private graphics: { [id: string]: PIXI.Graphics } = {}
	private linkedIdMappings: { id: UUID; parent: UUID }[] = []
	private stage: PIXI.Container

	constructor(stage: PIXI.Container) {
		this.stage = stage
	}

	addLinkedMapping(id: UUID, parent: UUID) {
		if (!this.linkedIdMappings.includes({ id, parent })) {
			this.linkedIdMappings.push({ id, parent })
		}
	}

	create(id: UUID, parent?: UUID): PIXI.Graphics {
		if (parent) {
			this.addLinkedMapping(id, parent)
		}

		if (id in this.graphics) {
			this.remove(id)
		}

		const instance = new PIXI.Graphics()
		this.stage.addChild(instance)
		this.graphics[id] = instance
		return instance
	}

	get(id: UUID): PIXI.Graphics | undefined {
		return this.graphics[id]
	}

	getAll(): PIXI.Graphics[] {
		return Object.values(this.graphics)
	}

	getChildIds(id: UUID): UUID[] {
		return this.linkedIdMappings
			.filter((child: { id: string; parent: string }) => child.parent === id)
			.map((child) => child.id)
	}

	remove(id: UUID): void {
		const childIds = this.getChildIds(id)

		this.linkedIdMappings = this.linkedIdMappings.filter((mapping) => {
			return !(mapping.id in childIds)
		})

		const ids = [id, ...childIds]

		ids.forEach((id: UUID) => {
			if (id in this.graphics) {
				const instance = this.graphics[id]
				delete this.graphics[id]
				this.stage.removeChild(instance) // remove the actual rendering from view
				instance.destroy() // needed to prevent memory leak
			}
		})
	}

	removeAll(): void {
		Object.keys(this.graphics).forEach((id) => {
			this.remove(id as UUID)
		})
	}
}

function containsAdditionalInformationElements(elements: FullyQualifiedPath[]): boolean {
	return elements.some((element) => element.includes(BASE_CP_DATA_ELEMENT_PATH))
}

function getSingleMappableFieldElementFromStore(store: Store<unknown>, id: UUID): ElementRef {
	return id ? store.getters.singleMappableFieldElementByFieldId(id) : O.none
}

function getMappableFieldElementsFromStore(store: Store<unknown>, id: UUID): ElementRef[] {
	return id ? store.getters.mappableFieldElementsByFieldId(id) : []
}

function getAllChildAndParentElements(
	store: Store<unknown>,
	fieldId: UUID,
	parentId?: UUID | null
): ElementRef[] {
	let elements: ElementRef[] = []

	elements.push(getSingleMappableFieldElementFromStore(store, fieldId))
	elements = elements.concat(getMappableFieldElementsFromStore(store, fieldId))

	if (parentId) {
		elements.push(getSingleMappableFieldElementFromStore(store, parentId))
		elements = elements.concat(getMappableFieldElementsFromStore(store, parentId))
	}
	return elements
}

function createBoundingBox(
	registry: GraphicsRegistry,
	zoomScale: number,
	field: VisibleField
): PIXI.Graphics | null {
	const store = useEditorStore()
	const { pages, selection } = store.state
	const parentId = RadioOption.is(field) ? field.parent : null
	const elements: FullyQualifiedPath[] = A.compact(
		getAllChildAndParentElements(store, field.id, parentId)
	)

	const page = pages[field.page]
	if (page === undefined) {
		return null
	}

	let boundingBox
	if (field.type === 'RADIO_OPTION') {
		boundingBox = registry.create(field.id, field.parent)
	} else {
		boundingBox = registry.create(field.id)
	}

	boundingBox.zIndex = 100

	const start: FormFieldPosition = {
		x: page.x - page.anchor.x * page.width + field.position.x * page.width,
		y: page.y - page.anchor.y * page.width + field.position.y * page.height,
	}

	const end: FormFieldPosition = {
		x: start.x + field.dimensions.width * page.width,
		y: start.y + field.dimensions.height * page.height,
	}

	const pickFieldColors = (f: VisibleField) => {
		if (store.getters.isUnmappableFieldType(f)) {
			return [unmappableFieldColors.backgroundColor, unmappableFieldColors.borderColor]
		} else if (store.getters.isUnmappedField(f)) {
			return [unmappedFieldColors.backgroundColor, unmappedFieldColors.borderColor]
		} else if (containsAdditionalInformationElements(elements)) {
			return [
				additionalInformationMappedColors.backgroundColor,
				additionalInformationMappedColors.borderColor,
			]
		} else {
			return [mappedFieldColors.backgroundColor, mappedFieldColors.borderColor]
		}
	}

	const [fillColor, borderColor] = pickFieldColors(field)

	// Draw bounding box
	boundingBox
		.beginFill(fillColor, 0.5)
		.lineStyle(1 / zoomScale + 1, borderColor, 1)
		.drawRect(
			start.x,
			start.y,
			field.dimensions.width * page.width,
			field.dimensions.height * page.height
		)
		.endFill()

	boundingBox.interactive = true
	boundingBox.on('pointerdown', () => {
		store.commit('setSelection', [field.id])
	})

	if (selection.includes(field.id)) {
		drawHandles(boundingBox, zoomScale, start, end, borderColor)
	}

	return boundingBox
}

/**
 * Composition function that manages the rendering of the bounding boxes. The
 * box manager subscribes to changes in the Vuex store that keeps track of the
 * editor state and performs re-renders the bounding boxes onto the PIXI
 * application.
 */
export function useBoxManager(app: PIXI.Application, zoomScale: Ref<number>) {
	const store = useEditorStore()
	const registry = new GraphicsRegistry(app.stage)

	// rerender all the fields on page or zoomScale changes
	watch(
		() => [store.state.pages, zoomScale.value] as [{ [id: string]: PIXI.Sprite }, number],
		([_newPages, newZoomScale]) => {
			const visibleFields = store.getters.visibleFields
			visibleFields.reverse().forEach((field: VisibleField) => {
				createBoundingBox(registry, newZoomScale, field)
			})
		}
	)

	// only update fields that are/were selected
	watch(
		() => store.getters.selectedFields as VisibleField[],
		(newSelection, previousSelection) => {
			const fieldsToRedraw = [...(previousSelection ?? []), ...(newSelection ?? [])]
			const formRegistry = store.getters.fieldRegistry
			fieldsToRedraw.forEach((field: VisibleField) => {
				// only draw field bounding box if it's in the form
				if (field.id in formRegistry) {
					createBoundingBox(registry, zoomScale.value, field)
				} else {
					registry.remove(field.id)
				}
			})
		}
	)
}
