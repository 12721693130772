<template>
	<span class="wrapper">
		<ion-icon class="tool" @click="selectTool" :name="iconStyle" :data-active="active"></ion-icon>
		<div v-if="dropdownOpen" class="dropdown-content">
			<slot></slot>
		</div>
	</span>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'Tool',
	props: {
		active: Boolean,
		dropdownOpen: {
			type: Boolean,
			default: false,
		},
		icon: { type: String, required: true },
	},
	setup(props, context) {
		const iconStyle = computed(() =>
			props.active ? props.icon.replace(/-outline$/, '') : props.icon
		)

		const selectTool = (e: MouseEvent) => {
			context.emit('select', e)
		}

		return {
			selectTool,
			iconStyle,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.wrapper {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	margin-top: -7px;
	position: absolute;
	z-index: 100;
	width: 100%;
}

.tool {
	margin-top: 6px;
	padding: 0 8px;
	height: 40px;
	font-size: 20px;
	color: white;
	transition: all 100ms ease-out;

	&:hover {
		background-color: $myndshft-orange-300;
	}

	&[data-active='true'] {
		background-color: $myndshft-orange-300;
	}
}
</style>
