<template>
	<div v-if="mappable">
		<section class="multiline">
			<label>Multiline? </label>
			<input
				type="checkbox"
				@input.prevent="setMultiline($event.target.checked)"
				:checked="field.multiline"
			/>
		</section>

		<section>
			<div class="toggle-labels">
				<label
					:toggled="!showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Data Model
				</label>
				<label
					:toggled="showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Additional Information
				</label>
			</div>
			<div v-if="showAdditionalInformationModel">
				<div class="element" v-for="(element, index) of elements" :key="index + 'el-selection'">
					<vue-select
						label="name"
						@update="setElement(index, $event)"
						:value="element"
						:options="filteredAdditionalDataModel"
						:reducer="(element) => element.id"
					></vue-select>
					<ion-icon v-if="index !== 0" name="trash" @click="removeElement(index)"></ion-icon>
				</div>
			</div>
			<div v-else>
				<div class="element" v-for="(element, index) of elements" :key="index + 'cp-el-selection'">
					<vue-select
						label="name"
						@update="setElement(index, $event)"
						:value="element"
						:options="filteredDataModel"
						:reducer="(element) => element.id"
					></vue-select>
					<ion-icon v-if="index !== 0" name="trash" @click="removeElement(index)"></ion-icon>
				</div>
			</div>
			<div v-if="showAddButton">
				<button @click="addElement" class="ms-button ms-slim">Add Additional Value</button>
			</div>
		</section>

		<section>
			<label>
				Delimiter
				<span class="delimiter" v-if="field.delimiter.length > 0">({{ field.delimiter }})</span>
			</label>
			<input @input="setDelimiter($event.target.value)" :value="field.delimiter" maxlength="3" />
		</section>

		<section>
			<label>Default Value</label>
			<input @input="setDefaultValue($event.target.value)" :value="field.defaultValue" />
		</section>

		<UnmappableConversion />
	</div>
	<div v-else>
		<MappableConversion />
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeMount, watch, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { indexArray } from 'monocle-ts/Index/Array'

import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import {
	TextField,
	defaultValue,
	delimiter,
	multiline,
	element,
} from '@/models/form/definition/field/variants/text'
import { ElementRef } from '@/models/form/definition/field/element'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'

export default defineComponent({
	name: 'TextFieldEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<TextField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref(false)
		const filteredAdditionalDataModel = computed(() => filterOptions(props.additionalDataModel))
		const filteredDataModel = computed(() => filterOptions(props.dataModel))
		const showAddButton = computed(() => props.field.element.every(O.isSome))

		const elements = computed(() =>
			props.field.element.length > 0 ? props.field.element : [O.none]
		)

		const filterOptions = (options: QualifiedDataElement[]) =>
			options.filter((e) => !['TIME', 'DATE'].includes(e.type)).sort(sortOptions)

		const setMultiline = (value: boolean) => {
			context.emit('update', multiline.set(value)(props.field))
		}

		const setDefaultValue = (value: string) => {
			context.emit('update', defaultValue.set(value)(props.field))
		}

		const setDelimiter = (value: string) => {
			context.emit('update', delimiter.set(value)(props.field))
		}

		const addElement = () => {
			const newElements = elements.value.concat(O.none)
			context.emit('update', element.set(newElements)(props.field))
		}

		const setElement = (index: number, path: FullyQualifiedPath | null) => {
			const elementAtIndex = indexArray<ElementRef>().index(index)
			const newElements = elementAtIndex.set(O.fromNullable(path))(elements.value)
			context.emit('update', element.set(newElements)(props.field))
		}

		const removeElement = (index: number) => {
			const newElements = pipe(
				elements.value,
				A.deleteAt(index),
				O.getOrElse<ElementRef[]>(() => [])
			)
			context.emit('update', element.set(newElements)(props.field))
		}

		const toggleModelType = () => {
			context.emit('update', element.set([])(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value = elements.value.some((e) =>
				O.toNullable(e)?.includes(BASE_CP_DATA_ELEMENT_PATH)
			)
		}

		onBeforeMount(() => checkModelType())

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		return {
			elements: computed(() => elements.value.map(O.toNullable)),
			showAddButton,
			filteredDataModel,
			filteredAdditionalDataModel,
			defaultValue,
			delimiter,
			multiline,
			setDefaultValue,
			setDelimiter,
			setMultiline,
			addElement,
			setElement,
			removeElement,
			showAdditionalInformationModel,
			toggleModelType,
		}
	},
})
</script>

<style lang="scss" scoped>
section {
	margin: 8px;
}

input {
	display: block;
	width: 100%;
	padding: 5px;
	border: 1px solid rgba(60, 60, 60, 0.26);
	border-radius: 4px;
	outline: none;
}

.multiline {
	margin-top: -8px;
	background: #efefef;
	padding: 4px 8px;
}

input[type='checkbox'] {
	display: inline-block;
	position: relative;
	top: 0px;
	width: auto;
}

ion-icon {
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.element {
	display: grid;
	grid-template-columns: 1fr 24px;
	margin-bottom: 8px;
}

.delimiter {
	color: blue;
}
</style>
