import * as PIXI from 'pixi.js-legacy'

import { constVoid } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'
import { Lens } from 'monocle-ts'
import { getAll } from 'monocle-ts/lib/Traversal'

import { Form } from '@/models/form'
import {
	FormField,
	formRegistryIso,
	MappableField,
	mappableField,
	radioGroup,
} from '@/models/form/definition/field'
import { RadioGroup, RadioConditional } from '@/models/form/definition/field/variants/radio'
import {
	dateField,
	phoneNumberField,
	singleMappableField,
	unmappableField,
} from '@/models/form/definition/field/optics'
import { format as dateFormat } from '@/models/form/definition/field/variants/date/optics'
import { format as phoneNumberFormat } from '@/models/form/definition/field/variants/phone/optics'

import { State } from './state'
import {
	formFields,
	formAdditionalInformation,
	formFieldName,
	checkboxConditionalId,
	checkboxConditionalValue,
	radioOptionConditional,
	radioOptionWithParentConditional,
	formField,
	radioOptionIdsWithParent,
	textFieldDefaultValue,
	textFieldDelimiter,
	textFieldMultiline,
	radioGroupId,
} from './optics'
import { QualifiedDataElement } from '@/models/data/elements'
import { SingleMappableField, UnmappableField } from '@/models/form/definition/field/field'
import {
	convertToPhoneNumberField,
	PhoneNumberField,
	PhoneNumberFormat,
} from '@/models/form/definition/field/variants/phone'
import { convertToTextField, TextField } from '@/models/form/definition/field/variants/text'
import { convertToDateField, DateField } from '@/models/form/definition/field/variants/date'
import {
	addProperty,
	deleteProperty,
	updateProperty,
	mapPropertiesForDisplay,
	findPropertyByID,
	mapPropertiesForSave,
	mapAdditionalInformationModel,
	mapRequiredFields,
} from '@/modules/editor/additional-information/properties'
import { additionalInformationIsValid } from '@/modules/editor/additional-information/validation'
import { AdditionalInformation, Property } from '@/models/form/additional-information'
import { NullProperty } from '@/models/form/additional-information/property-types'
import { ElementRef } from '@/models/form/definition/field/element'
import { applyChangesToFormMapping, diffModel } from '@/modules/editor/additional-information/diff'

/**
 * Adds a row to a record.
 */
function addRow<T, R extends { [k: number]: T }>(key: number, value: T): (record: R) => R
function addRow<T, R extends { [k: string]: T }>(key: string, value: T): (record: R) => R
function addRow<T, R>(key: number | string, value: T) {
	return (record: R) => ({ [key]: value, ...record })
}

/**
 * Removes a row from a record.
 */
function removeRow<T>(key: string): (record: { [k: string]: T }) => { [k: string]: T } {
	return (record: { [k: string]: T }) => {
		// eslint-disable-next-line
		const { [key]: _, ...rest } = record
		return rest
	}
}

/**
 * Resets the store state.
 */
function reset(state: State) {
	state.form = O.none
	state.pages = {}
	state.selection = []
	return state
}

/**
 * Create the additional information from the json schema provided
 */
function setAdditionalInformationFromSchema(state: State, schema: any) {
	state.additionalInformation = mapPropertiesForDisplay(schema)
	state.additionalInformationAreValid = additionalInformationIsValid(state.additionalInformation)
	state.additionalInformationModel = mapAdditionalInformationModel(state.additionalInformation)
}

/**
 * Loads a form into the store.
 */
function loadForm(state: State, form: Form) {
	setAdditionalInformationFromSchema(state, form.additionalInformation)
	state.cachedAdditionalInformationData = state.additionalInformation
	state.form = O.some(form)
	state.initialFormData = O.some(form)
	return state
}

/**
 * Add a form field to the registry.
 */
function addField(state: State, field: FormField) {
	const addField = addRow(field.id, field)
	state.form = formFields.modify(addField)(state).form
	return state
}

/**
 * Removes a form field from the registry.
 */
function removeField(state: State, id: UUID) {
	pipe(
		formField(id)
			.composePrism(radioGroup)
			.composeLens(Lens.fromProp<RadioGroup>()('id'))
			.getOption(state),
		O.fold(constVoid, (groupId) => {
			const ids = getAll(state)(radioOptionIdsWithParent(groupId))
			ids.forEach((option) => removeField(state, option))
		})
	)

	const removeFormField = removeRow<FormField>(id)
	state.form = formFields.modify(removeFormField)(state).form
	return state
}

/**
 * Update the type of the field and remove the old and add the new to the registry
 * Currently allows conversion of: TextField | PhoneNumberField | DateField
 * @param state
 * @param field
 * @param fieldType
 */
function updateFieldType(
	state: State,
	{ field, newType }: { field: TextField | PhoneNumberField | DateField; newType: string }
) {
	let newField: TextField | PhoneNumberField | DateField
	switch (newType) {
		case 'TEXT_FIELD':
			newField = convertToTextField(field)
			break
		case 'DATE_FIELD':
			newField = convertToDateField(field)
			break
		case 'PHONE_NUMBER_FIELD':
			newField = convertToPhoneNumberField(field)
			break
		default:
			newField = field
	}
	removeField(state, newField.id)
	addField(state, newField)
}

/**
 * Removes the currently selected fields from the registry.
 */
function removeSelectedFields(state: State) {
	const reducer = (id: UUID, state: State) => removeField(state, id)
	state.form = A.reduceRight(state, reducer)(state.selection).form
	state.selection = []
	return state
}

/**
 * Add a form page sprite to the store.
 */
function addPage(state: State, { index, page }: { index: number; page: PIXI.Sprite }) {
	state.pages = addRow(index, page)(state.pages)
	return state
}

/**
 * Sets the field selection.
 */
function setSelection(state: State, selection: UUID[]) {
	state.selection = selection
	return state
}

/**
 * Sets the name of a field.
 */
function setFieldName(state: State, { id, name }: { id: UUID; name: string }) {
	state.form = formFieldName(id).set(O.some(name))(state).form
	return state
}

/**
 * Replaces the entire contents of a field.
 */
function setField(state: State, field: FormField) {
	state.form = formField(field.id).set(field)(state).form
	return state
}

/**
 * Sets the data elements of a field.
 * Resets the conditional values of all the radio options that belong to the
 * group with the specified ID.
 */
function setFieldElements(state: State, { id, elements }: { id: UUID; elements: ElementRef[] }) {
	state.form = formField(id)
		.composePrism(mappableField)
		.composeLens(Lens.fromProp<MappableField>()('element'))
		.set(elements)(state).form

	state.form = setConditionalIds(state, { id, values: [] }).form
	state.form = setConditionalValue(state, { id, value: false }).form
	state.form = setRadioConditional(state, { id, conditional: O.none }).form

	pipe(
		radioGroupId(id).getOption(state),
		O.fold(constVoid, (groupId) => {
			state.form = radioOptionWithParentConditional(groupId).set(O.none)(state).form
		})
	)
}

/**
 * Sets the default value of a field.
 */
function setDefaultValue(state: State, { id, value }: { id: UUID; value: string }) {
	state.form = textFieldDefaultValue(id).set(value)(state).form
	return state
}

/**
 * Sets the multiline bit of a text field.
 */
function setMultiline(state: State, { id, multiline }: { id: UUID; multiline: boolean }) {
	state.form = textFieldMultiline(id).set(multiline)(state).form
}

/**
 * Sets the delimiter character of a text field.
 */
function setDelimiter(state: State, { id, delimiter }: { id: UUID; delimiter: string }) {
	state.form = textFieldDelimiter(id).set(delimiter)(state).form
}

/**
 * Sets the conditional enum ids of a checkbox field.
 */
function setConditionalIds(state: State, { id, values }: { id: UUID; values: O.Option<UUID>[] }) {
	state.form = checkboxConditionalId(id).set(values)(state).form
	return state
}

/**
 * Sets the conditional values of a checkbox field.
 */
function setConditionalValue(state: State, { id, value }: { id: UUID; value: boolean }) {
	state.form = checkboxConditionalValue(id).set(value)(state).form
	return state
}

/**
 * Sets the radio conditional values of a radio button field.
 */
function setRadioConditional(
	state: State,
	{ id, conditional }: { id: UUID; conditional: RadioConditional['conditional'] }
) {
	state.form = radioOptionConditional(id).set(conditional)(state).form
	return state
}

/**
 * Sets the [[ElementRef]] for a date field.
 */
function setSingleElement(state: State, { id, element }: { id: UUID; element: ElementRef }) {
	state.form = formField(id)
		.composePrism(singleMappableField)
		.composeLens(Lens.fromProp<SingleMappableField>()('element'))
		.set(element)(state).form
	return state
}

/**
 * Sets the `mappable` flag on a field.
 */
function setMappableFlag(state: State, { id, mappable }: { id: UUID; mappable: boolean }) {
	state.form = formField(id)
		.composePrism(unmappableField)
		.composeLens(Lens.fromProp<UnmappableField>()('mappable'))
		.set(mappable)(state).form
	return state
}

/**
 * Sets the format specifier for a date field.
 */
function setDateFormat(state: State, { id, format }: { id: UUID; format: string }) {
	state.form = formField(id).composePrism(dateField).composeLens(dateFormat).set(format)(state).form
	return state
}

/**
 * Sets the format specifier for a phone number field.
 */
function setPhoneNumberFormat(
	state: State,
	{ id, format }: { id: UUID; format: O.Option<PhoneNumberFormat> }
) {
	state.form = formField(id)
		.composePrism(phoneNumberField)
		.composeLens(phoneNumberFormat)
		.set(format)(state).form
	return state
}

/**
 * Resets the conditional values of all the radio options that belong to the
 * group with the specified ID.
 */
function resetRadioOptions(state: State, radioGroupId: UUID) {
	state.form = radioOptionWithParentConditional(radioGroupId).set(O.none)(state).form
	return state
}

/**
 * Sets the data model.
 */
function setDataModel(state: State, model: QualifiedDataElement[]) {
	state.model = model
	return state
}

/**
 * Invoked after form changes are saved so that the initialFormData is properly updated
 */
function updateInitialFormData(state: State) {
	state.initialFormData = { ...state.form }
	return state
}

/**
 * Set selected additional property
 */
function setSelectedAdditionalProperty(state: State, property: Property | null) {
	state.selectedProperty = property === null ? ({} as NullProperty) : { ...property }
}

/**
 * Add a new additional property
 */
function addAdditionalProperty(
	state: State,
	{ property, parentId }: { property: Property; parentId?: string }
) {
	if (parentId) {
		const newProperties = addProperty(parentId, state.additionalInformation, property)
		state.additionalInformation = newProperties
	} else {
		state.additionalInformation = [...state.additionalInformation, property]
	}

	state.selectedProperty = property
	state.additionalInformationAreValid = additionalInformationIsValid(state.additionalInformation)
}

/**
 * Revalidate
 */
function revalidateAdditionalProperties(state: State) {
	if (state.additionalInformation.length) {
		state.additionalInformationAreValid = additionalInformationIsValid(state.additionalInformation)
	} else {
		state.additionalInformationAreValid = additionalInformationIsValid([])
	}
}

/**
 * Remove a additional property
 */
function removeAdditionalProperty(state: State, id: string) {
	const newProperties = deleteProperty(id, state.additionalInformation)

	if (state.selectedProperty?.id === id || state.selectedProperty?.parentId === id) {
		state.selectedProperty = null
	}

	state.additionalInformation = [...newProperties]
	state.additionalInformationAreValid = additionalInformationIsValid(state.additionalInformation)
}

/**
 * Update the given property
 */
function updateAdditionalProperty(state: State, property: Property) {
	const newProperties = updateProperty(property, state.additionalInformation)
	const newProperty = findPropertyByID(property.id, newProperties)

	state.selectedProperty = newProperty
	state.additionalInformation = [...newProperties]
	state.additionalInformationAreValid = additionalInformationIsValid(state.additionalInformation)
}

/**
 * Save the additional information to the form
 */
function saveAdditionalInformation(state: State) {
	const additionalInformation = mapPropertiesForSave(state.additionalInformation)
	const requiredFields = mapRequiredFields(state.additionalInformation)

	const formMapping = formFields.composeIso(formRegistryIso.reverse())
	const diff = diffModel(state.cachedAdditionalInformationData ?? [], state.additionalInformation)
	const applyChanges = formMapping.modify((mapping) => applyChangesToFormMapping(mapping, diff))

	state.cachedAdditionalInformationData = state.additionalInformation
	state.selection = []
	state.form = pipe(
		state.form,
		O.getOrElse(() => ({} as Form)),
		(form) => {
			return formAdditionalInformation.set({
				$schema: form.additionalInformation.$schema,
				$id: form.additionalInformation.$id,
				title: form.additionalInformation.title ?? O.none,
				description: form.additionalInformation.description ?? O.none,
				type: 'object',
				properties: additionalInformation ?? {},
				required: requiredFields,
			} as AdditionalInformation)(state)
		},
		applyChanges
	).form
}

/**
 * Set the additional information
 * @param state
 * @param additionalInformation
 */
function setAdditionalInformation(state: State, additionalInformation: Property[]) {
	state.additionalInformation = additionalInformation
	return state
}

/**
 * Set the initial additional information state
 * @param state
 * @param properties
 */
function setInitialAdditionalInformationData(state: State, properties: Property[] | null) {
	state.cachedAdditionalInformationData = properties
	return state
}

/**
 * Caches the current additional information state
 * @param state
 */
function cacheAdditionalInformation(state: State) {
	state.cachedAdditionalInformationData = [...state.additionalInformation]
	return state
}

/**
 * Restores the cached the additional information state
 * @param state
 */
function restoreCachedAdditionalInformation(state: State) {
	state.additionalInformation = [...(state.cachedAdditionalInformationData || [])]
	return state
}

export default {
	reset,
	loadForm,
	addField,
	updateFieldType,
	removeField,
	removeSelectedFields,
	addPage,
	setSelection,
	setFieldName,
	setDefaultValue,
	setMultiline,
	setDelimiter,
	setField,
	setFieldElements,
	resetRadioOptions,
	setConditionalIds,
	setConditionalValue,
	setRadioConditional,
	setSingleElement,
	setMappableFlag,
	setDateFormat,
	setPhoneNumberFormat,
	setDataModel,
	updateInitialFormData,
	setAdditionalInformation,
	setSelectedAdditionalProperty,
	addAdditionalProperty,
	removeAdditionalProperty,
	updateAdditionalProperty,
	saveAdditionalInformation,
	setInitialAdditionalInformationData,
	cacheAdditionalInformation,
	restoreCachedAdditionalInformation,
	revalidateAdditionalProperties,
}
