export const shortcutOptions = [
	{ description: 'Selection Tool', binding: 'a' },
	{ description: 'Save', binding: 'ctrl + s' },
	{ description: 'Draw Tool', binding: 's' },
	{ description: 'Scroll Page', binding: 'scroll wheel' },
	{ description: 'Hand Tool', binding: 'd' },
	{ description: 'Zoom In', binding: '+' },
	{ description: 'Text Field', binding: 'q' },
	{ description: 'Zoom Out', binding: '-' },
	{ description: 'Date Field', binding: 'w' },
	{
		description: 'Zoom At Cursor Location',
		binding: 'ctrl + scroll wheel',
	},
	{ description: 'Phone Number Field', binding: 'e' },
	{ description: 'Additional Information', binding: 'z' },
	{ description: 'Checkbox Field', binding: 'r' },
	{ description: 'Help', binding: 'ctrl + h' },
	{ description: 'Radio Button Field', binding: 't' },
	{ description: 'Temporary Hand Tool', binding: 'spacebar' },
	{ description: 'Delete Selected Field', binding: 'backspace', mac: 'delete' },
]
