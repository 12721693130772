import {
	myndshftBlue300,
	myndshftBlue700,
	myndshftGray100,
	myndshftGray300,
	myndshftOrange300,
	myndshftOrange900,
	myndshftTealBorder,
	myndshftTealBackground,
} from '@/styles/colors'

import * as t from 'io-ts'

export const BoxColorSettings = t.type({
	backgroundColor: t.number,
	borderColor: t.number,
})
export type BoxColorSettings = t.TypeOf<typeof BoxColorSettings>

export const unmappableFieldColors: BoxColorSettings = {
	backgroundColor: myndshftGray300,
	borderColor: myndshftGray100,
}

export const unmappedFieldColors: BoxColorSettings = {
	backgroundColor: myndshftOrange900,
	borderColor: myndshftOrange300,
}

export const mappedFieldColors: BoxColorSettings = {
	backgroundColor: myndshftBlue700,
	borderColor: myndshftBlue300,
}

export const additionalInformationMappedColors: BoxColorSettings = {
	backgroundColor: myndshftTealBackground,
	borderColor: myndshftTealBorder,
}
