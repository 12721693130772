<template>
	<div v-if="mappable">
		<section>
			<div class="toggle-labels">
				<label
					id="phone-model-toggle-label"
					:toggled="!showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Data Model
				</label>
				<label
					id="phone-ai-model-toggle-label"
					:toggled="showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Additional Information
				</label>
			</div>
			<div id="phone-ai-model-toggle" v-if="showAdditionalInformationModel">
				<vue-select
					label="name"
					@update="setElement"
					:options="filteredAdditionalInformationModel"
					:value="element"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
			<div id="phone-model-toggle" v-else>
				<vue-select
					label="name"
					@update="setElement($event)"
					:value="element"
					:options="filteredDataModel"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
		</section>
		<section>
			<label>Format</label>
			<vue-select
				label="label"
				@update="setFormat($event)"
				:value="!!field.format?.value ? String(field.format.value) : null"
				:options="phoneNumberFormatOptions"
				:reducer="(value) => value.value"
			></vue-select>
		</section>
		<UnmappableConversion />
	</div>
	<div v-else>
		<MappableConversion />
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeMount, watch, PropType } from 'vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import {
	PhoneNumberField,
	PhoneNumberFormat,
	phoneNumberFormat,
	phoneNumberFormatOptions,
} from '@/models/form/definition/field/variants/phone'
import { sortOptions } from '@/components/Shared/shared'
import VueSelect from '@/components/Select/VueSelect.vue'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { QualifiedDataElement } from '@/models/data/elements'
import { findByID } from '@/models/data/model'
import { element } from '@/models/form/definition/field/traits/single-mappable'

export default defineComponent({
	name: 'PhoneNumberFieldEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<PhoneNumberField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref<boolean>(false)

		const filteredAdditionalInformationModel = computed(() =>
			props.additionalDataModel
				.filter((x) => ['PHONE_NUMBER_FIELD'].includes(x.type))
				.sort(sortOptions)
		)

		const filteredDataModel = computed(() =>
			props.dataModel.filter((x) => /(phone|fax)$/.test(x.name.toLowerCase())).sort(sortOptions)
		)

		const elementPath = computed(() => props.field.element ?? O.none)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const setElement = (value: FullyQualifiedPath | null) =>
			context.emit('update', element.set(O.fromNullable(value))(props.field))

		const setFormat = (value: PhoneNumberFormat) => {
			context.emit('update', phoneNumberFormat.set(O.fromNullable(value))(props.field))
		}

		const toggleModelType = () => {
			context.emit('update', element.set(O.none)(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value = pipe(
				elementPath.value,
				O.toNullable,
				(val: FullyQualifiedPath | null) => val?.includes(BASE_CP_DATA_ELEMENT_PATH) ?? false
			)
		}

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		onBeforeMount(() => checkModelType())

		return {
			element: computed(() => O.toNullable(elementRef.value)),
			filteredDataModel,
			phoneNumberFormatOptions,
			showAdditionalInformationModel,
			filteredAdditionalInformationModel,
			toggleModelType,
			setFormat,
			setElement,
		}
	},
})
</script>

<style lang="scss" scoped>
section {
	margin: 8px;
}

input {
	display: block;
	width: 100%;
	padding: 5px;
	border: 1px solid rgba(60, 60, 60, 0.26);
	border-radius: 4px;
	outline: none;
}

ion-icon {
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.element {
	display: grid;
	grid-template-columns: 1fr 24px;
	margin-bottom: 8px;
}
</style>
