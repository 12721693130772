
import { defineComponent, computed, ref, onBeforeMount, watch, PropType } from 'vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import {
	PhoneNumberField,
	PhoneNumberFormat,
	phoneNumberFormat,
	phoneNumberFormatOptions,
} from '@/models/form/definition/field/variants/phone'
import { sortOptions } from '@/components/Shared/shared'
import VueSelect from '@/components/Select/VueSelect.vue'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { QualifiedDataElement } from '@/models/data/elements'
import { findByID } from '@/models/data/model'
import { element } from '@/models/form/definition/field/traits/single-mappable'

export default defineComponent({
	name: 'PhoneNumberFieldEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<PhoneNumberField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref<boolean>(false)

		const filteredAdditionalInformationModel = computed(() =>
			props.additionalDataModel
				.filter((x) => ['PHONE_NUMBER_FIELD'].includes(x.type))
				.sort(sortOptions)
		)

		const filteredDataModel = computed(() =>
			props.dataModel.filter((x) => /(phone|fax)$/.test(x.name.toLowerCase())).sort(sortOptions)
		)

		const elementPath = computed(() => props.field.element ?? O.none)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const setElement = (value: FullyQualifiedPath | null) =>
			context.emit('update', element.set(O.fromNullable(value))(props.field))

		const setFormat = (value: PhoneNumberFormat) => {
			context.emit('update', phoneNumberFormat.set(O.fromNullable(value))(props.field))
		}

		const toggleModelType = () => {
			context.emit('update', element.set(O.none)(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value = pipe(
				elementPath.value,
				O.toNullable,
				(val: FullyQualifiedPath | null) => val?.includes(BASE_CP_DATA_ELEMENT_PATH) ?? false
			)
		}

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		onBeforeMount(() => checkModelType())

		return {
			element: computed(() => O.toNullable(elementRef.value)),
			filteredDataModel,
			phoneNumberFormatOptions,
			showAdditionalInformationModel,
			filteredAdditionalInformationModel,
			toggleModelType,
			setFormat,
			setElement,
		}
	},
})
