
import { defineComponent, computed, ref, onBeforeMount, watch, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { indexArray } from 'monocle-ts/Index/Array'

import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'
import {
	TextField,
	defaultValue,
	delimiter,
	multiline,
	element,
} from '@/models/form/definition/field/variants/text'
import { ElementRef } from '@/models/form/definition/field/element'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'

export default defineComponent({
	name: 'TextFieldEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<TextField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref(false)
		const filteredAdditionalDataModel = computed(() => filterOptions(props.additionalDataModel))
		const filteredDataModel = computed(() => filterOptions(props.dataModel))
		const showAddButton = computed(() => props.field.element.every(O.isSome))

		const elements = computed(() =>
			props.field.element.length > 0 ? props.field.element : [O.none]
		)

		const filterOptions = (options: QualifiedDataElement[]) =>
			options.filter((e) => !['TIME', 'DATE'].includes(e.type)).sort(sortOptions)

		const setMultiline = (value: boolean) => {
			context.emit('update', multiline.set(value)(props.field))
		}

		const setDefaultValue = (value: string) => {
			context.emit('update', defaultValue.set(value)(props.field))
		}

		const setDelimiter = (value: string) => {
			context.emit('update', delimiter.set(value)(props.field))
		}

		const addElement = () => {
			const newElements = elements.value.concat(O.none)
			context.emit('update', element.set(newElements)(props.field))
		}

		const setElement = (index: number, path: FullyQualifiedPath | null) => {
			const elementAtIndex = indexArray<ElementRef>().index(index)
			const newElements = elementAtIndex.set(O.fromNullable(path))(elements.value)
			context.emit('update', element.set(newElements)(props.field))
		}

		const removeElement = (index: number) => {
			const newElements = pipe(
				elements.value,
				A.deleteAt(index),
				O.getOrElse<ElementRef[]>(() => [])
			)
			context.emit('update', element.set(newElements)(props.field))
		}

		const toggleModelType = () => {
			context.emit('update', element.set([])(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value = elements.value.some((e) =>
				O.toNullable(e)?.includes(BASE_CP_DATA_ELEMENT_PATH)
			)
		}

		onBeforeMount(() => checkModelType())

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		return {
			elements: computed(() => elements.value.map(O.toNullable)),
			showAddButton,
			filteredDataModel,
			filteredAdditionalDataModel,
			defaultValue,
			delimiter,
			multiline,
			setDefaultValue,
			setDelimiter,
			setMultiline,
			addElement,
			setElement,
			removeElement,
			showAdditionalInformationModel,
			toggleModelType,
		}
	},
})
