
import { computed, defineComponent, watch } from 'vue'
import PreviewFormMappingChange from './PreviewFormMappingChange.vue'
import { useEditorStore } from '@/modules/editor/store'
import {
	diffModel,
	hasEffectOnFormMapping,
	previewChangesToFormMapping,
} from '@/modules/editor/additional-information/diff'
import * as A from 'fp-ts/Array'

export default defineComponent({
	name: 'PreviewPanel',
	components: { PreviewFormMappingChange },
	emits: ['hasMappedFieldChanges'],
	setup(_, context) {
		const store = useEditorStore()
		const isValid = computed<boolean>(() => {
			return store.getters['propertiesValidForSave']
		})

		const changes = computed(() => {
			const before = store.state.cachedAdditionalInformationData ?? []
			const after = store.state.additionalInformation
			const diff = diffModel(before, after)
			const changes = previewChangesToFormMapping(store.getters.allFields)(diff)
			const partitionedChanges = A.partition(hasEffectOnFormMapping)(changes)

			return {
				withNoEffects: partitionedChanges.left,
				withEffects: partitionedChanges.right,
			}
		})

		watch(
			changes,
			(changes) => {
				context.emit('hasMappedFieldChanges', !!changes?.withEffects?.length)
			},
			{ immediate: true }
		)

		return { changes, isValid }
	},
})
