<template>
	<template v-if="changes.withNoEffects.length === 0 && changes.withEffects.length === 0">
		<div v-if="!isValid" class="warning-message">
			<span class="warning-icon">⚠️</span> Errors currently exist that prevent saving.
		</div>
		<div v-else class="no-changes-message">No changes that will affect form mapping</div>
	</template>

	<template v-if="changes.withEffects.length > 0">
		<h2>Mapped Property Changes</h2>
		<div class="warning-message">
			<span class="warning-icon">⚠️</span> Making changes to these mapped groups and properties will
			unmap them. Review these changes before saving.
		</div>
		<div v-for="(change, i) in changes.withEffects" :key="i">
			<preview-form-mapping-change :change="change" />
		</div>
		<div v-if="!isValid" class="warning-message">
			<span class="warning-icon">⚠️</span> Errors currently exist that prevent saving.
		</div>
	</template>

	<template v-if="changes.withNoEffects.length > 0">
		<h2>Not Mapped Property Changes</h2>
		<div class="info-message">
			<span class="info-icon">ℹ️</span>These property changes will not affect the form mapping.
		</div>
		<div v-for="(change, i) in changes.withNoEffects" :key="i">
			<preview-form-mapping-change :change="change" />
		</div>
		<div v-if="!isValid" class="warning-message">
			<span class="warning-icon">⚠️</span> Errors currently exist that prevent saving.
		</div>
	</template>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import PreviewFormMappingChange from './PreviewFormMappingChange.vue'
import { useEditorStore } from '@/modules/editor/store'
import {
	diffModel,
	hasEffectOnFormMapping,
	previewChangesToFormMapping,
} from '@/modules/editor/additional-information/diff'
import * as A from 'fp-ts/Array'

export default defineComponent({
	name: 'PreviewPanel',
	components: { PreviewFormMappingChange },
	emits: ['hasMappedFieldChanges'],
	setup(_, context) {
		const store = useEditorStore()
		const isValid = computed<boolean>(() => {
			return store.getters['propertiesValidForSave']
		})

		const changes = computed(() => {
			const before = store.state.cachedAdditionalInformationData ?? []
			const after = store.state.additionalInformation
			const diff = diffModel(before, after)
			const changes = previewChangesToFormMapping(store.getters.allFields)(diff)
			const partitionedChanges = A.partition(hasEffectOnFormMapping)(changes)

			return {
				withNoEffects: partitionedChanges.left,
				withEffects: partitionedChanges.right,
			}
		})

		watch(
			changes,
			(changes) => {
				context.emit('hasMappedFieldChanges', !!changes?.withEffects?.length)
			},
			{ immediate: true }
		)

		return { changes, isValid }
	},
})
</script>

<style lang="scss" scoped>
.info-message {
	margin-top: 8px;
	margin-left: 8px;
	margin-right: 8px;
	padding: 8px 4px;
	background-color: #eee;
	border: 1px solid #ddd;
}

.info-icon,
.warning-icon {
	margin: 0 12px;
}

.warning-message {
	margin-top: 8px;
	margin-left: 8px;
	margin-right: 8px;
	padding: 8px 4px;
	background-color: lightyellow;
	border: 1px solid yellow;
}

.no-changes-message {
	display: grid;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 3em;
	color: #999;
}
</style>
