import { useMyndmapApi, getApiErrorMessage } from '@/modules/api'
import { useConfigStore } from '@/modules/mynd-config'
import { useEditorStore } from '@/modules/editor/store'
import { toast } from '@/components/Toast'

import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import { computed } from 'vue'

export function loadDataModel() {
	const store = useEditorStore()
	const configStore = useConfigStore()
	const config = computed(() => configStore.getters['getConfig'])

	if (store.state.model.length === 0) {
		const { getDataModel } = useMyndmapApi(config)
		getDataModel()().then((result) => {
			pipe(
				result,
				E.mapLeft(getApiErrorMessage),
				E.fold(
					(error) => toast(error),
					(model) => {
						store.commit('setDataModel', model)
					}
				)
			)
		})
	}
}
