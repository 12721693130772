
import { defineComponent, computed } from 'vue'

import * as O from 'fp-ts/lib/Option'

import { FormField } from '@/models/form/definition/field'
import { useEditorStore } from '@/modules/editor/store'

import TextFieldEditor from './variants/TextField.vue'
import CheckboxEditor from './variants/Checkbox.vue'
import RadioGroupEditor from './variants/RadioGroup.vue'
import RadioOptionEditor from './variants/RadioOption.vue'
import DateFieldEditor from './variants/DateField.vue'
import PhoneNumberFieldEditor from './variants/PhoneNumberField.vue'
import { UUID } from 'io-ts-types/UUID'

export default defineComponent({
	name: 'DataModel',
	components: {
		TextFieldEditor,
		CheckboxEditor,
		RadioGroupEditor,
		RadioOptionEditor,
		DateFieldEditor,
		PhoneNumberFieldEditor,
	},
	setup() {
		const store = useEditorStore()

		const selectedFields = computed(() => store.getters.selectedFields as FormField[])
		const field = computed(() => selectedFields.value?.[0])
		const fieldType = computed(() => field.value?.type)

		const dataModel = computed(() => store.state.model)
		const additionalInformationModel = computed(() => store.state.additionalInformationModel)
		const radioGroup = computed(() => O.toNullable(store.getters.selectedRadioGroup))
		const mappable = computed(() => O.toNullable(store.getters.mappable) ?? false)

		function updateField(field: FormField) {
			store.commit('setField', field)
		}

		function resetRadioOptions(radioGroupId: UUID) {
			store.commit('resetRadioOptions', radioGroupId)
		}

		return {
			field,
			fieldType,
			radioGroup,
			mappable,
			dataModel,
			additionalInformationModel,
			updateField,
			resetRadioOptions,
		}
	},
})
