import * as PIXI from 'pixi.js-legacy'

import * as O from 'fp-ts/lib/Option'
import { UUID } from 'io-ts-types/lib/UUID'

import { Form } from '@/models/form'
import { QualifiedDataElement } from '@/models/data/elements'
import { Property } from '@/models/form/additional-information'
import * as E from 'fp-ts/Either'
import { InvalidProperty } from '@/modules/editor/additional-information'

const state = () => ({
	/**
	 * The form model that is currently loaded.
	 */
	form: O.none as O.Option<Form>,

	/**
	 * The rendered page images of the currently loaded form.
	 */
	pages: {} as { [page: number]: PIXI.Sprite },

	/**
	 * Represents the list of bounding boxes that are selected.
	 */
	selection: [] as UUID[],

	/**
	 * The data model that can be mapped to the form.
	 */
	model: [] as QualifiedDataElement[],

	/**
	 * The data model for additional information that can be mapped to the form.
	 */
	additionalInformationModel: [] as QualifiedDataElement[],

	/**
	 * Capture initial data of form in the editor to see if there are pending, unsaved edits
	 */
	initialFormData: O.none as O.Option<Form>,

	/**
	 * Additional property information
	 */
	additionalInformation: [] as Property[],

	/**
	 * Currently Selected Property
	 */
	selectedProperty: null as Property | null,

	/**
	 * E.right is the indication that all the properties are valid
	 * E.left should contain all outstanding errors
	 */
	additionalInformationAreValid: {} as E.Either<InvalidProperty[], true>,

	/**
	 * Capture initial snapshot of initial additional information to see if there are pending, unsaved edits.
	 */
	cachedAdditionalInformationData: [] as Property[] | null,
})

export type State = ReturnType<typeof state>

export default state
