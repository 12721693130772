
import { defineComponent, computed, ref } from 'vue'
import { useEditorStore } from '@/modules/editor/store'
import { formFields } from '@/modules/editor/store/optics'
import { FormField } from '@/models/form/definition/field'
import { FieldFilter } from './filter-type'
import NavigatorItem from '@/components/NavigatorItem'

import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import SearchBar from '@/components/SearchBar/SearchBar.vue'

export default defineComponent({
	name: 'NavigatorList',
	components: {
		SearchBar,
		[NavigatorItem.name]: NavigatorItem,
	},
	setup() {
		const store = useEditorStore()
		const searchText = ref<string>('')
		const fields = computed(() => {
			return pipe(
				formFields.getOption(store.state),
				O.map(Object.values),
				O.getOrElse(() => [] as FormField[]),
				A.filter((f) => f.type !== 'RADIO_OPTION')
			)
		})

		const filterType = ref<FieldFilter>(FieldFilter.ALL)
		const searchValue = (search: string) => (searchText.value = search)
		const clearFilters = () => {
			filterType.value = FieldFilter.ALL
			searchText.value = ''
		}

		return {
			fields,
			filterType,
			filters: {
				all: FieldFilter.ALL,
				unmapped: FieldFilter.UNMAPPED,
				unmappable: FieldFilter.UNMAPPABLE,
			},
			searchText,
			searchValue,
			clearFilters,
		}
	},
})
