import { Machine, MachineConfig, assign, interpret } from 'xstate'
import { Context, Schema, Event } from './types'

const config: MachineConfig<Context, Schema, Event> = {
	id: 'toolboxMachine',
	type: 'parallel',
	context: {
		previousTool: 'move',
		drawFieldType: 'TEXT_FIELD',
	},
	states: {
		tool: {
			initial: 'move',
			states: {
				move: {
					exit: [
						assign({
							// eslint-disable-next-line
							previousTool: (_) => 'move',
						}),
					],
				},
				draw: {
					initial: 'idle',
					exit: [
						assign({
							// eslint-disable-next-line
							previousTool: (_) => 'draw',
						}),
					],
					states: {
						idle: {
							on: {
								START_DRAW: 'drawing',
							},
						},
						drawing: {
							on: {
								STOP_DRAW: 'idle',
							},
						},
					},
				},
				hand: {
					type: 'parallel',
					exit: [
						assign({
							// eslint-disable-next-line
							previousTool: (_) => 'hand',
						}),
					],
					states: {
						panning: {
							initial: 'inactive',
							states: {
								inactive: {
									on: {
										START_PAN: 'active',
									},
								},
								active: {
									on: {
										STOP_PAN: 'inactive',
									},
								},
							},
						},
						temporary: {
							initial: 'inactive',
							states: {
								inactive: {
									exit: [
										assign({
											// eslint-disable-next-line
											previousTool: (_) => 'hand',
										}),
									],
								},
								active: {
									on: {
										SELECT_PREVIOUS: [
											{
												target: '#toolboxMachine.tool.move',
												cond: (c) => c.previousTool === 'move',
											},
											{
												target: '#toolboxMachine.tool.draw',
												cond: (c) => c.previousTool === 'draw',
											},
											{
												// This internal state transition is intentional. It
												// preserves the 'tool.hand.panning' state so that
												// in-progress panning operations do not get disrupted.
												target: 'inactive',
												cond: (c) => c.previousTool === 'hand',
											},
										],
									},
								},
							},
						},
					},
				},
			},

			on: {
				SELECT_MOVE: 'tool.move',
				SELECT_DRAW: 'tool.draw',
				SELECT_HAND: 'tool.hand',
				SELECT_TEMP_HAND: 'tool.hand.temporary.active',
			},
		},
		fieldType: {
			initial: 'text',
			states: {
				text: {},
				checkbox: {},
				radio: {},
				date: {},
				phoneNumber: {},
			},

			on: {
				SELECT_TEXT_FIELD: 'fieldType.text',
				SELECT_CHECKBOX: 'fieldType.checkbox',
				SELECT_RADIO_BUTTON: 'fieldType.radio',
				SELECT_DATE_FIELD: 'fieldType.date',
				SELECT_PHONE_NUMBER_FIELD: 'fieldType.phoneNumber',
			},
		},
		control: {
			initial: 'inactive',
			states: {
				inactive: {
					on: {
						CONTROL_DOWN: 'active',
					},
				},
				active: {
					on: {
						CONTROL_UP: 'inactive',
					},
				},
			},
		},
	},
}

export const toolboxMachine = Machine(config)
export const toolboxService = interpret(toolboxMachine).start()
