import { FormField } from '@/models/form/definition/field'
import * as A from 'fp-ts/Array'
import { QualifiedDataElement } from '@/models/data/elements'
import { pipe } from 'fp-ts/function'
import { RadioOption } from '@/models/form/definition/field/variants/radio'
import * as O from 'fp-ts/Option'
import { ElementRef } from '@/models/form/definition/field/element'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'

const getFieldsNames = (fields: FormField[]): string[] => {
	return A.compact(fields.map((field) => field.metadata.name))
}

const getFieldsElements = (fields: FormField[], dataModel: QualifiedDataElement[]): string[] => {
	const elements = pipe(
		fields.flatMap((field) =>
			pipe(RadioOption.is(field) ? O.none : field.element, (element) =>
				ElementRef.is(element) ? [element] : element
			)
		),
		A.compact
	)
	return elements.map((element) => {
		if (!element.includes(BASE_CP_DATA_ELEMENT_PATH)) {
			return dataModel.find((dataElement) => dataElement.id === element)?.name ?? ''
		} else {
			return element.replace(BASE_CP_DATA_ELEMENT_PATH + '.', '')
		}
	})
}

export const fieldIncludedInSearch = (
	search: string,
	field: FormField,
	children: RadioOption[],
	dataModel: QualifiedDataElement[]
): boolean => {
	if (!search) return true

	const searchValues = (strings: string[]): boolean => {
		return strings.some((stringValue) => {
			return stringValue.toLowerCase().includes(search.toLowerCase())
		})
	}

	const fieldsNames = getFieldsNames([...children, field])
	const fieldsElements = getFieldsElements([...children, field], dataModel)
	const fieldsIds = fieldsNames.length ? [] : [...children, field].map((field) => field.id)

	return searchValues([...fieldsNames, ...fieldsElements, ...fieldsIds])
}
