
import { defineComponent, computed, ref, watch, PropType, onBeforeMount } from 'vue'
import { useEditorStore } from '@/modules/editor/store'
import { formFieldName, radioOptionWithParent } from '@/modules/editor/store/optics'
import { FormField, BoxColorSettings, unmappedFieldColors } from '@/models/form/definition/field'

import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as T from 'monocle-ts/lib/Traversal'
import { FieldFilter } from '../NavigatorList/filter-type'
import ColorPreview from '@/components/ColorPreview/ColorPreview.vue'
import { getFieldBoxColors } from '@/modules/editor/colors'
import { fieldIncludedInSearch } from './'

export default defineComponent({
	name: 'NavigatorItem',
	components: { ColorPreview },
	props: {
		field: { type: Object as PropType<FormField>, required: true },
		filter: { type: Number as PropType<FieldFilter> },
		parentField: { type: Object as PropType<FormField>, default: () => null },
		search: { type: String, default: '' },
	},
	setup(props) {
		const store = useEditorStore()
		const input = ref<HTMLInputElement | null>(null)
		const isEditing = ref(false)
		const previewColors = ref<BoxColorSettings>(unmappedFieldColors)

		const fieldName = ref(
			pipe(
				formFieldName(props.field.id),
				(o) => o.getOption(store.state),
				O.flatten,
				O.getOrElse<string>(() => props.field.id)
			)
		)

		const selection = computed(() => store.state.selection)

		const isSelected = computed(() => selection.value.includes(props.field.id))

		const dataModel = computed(() => store.state.model)

		const select = () => store.commit('setSelection', [props.field.id])

		const edit = () => {
			isEditing.value = true
			input.value?.focus()
			input.value?.select()
		}

		const editGuard = (event: MouseEvent) => {
			if (!isEditing.value) {
				event.preventDefault()
			}
		}

		const save = () => {
			input.value?.blur()
			store.commit('setFieldName', {
				id: props.field.id,
				name: fieldName.value,
			})
			isEditing.value = false
		}

		// https://ionicons.com/
		const iconMap: { [T in FormField['type']]: string } = {
			TEXT_FIELD: 'text',
			CHECK_BOX: 'checkbox',
			RADIO_GROUP: 'radio-button-on',
			RADIO_OPTION: 'radio-button-on',
			DATE_FIELD: 'calendar',
			PHONE_NUMBER_FIELD: 'call',
		}

		const iconName = computed(() => iconMap[props.field.type] || 'help')

		const isRadioOption = computed(() =>
			['RADIO_UNMAPPED_OPTION', 'RADIO_BOOL_OPTION', 'RADIO_ENUM_OPTION'].includes(props.field.type)
		)

		const children = computed(() => {
			if (props.field.type === 'RADIO_GROUP') {
				return T.getAll(store.state)(radioOptionWithParent(props.field.id))
			} else {
				return []
			}
		})

		const isUnmappableField = (field: FormField) => store.getters.isUnmappableFieldType(field)

		const isUnmappedField = (field: FormField) =>
			!isUnmappableField(field) && store.getters.isUnmappedField(field)

		const fieldIncludedInFilter = (field: FormField, filter?: FieldFilter): boolean => {
			switch (filter) {
				case FieldFilter.UNMAPPED:
					return isUnmappedField(field)
				case FieldFilter.UNMAPPABLE:
					return isUnmappableField(field)
				default:
					return true
			}
		}

		const showField = computed(() => {
			const field = props.field
			return (
				fieldIncludedInFilter(field, props.filter) &&
				fieldIncludedInSearch(props.search ?? '', field, [...children.value], dataModel.value)
			)
		})

		watch(
			() => props.field,
			(field: FormField) => (previewColors.value = getFieldBoxColors(field, props.parentField))
		)

		watch(
			() => props.parentField,
			(parentField: FormField) =>
				(previewColors.value = getFieldBoxColors(props.field, parentField))
		)

		watch([isSelected], ([isSelected]) => {
			if (!isSelected) {
				input.value?.blur()
			}
		})

		onBeforeMount(() => {
			previewColors.value = getFieldBoxColors(props.field, props.parentField)
		})

		return {
			input,
			isSelected,
			isEditing,
			fieldName,
			iconName,
			isRadioOption,
			children,
			showField,
			previewColors,
			select,
			edit,
			editGuard,
			save,
			isUnmappedField,
		}
	},
})
