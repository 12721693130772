
import { computed, defineComponent, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createNewGroupProperty } from '@/modules/editor/additional-information/properties'
import { Property } from '@/models/form/additional-information'
import PropertySelection from '@/components/AdditionalInformation/Panels/Properties/PropertySelection.vue'
import { useEditorStore } from '@/modules/editor/store'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'
import { InvalidProperty } from '@/modules/editor/additional-information'

export default defineComponent({
	name: 'PropertyPanel',
	components: {
		PropertySelection,
		'font-awesome-icon': FontAwesomeIcon,
	},
	setup() {
		const store = useEditorStore()
		const mappedAdditionalInformationFields = ref<string[]>(
			store.getters.allFieldsMappedToAdditionalInformation
		)
		const invalidProperties = computed<string[]>(() =>
			pipe(
				store.getters['additionalInformationAreValid'] as E.Either<InvalidProperty[], true>,
				E.fold(
					(aI) =>
						aI.map((ip: InvalidProperty) => (Array.isArray(ip.property) ? '' : ip.property.id)),
					() => []
				)
			)
		)

		const additionalInformation = computed<Property[]>(() => {
			return store.getters['additionalInformation']
		})

		const addNewGroup = () => {
			const property = createNewGroupProperty()
			store.commit('addAdditionalProperty', { property })
		}

		return {
			additionalInformation,
			mappedAdditionalInformationFields,
			addNewGroup,
			invalidProperties,
		}
	},
})
