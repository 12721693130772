<template>
	<div v-if="mappable">
		<section>
			<div class="toggle-labels">
				<label
					:toggled="!showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Data Model
				</label>
				<label
					:toggled="showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Additional Information
				</label>
			</div>
			<div v-if="showAdditionalInformationModel">
				<vue-select
					label="name"
					@update="setElement"
					:options="filteredAdditionalDataModel"
					:value="toNullable(elementRef)"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
			<div v-else>
				<vue-select
					label="name"
					@update="setElement"
					:options="filteredDataModel"
					:value="toNullable(elementRef)"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
		</section>
		<section v-if="elementRefType === 'BOOLEAN'">
			<label>Checkbox Value</label>
			<vue-select
				label="value"
				@update="setConditionalValue"
				:value="field.conditionalValue"
				:options="checkboxValues"
				:reducer="(value) => value.id"
			></vue-select>
		</section>
		<section v-if="elementRefType === 'ENUM'" :addOption="addOption()">
			<label>Checkbox Value</label>
			<div class="element" v-for="(conditionalId, index) of field.conditionalId" :key="index">
				<vue-select
					label="value"
					@update="setConditionalId(index, $event)"
					:value="toNullable(conditionalId)"
					:options="filteredCheckboxValues(conditionalId)"
					:reducer="(value) => value.id"
				></vue-select>
				<ion-icon v-if="index !== 0" name="trash" @click="removeConditionalId(index)"></ion-icon>
			</div>
			<div v-if="addButton">
				<button @click="addConditionalId" class="ms-button ms-slim">Add Additional Value</button>
			</div>
		</section>

		<UnmappableConversion />
	</div>
	<div v-else>
		<MappableConversion />
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onBeforeMount, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { indexArray } from 'monocle-ts/lib/Index/Array'

import {
	CheckboxField,
	conditionalValue,
	conditionalId,
} from '@/models/form/definition/field/variants/checkbox'
import { findByID } from '@/models/data/model'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { element } from '@/models/form/definition/field/variants/checkbox/optics'
import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'

export default defineComponent({
	name: 'CheckboxEditor',
	components: {
		MappableConversion,
		UnmappableConversion,
		VueSelect,
	},
	props: {
		field: {
			type: Object as PropType<CheckboxField>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref<boolean>(false)
		const filteredAdditionalDataModel = computed(() => filterOptions(props.additionalDataModel))
		const filteredDataModel = computed(() => filterOptions(props.dataModel))

		const addButton = ref(false)

		const elementPath = computed(() =>
			props.field.element.length > 0 ? props.field.element[0] : O.none
		)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const elementRefType = computed(() => O.toNullable(elementRef.value)?.type)

		const checkboxValues = computed(() =>
			pipe(
				elementRef.value,
				O.fold(
					() => [],
					(x) =>
						x.type === 'ENUM'
							? x.values
							: x.type === 'BOOLEAN'
							? [
									{ id: true, value: 'true' },
									{ id: false, value: 'false' },
							  ]
							: []
				)
			).sort(sortOptions)
		)

		const filteredCheckboxValues = (conditionalId: any) => {
			return [...checkboxValues.value].filter(
				(x) =>
					!props.field.conditionalId.find(
						(item) => O.isSome(item) && item.value === x.id && item.value !== conditionalId.value
					)
			)
		}

		const filterOptions = (options: QualifiedDataElement[]) => {
			return options.filter((x) => ['BOOLEAN', 'ENUM'].includes(x.type)).sort(sortOptions)
		}

		const setConditionalValue = (value: boolean | null) => {
			context.emit('update', conditionalValue.set(value)(props.field))
		}

		const setElement = (path: FullyQualifiedPath | null) => {
			context.emit(
				'update',
				pipe(
					props.field,
					element.set(path ? [O.some(path)] : []),
					conditionalId.set([]),
					conditionalValue.set(false)
				)
			)
		}

		const addConditionalId = () => {
			addButton.value = false
			const newConditionalId = props.field.conditionalId.concat(O.none)
			context.emit('update', conditionalId.set(newConditionalId)(props.field))
		}

		const setConditionalId = (index: number, id: string | null) => {
			const idAtIndex = indexArray<O.Option<string>>().index(index)
			const newIds = idAtIndex.set(O.fromNullable(id))(props.field.conditionalId)
			context.emit('update', conditionalId.set(newIds)(props.field))
		}

		const removeConditionalId = (index: number) => {
			const newIds = pipe(
				props.field.conditionalId,
				A.deleteAt(index),
				O.getOrElse<O.Option<string>[]>(() => [])
			)
			context.emit('update', conditionalId.set(newIds)(props.field))
		}

		const addOption = () => {
			const isNoneOption = props.field.conditionalId.some((option) => O.isNone(option))
			if (!props.field.conditionalId.length) addConditionalId()
			addButton.value = !(
				isNoneOption || props.field.conditionalId.length === [...checkboxValues.value].length
			)
		}

		const toggleModelType = () => {
			context.emit('update', element.set([])(props.field))
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value =
				O.toNullable(elementPath.value)?.includes(BASE_CP_DATA_ELEMENT_PATH) ?? false
		}

		onBeforeMount(() => checkModelType())

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		return {
			elementRef,
			elementRefType,
			filteredDataModel,
			filteredAdditionalDataModel,
			checkboxValues,
			toNullable: O.toNullable,
			addButton,
			showAdditionalInformationModel,
			setElement,
			addConditionalId,
			setConditionalId,
			setConditionalValue,
			removeConditionalId,
			addOption,
			filteredCheckboxValues,
			toggleModelType,
		}
	},
})
</script>

<style lang="scss" scoped>
section {
	margin: 8px;
}

ion-icon {
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.element {
	display: grid;
	grid-template-columns: 1fr 24px;
	margin-bottom: 8px;
}
</style>
