<template>
	<div :style="{ visibility: isHidden ? 'hidden' : 'visible' }" id="field-editor">
		<field-type @fieldTypeUpdated="fieldTypeUpdated"></field-type>
		<data-model></data-model>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useEditorStore } from '@/modules/editor/store'

import DataModel from './DataModel'
import FieldType from './FieldType'

import { TextField } from '@/models/form/definition/field/variants/text'
import { PhoneNumberField } from '@/models/form/definition/field/variants/phone'
import { DateField } from '@/models/form/definition/field/variants/date'

export default defineComponent({
	name: 'FieldEditor',
	components: {
		[DataModel.name]: DataModel,
		[FieldType.name]: FieldType,
	},
	setup() {
		const store = useEditorStore()

		const isHidden = computed(() => store.state.selection.length === 0)

		const fieldTypeUpdated = (field: TextField | PhoneNumberField | DateField, newType: string) => {
			store.commit('updateFieldType', { field, newType })
		}

		return { isHidden, fieldTypeUpdated }
	},
})
</script>
