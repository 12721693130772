
import { defineComponent, computed, ref, watch } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import { useEditorStore } from '@/modules/editor/store'
import { FormField } from '@/models/form/definition/field'

export default defineComponent({
	name: 'FieldType',
	components: {
		VueSelect,
	},
	setup(_, context) {
		type FieldType = { name: string; value: FormField['type'] }

		const store = useEditorStore()

		const nonSelectableValues = ['CHECK_BOX', 'RADIO_GROUP', 'RADIO_OPTION']
		const allFields: FieldType[] = [
			{
				name: 'Text Field',
				value: 'TEXT_FIELD',
			},
			{
				name: 'Date Field',
				value: 'DATE_FIELD',
			},
			{
				name: 'Phone Number Field',
				value: 'PHONE_NUMBER_FIELD',
			},
			{
				name: 'Checkbox',
				value: 'CHECK_BOX',
			},
			{
				name: 'Radio Button Group',
				value: 'RADIO_GROUP',
			},
			{
				name: 'Radio Button Option',
				value: 'RADIO_OPTION',
			},
		]

		const fieldType = ref<string>('')

		const field = computed(() =>
			store.getters.selectedFields.length > 0 ? store.getters.selectedFields[0] : { type: '' }
		)

		const fieldChangeDisabled = (field: string) => {
			return nonSelectableValues.includes(field)
		}

		const fieldTypeOptions = computed<FieldType[]>(() => {
			if (store.state.selection.length > 0) {
				return !fieldChangeDisabled(field.value.type.toString())
					? allFields.filter((field) => !nonSelectableValues.includes(field.value))
					: allFields
			}
			return []
		})

		function updateType(fieldType: string) {
			context.emit('fieldTypeUpdated', field.value, fieldType)
		}

		watch(field, (field) => {
			fieldType.value = field?.type ?? ''
		})

		return {
			fieldType,
			fieldTypeOptions,
			fieldChangeDisabled,
			updateType,
		}
	},
})
