// This file was generated by 'bin/generateCSS.ts'.

export const myndshftBlue100 = 0x09294e
export const myndshftBlue200 = 0x0f345e
export const myndshftBlue300 = 0x154d84
export const myndshftBlue400 = 0x146fb8
export const myndshftBlue500 = 0x1882d8
export const myndshftBlue600 = 0x47a2eb
export const myndshftBlue700 = 0x55b5ec
export const myndshftBlue800 = 0x88cef2
export const myndshftBlue900 = 0xbae6f7
export const myndshftGray100 = 0x222222
export const myndshftGray200 = 0x383838
export const myndshftGray300 = 0x525252
export const myndshftGray400 = 0x66676c
export const myndshftGray500 = 0x818181
export const myndshftGray600 = 0xb1b1b1
export const myndshftGray700 = 0xd0d0d0
export const myndshftGray800 = 0xdadada
export const myndshftGray900 = 0xf1f1f1
export const myndshftOrange100 = 0xda3030
export const myndshftOrange200 = 0xe24428
export const myndshftOrange300 = 0xf15e29
export const myndshftOrange400 = 0xf37b3f
export const myndshftOrange500 = 0xf9874e
export const myndshftOrange600 = 0xf69855
export const myndshftOrange700 = 0xf7a76e
export const myndshftOrange800 = 0xfdc6aa
export const myndshftOrange900 = 0xfde7dd

// Added colors for additional information box colors
export const myndshftTealBorder = 0x007f7f
export const myndshftTealBackground = 0x00cccc
