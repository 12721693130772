
import { defineComponent } from 'vue'
import { shortcutOptions } from '@/modules/keyboardshortcuts/keyboard-shortcuts'

export default defineComponent({
	name: 'KeyboardShortcuts',
	setup() {
		const isMac = navigator.appVersion.indexOf('Mac') != -1
		return {
			shortcutOptions,
			isMac,
		}
	},
})
