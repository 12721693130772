<template>
	<div v-if="mappable">
		<section v-if="elementRefType === 'BOOLEAN'">
			<label>Radio Value</label>
			<vue-select
				label="value"
				@update="setBooleanValue"
				:value="toNullable(booleanValue)"
				:options="radioValues"
				:reducer="(value) => value.id"
			></vue-select>
		</section>

		<section v-else-if="elementRefType === 'ENUM'" :addOption="addOption()">
			<label>Radio Value</label>
			<div class="element" v-for="(enumValue, index) of enumValues" :key="index">
				<vue-select
					label="value"
					@update="setEnumValue(index, $event)"
					:value="toNullable(enumValue)"
					:options="filteredRadioValues(enumValue)"
					:reducer="(value) => value.id"
				></vue-select>
				<ion-icon v-if="index !== 0" name="trash" @click="removeEnumValue(index)"></ion-icon>
			</div>
			<div v-if="addButton">
				<button @click="addEnumValue" class="ms-button ms-slim">Add Additional Value</button>
			</div>
		</section>

		<section class="instructions" v-else>
			<em>Select a data element for the radio group.</em>
		</section>
	</div>
	<div v-else>
		<section class="instructions">
			<em>The radio group is currently unmappable.</em>
		</section>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { indexArray } from 'monocle-ts/lib/Index/Array'

import { findByID } from '@/models/data/model'
import { conditional, RadioOption } from '@/models/form/definition/field/variants/radio'
import {
	booleanConditionalValue,
	enumConditionalValue,
	RadioBooleanConditional,
	RadioEnumConditional,
	RadioGroup,
} from '@/models/form/definition/field/variants/radio/radio'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'

export default defineComponent({
	name: 'RadioOptionEditor',
	components: {
		VueSelect,
	},
	props: {
		field: {
			type: Object as PropType<RadioOption>,
			required: true,
		},
		group: {
			type: Object as PropType<RadioGroup>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
	},
	setup(props, context) {
		const addButton = ref(false)
		const mappable = computed(() => props.group.mappable)

		const elementPath = computed(() =>
			props.group.element.length > 0 ? props.group.element[0] : O.none
		)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const elementRefType = computed(() => O.toNullable(elementRef.value)?.type)

		const radioValues = computed(() =>
			pipe(
				elementRef.value,
				O.fold(
					() => [],
					(x) =>
						x.type === 'ENUM'
							? x.values
							: x.type === 'BOOLEAN'
							? [
									{ id: true, value: 'true' },
									{ id: false, value: 'false' },
							  ]
							: []
				)
			).sort(sortOptions)
		)

		const booleanValue = computed(() =>
			pipe(
				props.field.conditional,
				O.filter(RadioBooleanConditional.is),
				O.map((cond) => cond.value)
			)
		)

		const setBooleanValue = (value: boolean | null) => {
			const conditionalValue = pipe(value, O.fromNullable, O.map(booleanConditionalValue))
			context.emit('update', conditional.set(conditionalValue)(props.field))
		}

		const enumValues = computed(() => {
			return pipe(
				props.field.conditional,
				O.filter(RadioEnumConditional.is),
				O.map((cond) => cond.ids),
				O.getOrElse<O.Option<string>[]>(() => [])
			)
		})

		const filteredRadioValues = (enumValue: any) =>
			[...radioValues.value].filter(
				(x) =>
					![...enumValues.value].find(
						(item) => O.isSome(item) && item.value === x.id && item.value !== enumValue.value
					)
			)

		const addEnumValue = () => {
			addButton.value = false
			const conditionalValue = enumConditionalValue(enumValues.value.concat(O.none))
			context.emit('update', conditional.set(O.some(conditionalValue))(props.field))
		}

		const setEnumValue = (index: number, id: string | null) => {
			const idAtIndex = indexArray<O.Option<string>>().index(index)
			const newIds = idAtIndex.set(O.fromNullable(id))(enumValues.value)
			const newValue = O.some(enumConditionalValue(newIds))
			context.emit('update', conditional.set(newValue)(props.field))
		}

		const removeEnumValue = (index: number) => {
			const newIds = pipe(
				enumValues.value,
				A.deleteAt(index),
				O.getOrElse<O.Option<string>[]>(() => []),
				enumConditionalValue
			)
			context.emit('update', conditional.set(O.some(newIds))(props.field))
		}

		const addOption = () => {
			const isNoneOption = [...enumValues.value].filter((option) => O.isNone(option)).length
			if (![...enumValues.value].length) addEnumValue()
			addButton.value = !(
				isNoneOption || [...enumValues.value].length === [...radioValues.value].length
			)
		}

		return {
			mappable,
			elementRefType,
			radioValues,
			booleanValue,
			setBooleanValue,
			enumValues,
			addEnumValue,
			setEnumValue,
			removeEnumValue,
			toNullable: O.toNullable,
			addButton,
			addOption,
			filteredRadioValues,
		}
	},
})
</script>

<style lang="scss" scoped>
section {
	margin: 8px;
}

ion-icon {
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.element {
	display: grid;
	grid-template-columns: 1fr 24px;
	margin-bottom: 8px;
}

.instructions {
	color: red;
}
</style>
