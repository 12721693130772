<template>
	<div class="pa-row">
		<div class="pa-col padding-2">
			<label :for="property.id + '-min-length'" :valid="`${valid.minLength}`">
				Minimum Length:
			</label>
			<input
				:id="property.id + '-min-length'"
				class="width-75"
				type="text"
				v-model="minimumInput"
				@input="updateMinimum($event.target.value)"
			/>
		</div>
		<div class="pa-col padding-2">
			<label :for="property.id + '-max-length'" :valid="`${valid.maxLength}`">
				Maximum Length:
			</label>
			<input
				:id="property.id + '-max-length'"
				class="width-75"
				type="text"
				v-model="maximumInput"
				@input="updateMaximum($event.target.value)"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { ref, defineComponent, watch, onBeforeMount, PropType } from 'vue'
import { StringProperty } from '@/models/form/additional-information'
import {
	DECIMAL_INPUT,
	INTEGER_INPUT,
	validateNumberInput,
} from '@/modules/editor/additional-information/validation'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'

type StringNumberType = string | null | undefined

export default defineComponent({
	name: 'TextAttributes',
	props: {
		textProperty: {
			type: Object as PropType<StringProperty>,
			required: true,
		},
	},
	setup(props, context) {
		const property = ref({} as StringProperty)
		const valid = ref<{
			minLength: boolean
			maxLength: boolean
		}>({
			minLength: true,
			maxLength: true,
		})
		const minimumInput = ref<string | null>(null)
		const maximumInput = ref<string | null>(null)

		function validate(property: StringProperty) {
			const min = property.minLength !== undefined ? property.minLength : null
			const max = property.maxLength !== undefined ? property.maxLength : null
			valid.value = {
				minLength: pipe(
					validateNumberInput(property, min, null, max, true, null, false, true),
					E.isRight
				),
				maxLength: pipe(
					validateNumberInput(property, max, min, null, true, null, false, true),
					E.isRight
				),
			}
		}

		function parseStringToNumber(inputString: StringNumberType): number | null {
			if (inputString) return Number.parseInt(inputString)
			return null
		}

		function isNumber(numberString: string, isInteger: boolean): boolean {
			return (
				(isInteger && INTEGER_INPUT.test(numberString)) ||
				(!isInteger && DECIMAL_INPUT.test(numberString))
			)
		}

		function updateMinimum(numberString: string) {
			if (isNumber(numberString, true)) {
				property.value.minLength = parseStringToNumber(minimumInput.value)
			} else {
				property.value.minLength = numberString
			}

			context.emit('update', { ...property.value })
		}

		function updateMaximum(numberString: string) {
			if (isNumber(numberString, true)) {
				property.value.maxLength = parseStringToNumber(maximumInput.value)
			} else {
				property.value.maxLength = numberString
			}

			context.emit('update', { ...property.value })
		}

		watch(
			() => props.textProperty,
			(textProperty) => {
				if (property.value.id !== textProperty?.id) {
					minimumInput.value =
						textProperty?.minLength || textProperty?.minLength === 0
							? `${textProperty.minLength}`
							: null

					maximumInput.value =
						textProperty?.maxLength || textProperty?.maxLength === 0
							? `${textProperty.maxLength}`
							: null
				}
				property.value = textProperty as StringProperty
				validate(property.value)
			}
		)

		onBeforeMount(() => {
			property.value = props.textProperty as StringProperty
			minimumInput.value =
				property.value.minLength || property.value.minLength === 0
					? `${property.value.minLength}`
					: null
			maximumInput.value =
				property.value.maxLength || property.value.maxLength === 0
					? `${property.value.maxLength}`
					: null
			validate(property.value)
		})

		return { property, valid, minimumInput, maximumInput, updateMinimum, updateMaximum }
	},
})
</script>

<style lang="scss" scoped></style>
