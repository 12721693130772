<template>
	<div class="keyboard-shortcuts">
		<h1 class="header">Keyboard Shortcuts</h1>
		<div class="shortcuts-container">
			<div class="box" v-for="shortcut in shortcutOptions" :key="shortcut.binding">
				<div class="description">{{ shortcut.description }}</div>
				<div class="binding">{{ isMac && shortcut.mac ? shortcut.mac : shortcut.binding }}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { shortcutOptions } from '@/modules/keyboardshortcuts/keyboard-shortcuts'

export default defineComponent({
	name: 'KeyboardShortcuts',
	setup() {
		const isMac = navigator.appVersion.indexOf('Mac') != -1
		return {
			shortcutOptions,
			isMac,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.keyboard-shortcuts {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 10px 25px 25px;

	.header {
		color: $myndshft-gray-200;
		font-size: 35px;
		font-weight: 400;
		padding-bottom: 20px;
	}
}

.shortcuts-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	background-color: $myndshft-gray-900;
	height: 100%;
	width: 100%;

	.box {
		&:nth-child(odd) {
			border-right: 1px solid $myndshft-gray-700;
		}
		display: flex;
		align-items: center;

		.description {
			color: $myndshft-gray-200;
			font-size: 20px;
			width: 60%;
			padding-left: 20px;
		}

		.binding {
			padding: 5px 12px;
			background-color: #fff;
			border: 1px solid $myndshft-gray-200;
			border-radius: 5px;
		}
	}
}
</style>
