import { Ref, isRef, watch, onMounted, onUnmounted } from 'vue'
import hotkeys, { KeyHandler } from 'hotkeys-js'

// For whatever reason, this type isn't exported in the hotkeys library.
// https://github.com/jaywcjlove/hotkeys/blob/d3c0/index.d.ts#L13-L19
export type Options = {
	scope?: string
	element?: HTMLElement | null
	keyup?: boolean | null
	keydown?: boolean | null
	splitKey?: string
}

/**
 * Composition function for registering keyboard hotkeys in components. The
 * hook takes care of the binding and the unbinding. When the component mounts
 * onto the DOM, it will listen for the hotkeys. When the component unmounts,
 * it will stop listening.
 *
 * If `handler` is a Ref, then the hook will take care of unbinding and
 * rebinding the listener when the ref object's inner value is mutated.
 */
export function useHotkeys(
	keyEvent: string,
	handler: KeyHandler | Ref<KeyHandler>,
	options: Options = {}
) {
	onMounted(() => {
		hotkeys(keyEvent, options, isRef(handler) ? handler.value : handler)
	})

	onUnmounted(() => {
		hotkeys.unbind(keyEvent, isRef(handler) ? handler.value : handler)
	})

	if (isRef(handler)) {
		watch(
			() => [handler.value],
			([handler, oldHandler]) => {
				hotkeys.unbind(keyEvent, oldHandler)
				hotkeys(keyEvent, options, handler)
			}
		)
	}
}
