import * as A from 'fp-ts/lib/Array'
import { UUID } from 'io-ts-types/lib/UUID'
import { Lens, Optional, fromTraversable } from 'monocle-ts'

import { definition, additionalInformation } from '@/models/form'
import { fields, metadata as formMetadata } from '@/models/form/definition'
import {
	formRegistryIso,
	fieldAt,
	type,
	checkboxField,
	radioGroup as radioGroupP,
	radioOption,
	mappableField,
	MappableField,
	FormField,
	textField,
} from '@/models/form/definition/field'
import { RadioGroup, RadioOption, conditional } from '@/models/form/definition/field/variants/radio'

import { State } from './state'
import {
	conditionalId,
	conditionalValue,
} from '@/models/form/definition/field/variants/checkbox/optics'
import { defaultValue } from '@/models/form/definition/field/variants/text'
import { delimiter, multiline } from '@/models/form/definition/field/variants/text/optics'
import { format as dateFormat } from '@/models/form/definition/field/variants/date/optics'
import { format as phoneNumberFormat } from '@/models/form/definition/field/variants/phone/optics'
import {
	dateField,
	phoneNumberField,
	singleMappableField,
	unmappableField,
} from '@/models/form/definition/field/optics'
import { SingleMappableField, UnmappableField } from '@/models/form/definition/field/field'

export const form = Optional.fromOptionProp<State>()('form')
export const pages = Lens.fromProp<State>()('pages')
export const selection = Lens.fromProp<State>()('selection')

export const formDefinition = form.composeLens(definition)
export const formDefinitionMetadata = formDefinition.composeLens(formMetadata)
export const formFields = formDefinition.composeLens(fields)
export const formField = (id: UUID): Optional<State, FormField> =>
	formFields.composeOptional(fieldAt(id))
export const formFieldType = (id: UUID) => formField(id).composeLens(type)
export const formAdditionalInformation = form.composeLens(additionalInformation)

export const formFieldName = (id: UUID) =>
	formField(id).composeLens(Lens.fromPath<FormField>()(['metadata', 'name']))

export const formFieldElement = (id: UUID) =>
	formField(id)
		.composePrism(singleMappableField)
		.composeLens(Lens.fromProp<SingleMappableField>()('element'))

export const formFieldElements = (id: UUID) =>
	formField(id).composePrism(mappableField).composeLens(Lens.fromProp<MappableField>()('element'))

export const mappableFlag = (id: UUID) =>
	formField(id)
		.composePrism(unmappableField)
		.composeLens(Lens.fromProp<UnmappableField>()('mappable'))

export const textFieldMultiline = (id: UUID) =>
	formField(id).composePrism(textField).composeLens(multiline)

export const textFieldDefaultValue = (id: UUID) =>
	formField(id).composePrism(textField).composeLens(defaultValue)

export const textFieldDelimiter = (id: UUID) =>
	formField(id).composePrism(textField).composeLens(delimiter)

export const dateFieldFormat = (id: UUID) =>
	formField(id).composePrism(dateField).composeLens(dateFormat)

export const phoneNumberFieldFormat = (id: UUID) =>
	formField(id).composePrism(phoneNumberField).composeLens(phoneNumberFormat)

export const checkboxConditionalId = (id: UUID) =>
	formField(id).composePrism(checkboxField).composeLens(conditionalId)

export const checkboxConditionalValue = (id: UUID) =>
	formField(id).composePrism(checkboxField).composeLens(conditionalValue)

export const radioOptionConditional = (id: UUID) =>
	formField(id).composePrism(radioOption).composeLens(conditional)

export const radioGroup = (id: UUID) => formField(id).composePrism(radioGroupP)

export const radioGroupId = (id: UUID) =>
	formField(id).composePrism(radioGroupP).composeLens(Lens.fromProp<RadioGroup>()('id'))

export const radioOptionParentId = (id: UUID) =>
	formField(id).composePrism(radioOption).composeLens(Lens.fromProp<RadioOption>()('parent'))

export const radioOptionWithParent = (id: UUID) =>
	formFields
		.composeIso(formRegistryIso.reverse())
		.composeTraversal(fromTraversable(A.array)<FormField>())
		.composePrism(radioOption)
		.filter((o) => o.parent === id)

export const radioOptionIdsWithParent = (id: UUID) =>
	radioOptionWithParent(id).composeLens(Lens.fromProp<RadioOption>()('id'))

export const radioOptionWithParentConditional = (id: UUID) =>
	radioOptionWithParent(id).composeLens(conditional)
