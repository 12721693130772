
import { computed, defineComponent, PropType, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	createNewGenericProperty,
	createNewGroupProperty,
	findPropertyByID,
} from '@/modules/editor/additional-information/properties'
import { isGroupProperty, Property } from '@/models/form/additional-information'
import { useEditorStore } from '@/modules/editor/store'
import { UUID } from 'io-ts-types/lib/UUID'
import ColorPreview from '@/components/ColorPreview/ColorPreview.vue'
import { additionalInformationMappedColors } from '@/models/form/definition/field'

export default defineComponent({
	name: 'PropertySelection',
	components: {
		FontAwesomeIcon,
		ColorPreview,
	},
	props: {
		property: Object,
		properties: {
			type: Array as PropType<Property[]>,
			default: () => [],
		},
		index: {
			type: Number,
			default: () => 0,
		},
		mappedProperties: {
			type: Array,
			default: () => [],
		},
		invalidProperies: {
			type: Array,
			default: () => [],
		},
	},
	setup(props) {
		const store = useEditorStore()
		const expanded = ref<boolean>(false)

		const isMapped = computed<boolean>(() =>
			props.mappedProperties.includes(additionalProperty.value.fullModelPath)
		)
		const squareBackground = computed<number>(() =>
			isMapped.value ? additionalInformationMappedColors.backgroundColor : 0xffffff
		)
		const squareBorder = computed<number>(() =>
			isMapped.value ? additionalInformationMappedColors.borderColor : 0xffffff
		)

		const additionalProperty = computed<Property>(() => {
			return (props.property ?? {}) as Property
		})

		const propertyGroupLength = computed(() => {
			if (isGroupProperty(additionalProperty.value)) {
				return props.properties?.length ?? 0
			} else {
				const parent = additionalProperty.value.parentId
					? findPropertyByID(additionalProperty.value.parentId, props.properties)
					: null
				return parent && isGroupProperty(parent) ? parent.properties.length : 0
			}
		})
		const selected = computed<boolean>(() => {
			return store.getters['selectedAdditionalProperty'].id === additionalProperty.value.id
		})

		const addNewGroup = (parentId: string) => {
			const property = createNewGroupProperty()
			store.commit('addAdditionalProperty', { property, parentId })
		}

		const addNewProperty = (parentId: string) => {
			const property = createNewGenericProperty()
			store.commit('addAdditionalProperty', { property, parentId })
			expanded.value = true
		}

		const removeProperty = (id: string) => {
			store.commit('setSelectedAdditionalProperty', null)
			store.commit('removeAdditionalProperty', id)
		}

		const selectProperty = (property: Property) => {
			store.commit('setSelectedAdditionalProperty', property)
		}

		const getPropertyTitle = () => {
			return additionalProperty.value.modelValue
				? additionalProperty.value.modelValue
				: additionalProperty.value.id
		}

		const toggleGroup = () => (expanded.value = !expanded.value)

		function shiftProperty(id: UUID, from: number, to: number) {
			const properties = props.properties
			const property: Property | null = findPropertyByID(id, properties)

			if (property) {
				const parent: Property | null = property.parentId
					? findPropertyByID(property.parentId, properties)
					: null

				if (!parent && properties[from].id === id) {
					properties.splice(from, 1)
					properties.splice(to, 0, property)
				} else if (parent && isGroupProperty(parent)) {
					const parentIndex = properties.findIndex((property) => property.id === parent.id)

					parent.properties.splice(from, 1)
					parent.properties.splice(to, 0, property)

					if (parentIndex !== -1) properties[parentIndex] = parent
				}
				store.commit('setAdditionalInformation', properties)
			}
		}

		const shiftUp = (id: UUID) => {
			if (props.index - 1 >= 0) shiftProperty(id, props.index, props.index - 1)
		}

		const shiftDown = (id: UUID) => {
			if (props.index + 1 < propertyGroupLength.value)
				shiftProperty(id, props.index, props.index + 1)
		}

		return {
			additionalProperty,
			expanded,
			selected,
			isMapped,
			squareBorder,
			squareBackground,
			propertyGroupLength,
			shiftUp,
			shiftDown,
			addNewGroup,
			toggleGroup,
			addNewProperty,
			removeProperty,
			selectProperty,
			getPropertyTitle,
		}
	},
})
