import { Ref, watch, onUnmounted } from 'vue'
import { useService } from '@xstate/vue'

import { v4 as uuidv4 } from 'uuid'
import { UUID } from 'io-ts-types/lib/UUID'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as PIXI from 'pixi.js-legacy'
import { Coordinates } from '@/components/Modal/types'
import { toast } from '@/components/Toast'

import { FormFieldPosition } from '@/models/form/definition/field/position'
import { FormFieldDimensions } from '@/models/form/definition/field/dimensions'
import { createTextField } from '@/models/form/definition/field/variants/text'
import { createCheckboxField } from '@/models/form/definition/field/variants/checkbox'
import { createRadioGroup, createRadioOption } from '@/models/form/definition/field/variants/radio'
import { createDateField } from '@/models/form/definition/field/variants/date'
import { createPhoneNumberField } from '@/models/form/definition/field/variants/phone'

import { toolboxService } from '@/modules/editor/toolbox'
import { useEditorStore } from '@/modules/editor/store'

import { myndshftOrange300, myndshftOrange900 } from '@/styles/colors'

/**
 * Returns `true` if the `inner` [[PIXI.Rectangle]] is fully contained within
 * the `outer` [[PIXI.Rectangle]].
 */
const contains = (outer: PIXI.Rectangle) => (inner: PIXI.Rectangle) => {
	return (
		outer.contains(inner.x, inner.y) &&
		outer.contains(inner.x + inner.width, inner.y + inner.height)
	)
}

/**
 * Computes the local position of a bounding box relative to its parent
 * bounding box.
 */
const getLocalPosition = (parent: PIXI.Rectangle) => (child: PIXI.Rectangle) => ({
	x: child.x - parent.x,
	y: child.y - parent.y,
})

/**
 * Maps a coordinate pair from `[0, width] × [0, length] -> [0, 1] × [0, 1]`.
 */
const normalizePosition = (bounds: FormFieldDimensions) => (position: FormFieldPosition) => ({
	x: position.x / bounds.width,
	y: position.y / bounds.height,
})

/**
 * Maps field dimensions from `[0, width] × [0, length] -> [0, 1] × [0, 1]`.
 */
const normalizeDimensions = (parent: FormFieldDimensions) => (child: FormFieldDimensions) => ({
	width: child.width / parent.width,
	height: child.height / parent.height,
})

/**
 * Computes the global position of the mouse cursor.
 */
function getGlobalPosition(
	app: PIXI.Application,
	event: PIXI.interaction.InteractionEvent,
	zoomScale: number
): Coordinates {
	return {
		//     [ pan offset ]     +  [ mouse position ]  * [ scale adjustment ]
		x: (-app.stage.position.x + event.data.global.x) * (1 / zoomScale),
		y: (-app.stage.position.y + event.data.global.y) * (1 / zoomScale),
	}
}

/**
 * Returns the index of the form page that completely contains the field.
 */
function findEnclosedPage(field: PIXI.Graphics): O.Option<{ page: number; field: PIXI.Graphics }> {
	const store = useEditorStore()
	const page = Object.keys(store.state.pages).find((i) => {
		const pageBounds = store.state.pages[Number(i)].getBounds()
		return contains(pageBounds)(field.getBounds())
	})

	return pipe(
		page,
		O.fromNullable,
		O.map((page) => ({
			page: Number(page),
			field,
		}))
	)
}

/**
 * Saves the field to the registry.
 */
function saveField(field: PIXI.Graphics, page: number): UUID {
	const store = useEditorStore()
	const { state } = useService(toolboxService)

	const pageBounds = store.state.pages[page].getBounds()
	const fieldBounds = field.getBounds()

	// prettier-ignore
	const position = pipe(
		fieldBounds,
		getLocalPosition(pageBounds),
		normalizePosition(pageBounds)
	)

	// prettier-ignore
	const dimensions = pipe(
		fieldBounds,
		normalizeDimensions(pageBounds)
	)

	const id = uuidv4() as UUID
	if (state.value.matches('fieldType.text')) {
		store.commit('addField', createTextField(id, O.none, page, position, dimensions))
	} else if (state.value.matches('fieldType.checkbox')) {
		store.commit('addField', createCheckboxField(id, O.none, page, position, dimensions))
	} else if (state.value.matches('fieldType.radio')) {
		const selectedGroupId = store.getters.selectedRadioGroupId as O.Option<UUID>
		const groupId = pipe(
			selectedGroupId,
			O.getOrElse(() => {
				const groupId = uuidv4() as UUID
				store.commit('addField', createRadioGroup(groupId, O.none))
				return groupId
			})
		)
		store.commit('addField', createRadioOption(id, groupId, O.none, page, position, dimensions))
	} else if (state.value.matches('fieldType.date')) {
		store.commit('addField', createDateField(id, O.none, page, position, dimensions))
	} else if (state.value.matches('fieldType.phoneNumber')) {
		store.commit('addField', createPhoneNumberField(id, O.none, page, position, dimensions))
	}

	return id
}

/**
 * Draws resizing handles on the bounding box.
 */
export function drawHandles(
	graphics: PIXI.Graphics,
	zoomScale: number,
	start: FormFieldPosition,
	end: FormFieldPosition,
	color: number = myndshftOrange300
) {
	const handleSize = 6
	const handle = {
		size: handleSize * (1 / zoomScale),
		topLeft: {
			x: start.x - (handleSize / 2) * (1 / zoomScale),
			y: start.y - (handleSize / 2) * (1 / zoomScale),
		},
		topRight: {
			x: end.x - (handleSize / 2) * (1 / zoomScale),
			y: start.y - (handleSize / 2) * (1 / zoomScale),
		},
		bottomLeft: {
			x: start.x - (handleSize / 2) * (1 / zoomScale),
			y: end.y - (handleSize / 2) * (1 / zoomScale),
		},
		bottomRight: {
			x: end.x - (handleSize / 2) * (1 / zoomScale),
			y: end.y - (handleSize / 2) * (1 / zoomScale),
		},
	}

	graphics
		.beginFill(color, 1.0)
		.drawRect(handle.topLeft.x, handle.topLeft.y, handle.size, handle.size)
		.drawRect(handle.topRight.x, handle.topRight.y, handle.size, handle.size)
		.drawRect(handle.bottomLeft.x, handle.bottomLeft.y, handle.size, handle.size)
		.drawRect(handle.bottomRight.x, handle.bottomRight.y, handle.size, handle.size)
		.endFill()
}

/**
 * Composition hook that enables drawing bounding boxes around fields.
 */
export function useDrawing(app: Ref<O.Option<PIXI.Application>>, zoomScale: Ref<number>) {
	const { state, send } = useService(toolboxService)

	let field: O.Option<PIXI.Graphics> = O.none
	let start: Coordinates
	let end: Coordinates

	// Just the bounding box without the handles or outlines
	let boundingBox: O.Option<PIXI.Graphics> = O.none

	const drawField = (app: PIXI.Application) => {
		// Remove old drawings
		if (O.isSome(field)) {
			app.stage.removeChild(field.value)
			field.value.destroy()
		}
		if (O.isSome(boundingBox)) {
			app.stage.removeChild(boundingBox.value)
			boundingBox.value.destroy()
		}

		// Draw bounding box
		const newField = new PIXI.Graphics()
		newField.zIndex = 100

		newField
			.beginFill(myndshftOrange900, 0.5)
			.lineStyle(1 / zoomScale.value + 1, myndshftOrange300, 1)
			.drawRect(
				// We have to draw the bounding box such that the origin is at the
				// top-left. Negative widths and heights break PIXI's bounding box
				// calculations.
				Math.min(start.x, end.x),
				Math.min(start.y, end.y),
				Math.abs(end.x - start.x),
				Math.abs(end.y - start.y)
			)
			.endFill()

		// Draw handles
		drawHandles(newField, zoomScale.value, start, end)

		field = O.some(app.stage.addChild(newField))
		boundingBox = O.some(
			app.stage.addChild(
				new PIXI.Graphics().drawRect(
					Math.min(start.x, end.x),
					Math.min(start.y, end.y),
					Math.abs(end.x - start.x),
					Math.abs(end.y - start.y)
				)
			)
		)
	}

	const stopAppWatcher = watch([app], ([app]) => {
		if (O.isSome(app)) {
			app.value.stage.on('mousedown', (event: PIXI.interaction.InteractionEvent) => {
				if (
					state.value.matches('tool.draw.idle') &&
					event.data.originalEvent instanceof MouseEvent
				) {
					// Start drawing
					start = getGlobalPosition(app.value, event, zoomScale.value)
					send('START_DRAW')

					// Stop drawing
					window.addEventListener(
						'mouseup',
						() => {
							send('STOP_DRAW')

							if (O.isSome(field)) {
								const store = useEditorStore()

								// Check that the drawn form field is completely contained
								// inside of a form page -- if it is, then save it to the
								// registry.
								pipe(
									boundingBox,
									O.chain(findEnclosedPage),
									O.fold(
										() => toast('Bounding boxes must be drawn inside the form pages'),
										({ page, field }) => {
											const id = saveField(field, page)
											store.commit('setSelection', [id])
											send('SELECT_MOVE')
										}
									)
								)

								// Now that the field is stored in the registry, we can
								// remove it here. From now on, the bounding box manager will
								// take care of rendering the field.
								if (O.isSome(field)) {
									app.value.stage.removeChild(field.value)
									field.value.destroy()
								}
								field = O.none
							}
						},
						{ once: true }
					)
				}
			})

			app.value.stage.on('mousemove', (event: PIXI.interaction.InteractionEvent) => {
				// Record the position of the mouse cursor
				if (event.data.originalEvent instanceof MouseEvent) {
					end = getGlobalPosition(app.value, event, zoomScale.value)
				}

				// Resize the bounding box when user moves their cursor
				if (state.value.matches('tool.draw.drawing')) {
					drawField(app.value)
				}
			})
		}
	})

	// The stroke width of the bounding box needs to be updated when the scale
	// changes. To the user, it should have the same width (in pixels) regardless
	// of how zoomed in the canvas is.
	const stopZoomWatcher = watch([zoomScale], () => {
		if (O.isSome(app.value)) {
			if (state.value.matches('tool.draw.drawing')) {
				drawField(app.value.value)
			}
		}
	})

	onUnmounted(() => {
		stopAppWatcher()
		stopZoomWatcher()
	})
}
