<template>
	<div class="property-panel-container">
		<div class="property-panel-header">
			<span>Properties</span>
			<div>
				<span class="property-btn" @click="addNewGroup">
					<font-awesome-icon class="btn-icon" icon="plus-circle" />
				</span>
			</div>
		</div>
		<div v-if="additionalInformation.length" class="property-panel-body">
			<div
				class="property-row"
				v-for="(property, index) in additionalInformation"
				:key="property.id"
			>
				<property-selection
					:invalidProperies="invalidProperties"
					:property="property"
					:properties="additionalInformation"
					:index="index"
					:mappedProperties="mappedAdditionalInformationFields"
				></property-selection>
			</div>
		</div>
		<div v-else>
			<span class="padding-10">No properties exist.</span>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createNewGroupProperty } from '@/modules/editor/additional-information/properties'
import { Property } from '@/models/form/additional-information'
import PropertySelection from '@/components/AdditionalInformation/Panels/Properties/PropertySelection.vue'
import { useEditorStore } from '@/modules/editor/store'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'
import { InvalidProperty } from '@/modules/editor/additional-information'

export default defineComponent({
	name: 'PropertyPanel',
	components: {
		PropertySelection,
		'font-awesome-icon': FontAwesomeIcon,
	},
	setup() {
		const store = useEditorStore()
		const mappedAdditionalInformationFields = ref<string[]>(
			store.getters.allFieldsMappedToAdditionalInformation
		)
		const invalidProperties = computed<string[]>(() =>
			pipe(
				store.getters['additionalInformationAreValid'] as E.Either<InvalidProperty[], true>,
				E.fold(
					(aI) =>
						aI.map((ip: InvalidProperty) => (Array.isArray(ip.property) ? '' : ip.property.id)),
					() => []
				)
			)
		)

		const additionalInformation = computed<Property[]>(() => {
			return store.getters['additionalInformation']
		})

		const addNewGroup = () => {
			const property = createNewGroupProperty()
			store.commit('addAdditionalProperty', { property })
		}

		return {
			additionalInformation,
			mappedAdditionalInformationFields,
			addNewGroup,
			invalidProperties,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.property-panel-container {
	display: flex;
	flex-direction: column;
	width: 510px;
}

.property-panel-header {
	display: flex;
	padding: 8px;
	margin-bottom: 8px;
	font-size: 18px;
	color: white;
	justify-content: space-between;
	background-color: $myndshft-orange-300;
}

.property-panel-body {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow-x: auto;
	max-height: 470px;
	padding: 8px;
}

.property-btn {
	margin-right: 8px;
}
.property-btn:hover {
	cursor: pointer;
}
.property-btn > .btn-icon:hover {
	transform: translateY(-1px);
}

.property-row {
	display: flex;
	flex-direction: row;
	padding: 8px;
	width: 100%;
	justify-content: space-between;
	cursor: pointer;
}

.padding-10 {
	padding: 10px;
}
</style>
