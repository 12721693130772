
import { defineComponent, computed, ref, watch, Ref } from 'vue'
import { useToolbox } from '@/modules/editor/toolbox/hook'
import { useDrawing, useBoxManager } from '@/modules/editor/bounding-box'
import { usePanning, useZooming } from '@/modules/editor/camera'
import { initialize } from '@/modules/editor/renderer'

import { UUID } from 'io-ts-types/lib/UUID'
import { constVoid, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import * as PIXI from 'pixi.js-legacy'

export default defineComponent({
	name: 'renderer',
	props: {
		formId: { type: String, required: true },
	},
	setup(props) {
		const container = ref<HTMLDivElement | null>(null)
		const maybeContainer = computed(() => O.fromNullable(container.value))
		const app = ref<O.Option<PIXI.Application>>(O.none)

		const { state, send } = useToolbox()
		const { zoomScale } = useZooming(app as Ref<O.Option<PIXI.Application>>, maybeContainer, state)
		usePanning(app as Ref<O.Option<PIXI.Application>>, maybeContainer, state, send)
		useDrawing(app as Ref<O.Option<PIXI.Application>>, zoomScale)

		watch([maybeContainer], ([container]) => {
			pipe(
				container,
				O.fold(constVoid, (container) => {
					// Initialize PIXI application
					app.value = pipe(initialize(container, props.formId as UUID)(), O.some)

					// Initialize bounding box manager
					if (O.isSome(app.value)) {
						useBoxManager((app as Ref<O.Some<PIXI.Application>>).value.value, zoomScale)
					}
				})
			)
		})

		return {
			container,
			state,
		}
	},
})
