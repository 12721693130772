<template>
	<div>
		<toolbar-container>
			<div class="toolbar-content">
				<toolbox @show-shortcuts-modal="showShortcutsModal"></toolbox>
				<div class="toolbar-buttons">
					<button
						v-if="!ms19118DisableAdditionalInformation"
						class="ms-button ms-slim"
						@click="toggleAdditionalInformation"
					>
						Additional Information
					</button>
					<button class="ms-button ms-slim" @click="saveChanges" :disabled="isSaving">
						{{ isSaving ? 'Saving...' : 'Save Changes' }}
					</button>
					<button class="ms-button ms-slim" @click="logout">Logout</button>
				</div>
			</div>
		</toolbar-container>

		<renderer v-if="formId != null" :formId="formId"></renderer>

		<div class="left">
			<div class="left-content">
				<navigator-list></navigator-list>
			</div>
		</div>
		<div class="right">
			<div class="right-content">
				<field-editor></field-editor>
			</div>
		</div>
		<modal
			@show="onAdditionalInformationShow"
			@hide="onAdditionalInformationHide"
			:max-width="1280"
			:max-height="760"
			ref="additionalInformationModal"
			:overlayClickHandler="closeModal"
			><additional-information
				@saveForm="saveChanges"
				:closeHandler="closeModal"
			></additional-information
		></modal>

		<modal ref="shortcuts" :max-height="650">
			<keyboard-shortcuts></keyboard-shortcuts>
		</modal>
	</div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import ToolbarContainer from '@/components/Toolbar/Container'
import Toolbox from '@/components/Toolbar/Toolbox'
import NavigatorList from '@/components/NavigatorList'
import Renderer from '@/components/Renderer'
import FieldEditor from '@/components/FieldEditor'
import Modal from '@/components/Modal'
import KeyboardShortcuts from '@/components/KeyboardShortcuts'
import AdditionalInformation from '@/components/AdditionalInformation'
import { toast } from '@/components/Toast'

import { useConfigStore } from '@/modules/mynd-config'
import { useMyndmapApi, getApiErrorMessage } from '@/modules/api'
import { useAuthStore } from '@/modules/auth/store'
import { useEditorStore } from '@/modules/editor/store'
import { useFormSaving } from '@/modules/editor/save'
import { useStoreHotkeys } from '@/modules/editor/store/hook'
import { loadDataModel } from '@/components/FieldEditor/DataModel/hooks'
import { useHotkeys } from '@/modules/hotkeys'

import { error as logError } from 'fp-ts/lib/Console'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/lib/Either'
import { UUID } from 'io-ts-types/lib/UUID'
import { constVoid } from 'fp-ts/lib/function'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { useService } from '@xstate/vue'
import { modalService } from '@/components/Modal/machine'
import { useFlagsStore } from '@/modules/flags/store'

export default defineComponent({
	name: 'Editor',
	layout: 'Default',
	components: {
		[NavigatorList.name]: NavigatorList,
		[Renderer.name]: Renderer,
		[ToolbarContainer.name]: ToolbarContainer,
		[Toolbox.name]: Toolbox,
		[FieldEditor.name]: FieldEditor,
		[Modal.name]: Modal,
		[KeyboardShortcuts.name]: KeyboardShortcuts,
		[AdditionalInformation.name]: AdditionalInformation,
	},
	setup() {
		const configStore = useConfigStore()
		const flagStore = useFlagsStore()
		const config = computed(() => configStore.getters['getConfig'])
		const store = useEditorStore()
		store.commit('reset')
		useStoreHotkeys()
		const route = useRoute()
		const formId = ref<UUID | null>(null)
		const isSaving = ref<boolean>(false)
		const logoutRequest = ref<boolean>(false)
		const { state, send } = useService(modalService)

		let saveChanges = ref<() => void>(() => ({}))

		// Hydrate Vuex store
		loadDataModel()
		const { getForm } = useMyndmapApi(config)

		const authStore = useAuthStore()
		const logout = () => {
			if (formHasPendingChanges()) {
				logoutRequest.value = true
				authStore.dispatch('logout')
			}
		}

		const shortcuts = ref<InstanceType<typeof Modal> | null>(null)
		const showShortcutsModal = computed(() => shortcuts.value?.showHandler ?? constVoid)

		// Additional Information
		const additionalInformationModal = ref<InstanceType<typeof Modal> | null>(null)
		const toggleAdditionalInformation = computed(
			() => additionalInformationModal.value?.showHandler ?? constVoid
		)
		const ms19118DisableAdditionalInformation = computed(
			() => flagStore.getters['featureFlags']?.['ms19118DisableAdditionalInformation']
		)

		// hotkey bindings
		useHotkeys('z', () => {
			if (additionalInformationModal.value) {
				// mouse event simulates click at middle of Additional Information button
				additionalInformationModal.value.showHandler({ clientX: 1055, clientY: 20 } as MouseEvent)
			}
		})
		useHotkeys('ctrl+s', () => {
			if (!isSaving.value) {
				saveChanges.value()
			}
		})
		useHotkeys('ctrl+h', () => {
			if (shortcuts.value) {
				// mouse event simulates click at middle of help button
				shortcuts.value.showHandler({ clientX: 200, clientY: 20 } as MouseEvent)
			}
		})

		onMounted(() => {
			formId.value = route.params.id as UUID
			const context = useFormSaving(formId.value)
			saveChanges.value = context.saveDefinitionChanges
			watch(
				() => context.isSaving.value,
				() => (isSaving.value = context.isSaving.value)
			)

			getForm(formId.value)().then((result) => {
				pipe(
					result,
					E.mapLeft((error) => {
						if (error.type === 'REQUEST' && error.context.type === 'VALIDATION') {
							logError(getApiErrorMessage(error))()
							return 'Error opening form, contents are old or corrupted'
						} else {
							return getApiErrorMessage(error)
						}
					}),
					E.fold(
						(error) => toast(error),
						(form) => {
							store.commit('loadForm', form)
						}
					)
				)
			})
		})

		function confirmAdditionalInformationChanges() {
			if (store.getters.additionalInformationHasPendingChanges) {
				const answer = confirm(
					'Unsaved changes will be lost.\nAre you sure you want to close Additional Information?'
				)
				return answer
			}
			return true
		}

		const closeModal = () => {
			if (confirmAdditionalInformationChanges()) {
				store.commit('setSelectedAdditionalProperty', null)
				send({ type: 'HIDE' })
			} else {
				if (state.value.matches('overlay')) send('TRANSITION_TO_OPEN')
			}
		}

		function formHasPendingChanges() {
			if (store.getters.formHasPendingChanges) {
				const answer = confirm(
					'Unsaved changes will be lost.\nAre you sure you want to close the form?'
				)
				return answer
			}
			return true
		}

		function isModalOpen() {
			return state.value.matches('open') || state.value.matches('overlay')
		}

		onBeforeRouteLeave(() => {
			if (!logoutRequest.value) {
				const confirmation = formHasPendingChanges()
				if (confirmation && isModalOpen()) {
					send('FORCE_HIDE')
				}
				return confirmation
			}
			return true
		})

		const onAdditionalInformationShow = () => {
			store.commit('cacheAdditionalInformation')
		}

		const onAdditionalInformationHide = () => {
			store.commit('restoreCachedAdditionalInformation')
		}

		return {
			formId,
			isSaving,
			saveChanges,
			logout,
			shortcuts,
			showShortcutsModal,
			ms19118DisableAdditionalInformation,

			// Additional Information
			additionalInformationModal,
			toggleAdditionalInformation,
			closeModal,
			onAdditionalInformationShow,
			onAdditionalInformationHide,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.toolbar-content {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.toolbar-buttons {
		margin-right: 8px;
	}

	button {
		margin-right: 8px;
	}

	.additional-information-btn {
		margin-right: 8px;
		color: white;
	}
}

.left {
	top: 40px;
	left: 0;
	height: calc(100vh - 40px);
	position: absolute;
	width: 350px;
	background: white;
	border-right: 1px solid $myndshft-gray-900;
}

.left-content {
	height: 100%;
	overflow-y: hidden;
}

.right {
	top: 40px;
	right: 0;
	height: calc(100vh - 40px);
	position: absolute;
	width: 350px;
	background: white;
	border-left: 1px solid $myndshft-gray-900;
}

.right-content {
	height: 100%;
	overflow-y: hidden;
}

section {
	padding: 8px;
}

label {
	color: $myndshft-gray-400;
	text-transform: uppercase;
	font-size: 12px;
}
</style>
