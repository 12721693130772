
import { defineComponent, computed } from 'vue'
import { useEditorStore } from '@/modules/editor/store'
import { FormField } from '@/models/form/definition/field'

import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'

export default defineComponent({
	name: 'UnmappableConversion',
	setup() {
		const store = useEditorStore()
		const field = computed(() => A.head(store.getters.selectedFields as FormField[]))

		const setUnmappable = () =>
			pipe(
				field.value,
				O.map((field) => {
					store.commit('setMappableFlag', {
						id: field.id,
						mappable: false,
					})
				})
			)

		return {
			setUnmappable,
		}
	},
})
