import {
	BASE_CP_DATA_ELEMENT_PATH,
	EnumProperty,
	GroupProperty,
	isGroupProperty,
	Property,
} from '@/models/form/additional-information'
import {
	getUniquePropertyType,
	UniquePropertyType,
} from '@/models/form/additional-information/property-types'
import {
	checkboxField,
	FormField,
	MappableField,
	radioOption,
} from '@/models/form/definition/field'
import { SingleMappableField } from '@/models/form/definition/field/field'
import {
	mappableField,
	singleMappableField,
	radioGroup,
} from '@/models/form/definition/field/optics'
import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'

import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as T from 'monocle-ts/Traversal'
import { Lens } from 'monocle-ts'
import { RadioGroup, RadioOption } from '@/models/form/definition/field/variants/radio'
import { ElementRef } from '@/models/form/definition/field/element'
import { conditionalId } from '@/models/form/definition/field/variants/checkbox'
import {
	conditionalOptional,
	enumConditional,
} from '@/models/form/definition/field/variants/radio/optics'
import { RadioEnumConditional } from '@/models/form/definition/field/variants/radio/radio'

interface GenericPropertySummary {
	id: string
	label: string | null
	title: string | null
	type: Exclude<UniquePropertyType, EnumPropertySummary['type'] | GroupPropertySummary['type']>
	fullModelPath: string
}

interface EnumPropertySummary {
	id: string
	label: string | null
	title: string | null
	type: EnumProperty['type']
	fullModelPath: string
	enum: string[]
}

interface GroupPropertySummary {
	id: string
	label: string | null
	title: string | null
	type: GroupProperty['type']
	fullModelPath: string

	// Keep track of the properties contained under this property to later
	// determine whether updating this group property affects the form mapping.
	descendantFullModelPaths: string[]
}

type PropertySummary = GenericPropertySummary | EnumPropertySummary | GroupPropertySummary

interface PropertyAddition {
	type: 'PROPERTY_ADDITION'
	property: PropertySummary
}

interface PropertyDeletion {
	type: 'PROPERTY_DELETION'
	property: PropertySummary
}

interface PropertyUpdate {
	type: 'PROPERTY_UPDATE'
	oldProperty: PropertySummary
	newProperty: PropertySummary
}

/**
 * Represents a change between two versions of an Additional Information model.
 */
export type DiffDelta = PropertyAddition | PropertyDeletion | PropertyUpdate

export function prefixBasePath(fullModelPath: string) {
	if (fullModelPath.startsWith(BASE_CP_DATA_ELEMENT_PATH)) {
		return fullModelPath
	} else {
		return [BASE_CP_DATA_ELEMENT_PATH, fullModelPath].join('.')
	}
}

export function createPropertySummary(property: Property): PropertySummary {
	const summary = {
		id: property.id,
		label: property.label ?? null,
		title: property.title ?? null,
		type: getUniquePropertyType(property),
		fullModelPath: prefixBasePath(property.fullModelPath),
	}

	if (property.type === 'enum') {
		return { ...summary, type: 'enum', enum: property.enum }
	} else if (property.type === 'object') {
		return { ...summary, type: 'object', descendantFullModelPaths: collectDescendantIDs(property) }
	} else {
		return summary as GenericPropertySummary
	}
}

export function createPropertyAddition(property: Property): PropertyAddition {
	return {
		type: 'PROPERTY_ADDITION',
		property: createPropertySummary(property),
	}
}

export function createPropertyDeletion(property: Property): PropertyDeletion {
	return {
		type: 'PROPERTY_DELETION',
		property: createPropertySummary(property),
	}
}

export function createPropertyUpdate(oldProperty: Property, newProperty: Property): PropertyUpdate {
	return {
		type: 'PROPERTY_UPDATE',
		oldProperty: createPropertySummary(oldProperty),
		newProperty: createPropertySummary(newProperty),
	}
}

type PropertyMap = { [id: string]: Property }

/**
 * Recursively traverses the Additional Information tree model and produces a
 * mapping that associates property IDs to the properties themselves.
 */
function indexPropertiesByID(properties: Property[]): PropertyMap {
	return properties.reduce((mapping, property) => {
		const children = isGroupProperty(property) ? property.properties : []
		return {
			...mapping,
			...indexPropertiesByID(children),
			[property.id]: property,
		}
	}, {})
}

/**
 * Traverses an Additional Information property and returns the list of the
 * full model paths of every descendant property.
 */
function collectDescendantIDs(property: Property): string[] {
	if (!isGroupProperty(property)) {
		return [prefixBasePath(property.fullModelPath)]
	} else {
		return property.properties.flatMap(collectDescendantIDs)
	}
}

/**
 * Returns the union of two sets.
 */
function union<T>(setA: Set<T>, setB: Set<T>): Set<T> {
	const result = new Set(setA)
	for (const element of setB) {
		result.add(element)
	}
	return result
}

/**
 * Returns the difference between two sets.
 */
function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
	const result = new Set(setA)
	for (const element of setB) {
		result.delete(element)
	}
	return result
}

/**
 * Returns the intersection of two sets.
 */
function intersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
	const result = new Set<T>()
	for (const element of setB) {
		if (setA.has(element)) {
			result.add(element)
		}
	}
	return result
}

/**
 * Returns true if the two sets are disjoint; false otherwise.
 */
function isDisjoint<T>(setA: Set<T>, setB: Set<T>): boolean {
	return intersection(setA, setB).size === 0
}

/**
 * Compares two different versions of the Additional Information model and
 * returns an array of [[DiffDelta]] that summarize the differences between the
 * two versions.
 */
export function diffModel(oldModel: Property[], newModel: Property[]): DiffDelta[] {
	const oldModelIndex = indexPropertiesByID(oldModel)
	const newModelIndex = indexPropertiesByID(newModel)

	const oldPropertyIDs = new Set(Object.keys(oldModelIndex))
	const newPropertyIDs = new Set(Object.keys(newModelIndex))
	const allPropertyIDs = union(oldPropertyIDs, newPropertyIDs)

	const addedPropertyIDs = difference(newPropertyIDs, oldPropertyIDs)
	const removedPropertyIDs = difference(oldPropertyIDs, newPropertyIDs)
	const addedOrRemovedPropertyIDs = union(addedPropertyIDs, removedPropertyIDs)
	const retainedPropertyIDs = difference(allPropertyIDs, addedOrRemovedPropertyIDs)

	const updatedPropertyIDs = [...retainedPropertyIDs].filter((id) => {
		const oldProperty = oldModelIndex[id]
		const newProperty = newModelIndex[id]

		const isPropertyChanged =
			getUniquePropertyType(oldProperty) !== getUniquePropertyType(newProperty)

		const isFullModelPathChanged = oldProperty['fullModelPath'] !== newProperty['fullModelPath']

		const isEnumValueChanged =
			'enum' in oldProperty &&
			'enum' in newProperty &&
			!oldProperty.enum.every((v) => newProperty.enum.includes(v))

		return isPropertyChanged || isFullModelPathChanged || isEnumValueChanged
	})

	const additionChanges = [...addedPropertyIDs]
		.map((id) => newModelIndex[id])
		.map(createPropertyAddition)

	const deletionChanges = [...removedPropertyIDs]
		.map((id) => oldModelIndex[id])
		.map(createPropertyDeletion)

	const updateChanges = [...updatedPropertyIDs]
		.map((id) => ({ oldProperty: oldModelIndex[id], newProperty: newModelIndex[id] }))
		.map(({ oldProperty, newProperty }) => createPropertyUpdate(oldProperty, newProperty))

	return [additionChanges, deletionChanges, updateChanges].flat()
}

/**
 * A traversal that focuses from a form mapping (a list of FormField) into the
 *  SingleMappableField elements that match the specified fullModelPath.
 */
export const singleMappableFieldTraversal = (
	fullModelPath: string
): T.Traversal<FormField[], SingleMappableField> =>
	pipe(
		T.fromTraversable(A.Traversable)<FormField>(),
		T.composePrism(singleMappableField),
		T.filter((field) => O.toNullable(field.element) === fullModelPath)
	)

/**
 * A traversal that focuses from a form mapping (a list of FormField) into the
 *  MappableField elements that match the specified fullModelPath.
 */
export const mappableFieldTraversal = (
	fullModelPath: string
): T.Traversal<FormField[], MappableField> =>
	pipe(
		T.fromTraversable(A.Traversable)<FormField>(),
		T.composePrism(mappableField),
		T.filter((field) => A.compact(field.element).includes(fullModelPath as FullyQualifiedPath))
	)

/**
 * A traversal that focuses from a form mapping (a list of FormField) into the
 * RadioGroup fields that match the specified fullModelPath.
 */
export const radioGroupTraversal = (fullModelPath: string): T.Traversal<FormField[], RadioGroup> =>
	pipe(
		T.fromTraversable(A.Traversable)<FormField>(),
		T.composePrism(radioGroup),
		T.filter((group) => A.compact(group.element).includes(fullModelPath as FullyQualifiedPath))
	)

/**
 * A traversal that focuses from a form mapping (a list of FormField) into the
 * RadioOption fields that match the specified group ID in the form mapping.
 */
export const radioOptionTraversal = (radioGroupId: string): T.Traversal<FormField[], RadioOption> =>
	pipe(
		T.fromTraversable(A.Traversable)<FormField>(),
		T.composePrism(radioOption),
		T.filter((option) => option.parent === radioGroupId)
	)

/**
 * A traversal that focuses from a form mapping (a list of FormField) into the
 * enum values on Checkbox fields that match the specified fullModelPath.
 */
export const checkboxEnumValuesTraversal = (
	fullModelPath: string
): T.Traversal<FormField[], O.Option<string>[]> =>
	pipe(
		T.fromTraversable(A.Traversable)<FormField>(),
		T.composePrism(checkboxField),
		T.filter((cb) => A.compact(cb.element).includes(fullModelPath as FullyQualifiedPath)),
		T.composeLens(conditionalId)
	)

/**
 * A traversal that focuses from a form mapping (a list of FormField) into the
 * enum values on RadioOption fields that match the specified group ID.
 */
export const radioOptionEnumValuesTraversal = (
	radioGroupId: string
): T.Traversal<FormField[], O.Option<string>[]> =>
	pipe(
		radioOptionTraversal(radioGroupId),
		T.composeOptional(conditionalOptional),
		T.composePrism(enumConditional),
		T.composeLens(Lens.fromProp<RadioEnumConditional>()('ids'))
	)

/**
 * Unmaps the mapped property on a [[SingleMappableField]].
 */
export const applyDeletionChangeToSingleMappableField = (
	field: SingleMappableField
): SingleMappableField => {
	const elementLens = Lens.fromProp<SingleMappableField>()('element')
	return elementLens.set(O.none)(field)
}

/**
 * Unmaps the mapped properties with the specified fullModelPath on a
 * [[MappableField]].
 */
export const applyDeletionChangeToMappableField =
	(fullModelPath: string) =>
	(field: MappableField): MappableField => {
		const elementLens = Lens.fromProp<MappableField>()('element')
		const updatedElements = pipe(
			elementLens.get(field),
			A.map(O.filter((path) => path !== fullModelPath))
		)
		return elementLens.set(updatedElements)(field)
	}

/**
 * Unmaps the mapped property from a [[RadioOption]].
 */
export const applyDeletionChangeToRadioOption = (field: RadioOption): RadioOption => {
	const conditionalLens = Lens.fromProp<RadioOption>()('conditional')
	return conditionalLens.set(O.none)(field)
}

/**
 * Returns a new copy of the form mapping with all of the properties
 * containing the specified fullModelPath omitted.
 */
export function applyDeletionChange(formMapping: FormField[], fullModelPath: string): FormField[] {
	const radioGroups = T.getAll(formMapping)(radioGroupTraversal(fullModelPath))
	const radioGroupIDsToBeUpdated = radioGroups.map((g) => g.id)

	const applyDeletionChangesToRadioOptions = (formMapping: FormField[]) =>
		radioGroupIDsToBeUpdated.reduce(
			(mapping, radioGroupID) =>
				pipe(
					radioOptionTraversal(radioGroupID),
					T.modify(applyDeletionChangeToRadioOption)
				)(mapping),
			formMapping
		)

	const applyDeletionChangesToSingleMappableFields = pipe(
		singleMappableFieldTraversal(fullModelPath),
		T.modify(applyDeletionChangeToSingleMappableField)
	)

	const applyDeletionChangesToMappableFields = pipe(
		mappableFieldTraversal(fullModelPath),
		T.modify(applyDeletionChangeToMappableField(fullModelPath))
	)

	return pipe(
		formMapping,
		applyDeletionChangesToRadioOptions,
		applyDeletionChangesToSingleMappableFields,
		applyDeletionChangesToMappableFields
	)
}

/**
 * Removes mapped enum values that no longer exist in the enum property definition.
 */
const removeDeletedEnumValues =
	(propertyEnumValues: string[]) =>
	(mappedEnumValues: O.Option<string>[]): O.Option<string>[] =>
		mappedEnumValues.filter((element) => {
			return O.isSome(element) && propertyEnumValues.includes(element.value)
		})

/**
 * Removes mapped enum values that no longer exist in the form mapping.
 *
 * <Precondition> The changes to the property fullModelPaths have already been applied.
 * The traversal focuses on fields mapped to delta.newProperty.fullModelPath.
 */
const applyUpdateChangesToEnumProperties =
	(delta: PropertyUpdate) =>
	(formMapping: FormField[]): FormField[] => {
		if (delta.oldProperty.type !== 'enum' || delta.newProperty.type !== 'enum') {
			return formMapping
		}

		const fullModelPath = delta.newProperty.fullModelPath
		const enumValues = delta.newProperty.enum

		const findRadioGroups = T.getAll(formMapping)(radioGroupTraversal(fullModelPath))
		const radioGroupIDsToBeUpdated = findRadioGroups.map((radioGroup) => radioGroup.id)

		const applyUpdateChangesToRadioOptions = (formMapping: FormField[]) =>
			radioGroupIDsToBeUpdated.reduce((mapping, radioGroupID) => {
				const radioOptions = radioOptionEnumValuesTraversal(radioGroupID)
				return T.modify(removeDeletedEnumValues(enumValues))(radioOptions)(mapping)
			}, formMapping)

		const applyUpdateChangesToCheckboxes = pipe(
			checkboxEnumValuesTraversal(fullModelPath),
			T.modify(removeDeletedEnumValues(enumValues))
		)

		return pipe(formMapping, applyUpdateChangesToRadioOptions, applyUpdateChangesToCheckboxes)
	}

/**
 * Returns a new copy of the form mapping with all of the properties containing
 * the fullModelPath of the old property remapped with the updated fullModelPath.
 */
function applyUpdateChange(formMapping: FormField[], delta: PropertyUpdate): FormField[] {
	const applyUpdateChangesToSingleMappableFields = pipe(
		singleMappableFieldTraversal(delta.oldProperty.fullModelPath),
		T.composeLens(Lens.fromProp<SingleMappableField>()('element')),
		T.set(O.some(delta.newProperty.fullModelPath))
	)

	const applyUpdateChangesToMappableFields = pipe(
		mappableFieldTraversal(delta.oldProperty.fullModelPath),
		T.composeLens(Lens.fromProp<MappableField>()('element')),
		T.composeTraversal(T.fromTraversable(A.Traversable)<ElementRef>()),
		T.filter((ref) => O.isSome(ref) && ref.value === delta.oldProperty.fullModelPath),
		T.set(O.some(delta.newProperty.fullModelPath))
	)

	return pipe(
		formMapping,
		applyUpdateChangesToSingleMappableFields,
		applyUpdateChangesToMappableFields,
		applyUpdateChangesToEnumProperties(delta)
	)
}

/**
 * Determines whether an update delta should be treated as a deletion change or
 * a renaming of the fullModelPath and then performs the change.
 */
export function transformAndApplyUpdateChange(
	formMapping: FormField[],
	delta: PropertyUpdate
): FormField[] {
	if (delta.oldProperty.type !== delta.newProperty.type) {
		return applyDeletionChange(formMapping, delta.oldProperty.fullModelPath)
	} else {
		return applyUpdateChange(formMapping, delta)
	}
}

/**
 * Applies a single diff change to the form mapping, returning a new copy of the
 * form mapping.
 */
export function applyChange(formMapping: FormField[], delta: DiffDelta): FormField[] {
	switch (delta.type) {
		case 'PROPERTY_ADDITION':
			// Adding a new property to the Additional Information
			// model does not affect any existing form mappings.
			return formMapping
		case 'PROPERTY_DELETION':
			return applyDeletionChange(formMapping, delta.property.fullModelPath)
		case 'PROPERTY_UPDATE':
			return transformAndApplyUpdateChange(formMapping, delta)
	}
}

/**
 * Applies all of the diff changes to the form mapping, returning a new copy of
 * the form mapping.
 */
export function applyChangesToFormMapping(formMapping: FormField[], deltas: DiffDelta[]) {
	return deltas.reduce(applyChange, formMapping)
}

export interface FormFieldSummary {
	name: string
	type: FormField['type']
	fullModelPath: string
	enumValues: string[]
}

export interface FormFieldPropertyNoop {
	type: 'FIELD_PROPERTY_NOOP'
	delta: PropertyAddition
}

export interface FormFieldGroupPropertyChange {
	type: 'FIELD_GROUP_PROPERTY_CHANGE'
	delta: PropertyDeletion | PropertyUpdate
	hasAffectedDescendants: boolean
}

export interface FormFieldPropertyUnmap {
	type: 'FIELD_PROPERTY_UNMAP'
	delta: PropertyDeletion | PropertyUpdate
	affectedFields: FormFieldSummary[]
}

export interface FormFieldEnumUnmap {
	type: 'FIELD_ENUM_UNMAP'
	delta: PropertyUpdate
	affectedFields: FormFieldSummary[]
	enumValuesToBeUnmapped: string[]
}

export interface FormFieldPropertyRename {
	type: 'FIELD_PROPERTY_RENAME'
	delta: PropertyUpdate
	affectedFields: FormFieldSummary[]
}

export type FormMappingChange =
	| FormFieldPropertyNoop
	| FormFieldGroupPropertyChange
	| FormFieldPropertyUnmap
	| FormFieldEnumUnmap
	| FormFieldPropertyRename

/**
 * Returns `true` if applying the specified change will have an effect on the
 * form mapping; false otherwise.
 */
export function hasEffectOnFormMapping(change: FormMappingChange): boolean {
	const isGroupChange = 'hasAffectedDescendants' in change && change.hasAffectedDescendants
	const hasAffectedFields = 'affectedFields' in change && change.affectedFields.length > 0
	return isGroupChange || hasAffectedFields
}

/**
 * Returns the enum values that are mapped to the specified field. If the field
 * is not capable of representing enum values or if it doesn't have any mapped
 * enum values, an empty list is returned.
 */
function getEnumValues(formMapping: FormField[], field: FormField): string[] {
	const checkboxEnumValues = pipe(
		checkboxField.composeLens(conditionalId).getOption(field),
		O.fold(() => [], A.compact)
	)

	const radioEnumValues = pipe(
		[...T.getAll(formMapping)(radioOptionEnumValuesTraversal(field.id))],
		A.flatten,
		A.compact
	)

	return checkboxEnumValues.concat(radioEnumValues)
}

/**
 * Finds all fields in the form mapping that contain a mapped property with the
 * specified fullModelPath. Returns the field name, field type, and list of enum
 * values mapped (if applicable).
 */
function findFieldsWithProperty(
	formMapping: FormField[],
	fullModelPath: string
): FormFieldSummary[] {
	const singleMappableFields = T.getAll(formMapping)(singleMappableFieldTraversal(fullModelPath))
	const mappableFields = T.getAll(formMapping)(mappableFieldTraversal(fullModelPath))
	const allFieldsWithProperty: FormField[] = [...singleMappableFields, ...mappableFields]

	return allFieldsWithProperty.map((field) => ({
		name: O.getOrElse(() => field.id as string)(field.metadata.name),
		type: field.type,
		fullModelPath: fullModelPath,
		enumValues: getEnumValues(formMapping, field),
	}))
}

/**
 * Associates a diff delta with the changes that would take effect to the form
 * mapping if applied.
 */
export const previewChangeToFormMapping =
	(formMapping: FormField[]) =>
	(delta: DiffDelta): FormMappingChange[] => {
		const propertyChanges: FormMappingChange[] = []

		switch (delta.type) {
			case 'PROPERTY_ADDITION': {
				propertyChanges.push({
					type: 'FIELD_PROPERTY_NOOP',
					delta,
				})
				break
			}

			case 'PROPERTY_DELETION': {
				propertyChanges.push({
					type: 'FIELD_PROPERTY_UNMAP',
					affectedFields: findFieldsWithProperty(formMapping, delta.property.fullModelPath),
					delta,
				})
				break
			}

			case 'PROPERTY_UPDATE': {
				const matchingFields = findFieldsWithProperty(formMapping, delta.oldProperty.fullModelPath)

				if (delta.oldProperty.type !== delta.newProperty.type) {
					propertyChanges.push({
						type: 'FIELD_PROPERTY_UNMAP',
						affectedFields: matchingFields,
						delta,
					})
				} else if (delta.oldProperty.fullModelPath !== delta.newProperty.fullModelPath) {
					propertyChanges.push({
						type: 'FIELD_PROPERTY_RENAME',
						affectedFields: matchingFields,
						delta,
					})
				}

				if (delta.oldProperty.type === 'enum' && delta.newProperty.type === 'enum') {
					const oldEnumValues = new Set(delta.oldProperty.enum)
					const newEnumValues = new Set(delta.newProperty.enum)
					const enumValuesToBeUnmapped = [...difference(oldEnumValues, newEnumValues)]

					if (enumValuesToBeUnmapped.length > 0) {
						propertyChanges.push({
							type: 'FIELD_ENUM_UNMAP',
							affectedFields: matchingFields,
							enumValuesToBeUnmapped,
							delta,
						})
					}
				}
				break
			}
		}

		return propertyChanges
	}

function isGroupPropertyDelta(delta: DiffDelta): boolean {
	return 'property' in delta
		? delta.property.type === 'object'
		: delta.oldProperty.type === 'object'
}

function sortFormMappingChanges(changes: FormMappingChange[]): FormMappingChange[] {
	return changes.sort((change1, change2) => {
		const getFullModelPath = (change: FormMappingChange) =>
			'property' in change.delta
				? change.delta.property.fullModelPath
				: change.delta.oldProperty.fullModelPath

		return getFullModelPath(change1) < getFullModelPath(change2) ? -1 : 1
	})
}

export const previewChangesToFormMapping =
	(formMapping: FormField[]) =>
	(deltas: DiffDelta[]): FormMappingChange[] => {
		const { left: propertyDeltas, right: groupPropertyDeltas } =
			A.partition(isGroupPropertyDelta)(deltas)

		// Find form changes for all non-group properties.
		const formChanges = propertyDeltas.flatMap(previewChangeToFormMapping(formMapping))

		// Find the affected full model paths for all non-group properties.
		const affectedFullModelPaths = formChanges.filter(hasEffectOnFormMapping).flatMap((change) => {
			return 'affectedFields' in change ? change.affectedFields.map((f) => f.fullModelPath) : []
		})

		// Cross reference the affected properties with the descendant properties of
		// the group property to determine if updates to a group property have any
		// effect on the existing form mapping.
		const groupFormChanges: FormMappingChange[] = groupPropertyDeltas.flatMap((delta) => {
			let descendantFullModelPaths: string[] = []

			if (delta.type === 'PROPERTY_ADDITION') {
				return []
			} else if (delta.type === 'PROPERTY_DELETION' && delta.property.type === 'object') {
				descendantFullModelPaths = delta.property.descendantFullModelPaths
			} else if (delta.type === 'PROPERTY_UPDATE' && delta.oldProperty.type === 'object') {
				descendantFullModelPaths = delta.oldProperty.descendantFullModelPaths
			}

			return [
				{
					type: 'FIELD_GROUP_PROPERTY_CHANGE',
					hasAffectedDescendants: !isDisjoint(
						new Set(affectedFullModelPaths),
						new Set(descendantFullModelPaths)
					),
					delta,
				},
			]
		})

		return sortFormMappingChanges(formChanges.concat(groupFormChanges))
	}
