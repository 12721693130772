<template>
	<div v-if="wizardStep === WizardStep.EDIT" class="additional-information-edit-container">
		<div class="properties-header">
			<span>Additional Information</span>
		</div>
		<div class="properties-body-left">
			<property-panel></property-panel>
		</div>
		<div class="properties-body-right">
			<attribute-panel
				:selectedAdditionalProperty="selectedAdditionalProperty"
				@update="updateAdditionalProperty"
			></attribute-panel>
		</div>
		<div class="properties-footer">
			<button class="ms-button ms-slim" @click="closeHandler">Discard</button>
			<button class="ms-button ms-slim" @click="switchToPreviewStep">Preview</button>
		</div>
	</div>
	<div
		v-else-if="wizardStep === WizardStep.PREVIEW"
		class="additional-information-preview-container"
	>
		<div class="properties-header">
			<span>Additional Information</span>
		</div>
		<div class="properties-body">
			<preview-panel @has-mapped-field-changes="updateMappedFieldsNeeded" />
		</div>
		<div class="properties-footer">
			<button class="ms-button ms-slim" @click="switchToEditStep">Back</button>
			<button class="ms-button ms-slim" @click="save" :disabled="!isValid || isSaving">
				{{ isSaving ? 'Saving...' : 'Save' }}
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import PropertyPanel from '@/components/AdditionalInformation/Panels/Properties/PropertyPanel.vue'
import AttributePanel from '@/components/AdditionalInformation/Panels/Attributes/AttributePanel.vue'
import PreviewPanel from '@/components/AdditionalInformation/Panels/Preview/PreviewPanel.vue'

import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useEditorStore } from '@/modules/editor/store'
import { useFormSaving } from '@/modules/editor/save'
import { UUID } from 'io-ts-types/lib/UUID'
import { useRoute } from 'vue-router'
import { toast } from '@/components/Toast'
import { Property } from '@/models/form/additional-information'

export default defineComponent({
	name: 'AdditionalInformation',
	components: {
		PropertyPanel,
		AttributePanel,
		PreviewPanel,
	},
	props: {
		closeHandler: {
			type: Function,
			required: true,
		},
	},
	setup() {
		const store = useEditorStore()
		const isValid = computed<boolean>(() => {
			return store.getters['propertiesValidForSave']
		})
		const formId = ref<UUID | null>(null)
		const isSaving = ref<boolean>(false)
		const mappedFieldsChanges = ref<boolean>(false)
		let saveChanges = (_?: boolean) => {
			toast('formId does not exist')
		}
		const selectedAdditionalProperty = computed<Property>(() => {
			return store.getters['selectedAdditionalProperty']
		})

		enum WizardStep {
			EDIT,
			PREVIEW,
		}

		const wizardStep = ref<WizardStep>(WizardStep.EDIT)
		const switchToEditStep = () => (wizardStep.value = WizardStep.EDIT)
		const switchToPreviewStep = () => (wizardStep.value = WizardStep.PREVIEW)

		const save = () => {
			if (isValid.value) {
				store.commit('saveAdditionalInformation', null)
				saveChanges(mappedFieldsChanges.value)
			}
		}

		onBeforeMount(() => {
			store.commit('setSelectedAdditionalProperty', null)
			store.commit('revalidateAdditionalProperties')
		})

		const route = useRoute()
		onMounted(() => {
			formId.value = route.params.id as UUID
			mappedFieldsChanges.value = false
			const context = useFormSaving(formId.value)
			saveChanges = context.saveAdditionalInformationChanges
			watch(
				() => context.isSaving.value,
				() => (isSaving.value = context.isSaving.value)
			)
		})

		const updateAdditionalProperty = (property: Property) => {
			store.commit('updateAdditionalProperty', property)
		}

		const updateMappedFieldsNeeded = (event: boolean) => {
			mappedFieldsChanges.value = event
		}

		return {
			isValid,
			isSaving,
			updateMappedFieldsNeeded,
			save,

			updateAdditionalProperty,
			selectedAdditionalProperty,

			WizardStep,
			wizardStep,
			switchToEditStep,
			switchToPreviewStep,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.additional-information-edit-container {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	grid-template-rows: 1fr minmax(50%, 100%) 1fr;
	grid-template-areas:
		'properties-header properties-header'
		'properties-body-left properties-body-right'
		'properties-footer properties-footer';
	padding: 10px;
}

.additional-information-preview-container {
	display: grid;
	height: 100%;
	grid-template-columns: 1fr 1.5fr;
	grid-template-rows: 1fr minmax(50%, 100%) 1fr;
	grid-template-areas:
		'properties-header properties-header'
		'properties-body properties-body'
		'properties-footer properties-footer';
	padding: 10px;
}

.properties-header {
	grid-area: properties-header;
	text-align: center;
	padding: 10px;
	font-size: 24px;
	border-bottom: 2px solid $myndshft-gray-900;
}

.properties-body {
	padding-top: 16px;
	grid-area: properties-body;
	min-height: 520px;
	overflow-y: scroll;
}

.properties-body-left {
	grid-area: properties-body-left;
	min-height: 520px;
	border-right: 2px solid $myndshft-gray-900;
	border-left: 2px solid $myndshft-gray-900;
}

.properties-body-right {
	grid-area: properties-body-right;
	min-height: 520px;
	border-right: 2px solid $myndshft-gray-900;
}

.properties-footer {
	height: 80px;
	padding: 10px;
	grid-area: properties-footer;
	border-top: 2px solid $myndshft-gray-900;
	text-align: right;

	button {
		width: 100px;
		margin-right: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
</style>
