<template>
	<div>
		<div class="color-square" :style="boxColorStyleVars"></div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { unmappedFieldColors } from '@/models/form/definition/field'

export default defineComponent({
	name: 'ColorPreview',
	props: {
		backgroundColor: {
			type: Number,
			default: () => unmappedFieldColors.backgroundColor,
		},
		borderColor: {
			type: Number,
			default: () => unmappedFieldColors.borderColor,
		},
	},
	setup(props) {
		const numberToRGBPairConversion = (num?: number): string => {
			const base = 16
			if (!num) return ''
			return '#' + num.toString(base).padStart(6, '0')
		}

		/**
		 * Referenced discussion on how to pass props to css:
		 * https://stackoverflow.com/questions/42872002/in-vue-js-component-how-to-use-props-in-css
		 */
		const boxColorStyleVars = computed(() => {
			return {
				'--preview-background-color': numberToRGBPairConversion(props.backgroundColor),
				'--preview-border-color': numberToRGBPairConversion(props.borderColor),
			}
		})
		return { boxColorStyleVars }
	},
})
</script>

<style scoped lang="scss">
.color-square {
	width: 10px;
	height: 10px;
	border: var(--preview-border-color) 1px solid;
	background-color: var(--preview-background-color);
}
</style>
