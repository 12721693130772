<template>
	<div v-if="mappable">
		<section>
			<div class="toggle-labels">
				<label
					:toggled="!showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Data Model
				</label>
				<label
					:toggled="showAdditionalInformationModel"
					class="toggle-label"
					@click="toggleModelType"
				>
					Additional Information
				</label>
			</div>
			<div v-if="showAdditionalInformationModel">
				<vue-select
					label="name"
					@update="setElement"
					:value="toNullable(elementRef)"
					:options="filteredAdditionalDataModel"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
			<div v-else>
				<vue-select
					label="name"
					@update="setElement"
					:value="toNullable(elementRef)"
					:options="filteredDataModel"
					:reducer="(element) => element.id"
				></vue-select>
			</div>
		</section>
		<UnmappableConversion />
	</div>
	<div v-else>
		<MappableConversion />
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeMount, watch, PropType } from 'vue'
import VueSelect from '@/components/Select/VueSelect.vue'
import MappableConversion from '../MappableConversion.vue'
import UnmappableConversion from '../UnmappableConversion.vue'

import * as O from 'fp-ts/lib/Option'

import { RadioGroup } from '@/models/form/definition/field/variants/radio'
import { findByID } from '@/models/data/model'
import { sortOptions } from '@/components/Shared/shared'
import { QualifiedDataElement } from '@/models/data/elements'
import { BASE_CP_DATA_ELEMENT_PATH } from '@/models/form/additional-information'
import { element } from '@/models/form/definition/field/traits/mappable'
import { FullyQualifiedPath } from '@/models/form/definition/field/element/path'

export default defineComponent({
	name: 'RadioGroupEditor',
	components: {
		VueSelect,
		MappableConversion,
		UnmappableConversion,
	},
	props: {
		field: {
			type: Object as PropType<RadioGroup>,
			required: true,
		},
		dataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		additionalDataModel: {
			type: Array as PropType<QualifiedDataElement[]>,
			default: () => [],
		},
		mappable: {
			type: Boolean,
			default: () => false,
		},
	},
	setup(props, context) {
		const showAdditionalInformationModel = ref<boolean>(false)

		const filterOptions = (options: QualifiedDataElement[]): QualifiedDataElement[] => {
			return options.filter((x) => ['BOOLEAN', 'ENUM'].includes(x.type)).sort(sortOptions)
		}

		const filteredAdditionalDataModel = computed<QualifiedDataElement[]>(() =>
			filterOptions(props.additionalDataModel)
		)
		const filteredDataModel = computed<QualifiedDataElement[]>(() => filterOptions(props.dataModel))

		const elementPath = computed(() =>
			props.field.element.length > 0 ? props.field.element[0] : O.none
		)

		const elementRef = computed(() => {
			const fullDataModel = props.dataModel.concat(props.additionalDataModel)
			const fullyQualifiedPath = O.toNullable(elementPath.value)
			return findByID(fullDataModel)(fullyQualifiedPath as string)
		})

		const setElement = (path: FullyQualifiedPath | null) => {
			context.emit('update', element.set(path ? [O.some(path)] : [])(props.field))
			context.emit('resetRadioOptions', props.field.id)
		}

		const toggleModelType = () => {
			context.emit('update', element.set([])(props.field))
			context.emit('resetRadioOptions', props.field.id)
			showAdditionalInformationModel.value = !showAdditionalInformationModel.value
		}

		const checkModelType = () => {
			showAdditionalInformationModel.value =
				O.toNullable(elementRef.value)?.id?.includes(BASE_CP_DATA_ELEMENT_PATH) ?? false
		}

		onBeforeMount(() => checkModelType())

		watch(
			() => props.field.id,
			() => checkModelType()
		)

		return {
			elementRef,
			filteredDataModel,
			showAdditionalInformationModel,
			filteredAdditionalDataModel,
			toggleModelType,
			setElement,
			toNullable: O.toNullable,
		}
	},
})
</script>

<style lang="scss" scoped>
section {
	margin: 8px;
}
</style>
