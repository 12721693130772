import { ApiError as MyndmapApiError, getApiErrorMessage } from '@/modules/api'

export type LoaderError = { type: 'LOADER' }
export type ApiError = { type: 'API'; context: MyndmapApiError }
export type FormRenderError = LoaderError | ApiError

/**
 * Data Constructor for [[FormRenderError]].
 */
export function loaderError(): FormRenderError {
	return { type: 'LOADER' }
}

/**
 * Data Constructor for [[FormRenderError]].
 */
export function apiError(context: MyndmapApiError): FormRenderError {
	return { type: 'API', context }
}

/**
 * Extracts an error message from [[FormRenderError]].
 */
export function getFormRenderErrorMessage(error: FormRenderError): string {
	switch (error.type) {
		case 'LOADER':
			return 'Failed to load form pages'
		case 'API':
			return getApiErrorMessage(error.context)
	}
}
