<template>
	<div class="pa-col padding-10">
		<label for="enum-input" :valid="isValid">Enum Options</label>
		<div>
			<input
				id="enum-input"
				class="enum-input"
				v-model.trim="inputValue"
				@input="validate"
				@keypress.enter.prevent="addOption"
			/>
			<button class="ms-button ms-slim add-btn" @click="addOption" :disabled="disableAddBtn">
				Add
			</button>
		</div>
	</div>

	<div v-for="option of options" :key="option" class="option">
		<span class="option-btn" @click="removeOption(option)">
			<font-awesome-icon class="btn-icon" icon="times" />
		</span>
		{{ option }}
	</div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed, onMounted } from 'vue'
import { EnumProperty } from '@/models/form/additional-information/property-types'
import { enumPropertyIsValid } from '@/modules/editor/additional-information/validation'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'

export default defineComponent({
	name: 'EnumAttributes',
	components: {
		FontAwesomeIcon,
	},
	props: {
		enumProperty: {
			type: Object as PropType<EnumProperty>,
			required: true,
		},
	},
	emits: ['update'],
	setup(props, context) {
		const property = ref({ ...props.enumProperty })
		const options = computed(() => props.enumProperty.enum || [])
		const inputValue = ref('')
		const isValid = ref(false)
		const disableAddBtn = computed(() => !isValid.value || !inputValue.value)

		function validate() {
			isValid.value = pipe(
				enumPropertyIsValid(
					{
						...property.value,
						enum: options.value,
					},
					inputValue.value
				),
				E.isRight
			)
		}

		function addOption() {
			if (isValid.value) {
				context.emit('update', {
					...property.value,
					enum: options.value.concat(inputValue.value).sort(),
				})
				inputValue.value = ''
			}
		}

		function removeOption(value: string) {
			property.value.enum = property.value.enum.filter((v) => v !== value)
			context.emit('update', { ...property.value })
		}

		watch(
			() => props.enumProperty,
			(enumProperty) => {
				property.value = { ...(enumProperty as EnumProperty) }
				validate()
			}
		)

		onMounted(() => {
			validate()
		})

		return {
			isValid,
			options,
			property,
			inputValue,
			disableAddBtn,

			validate,
			addOption,
			removeOption,
		}
	},
})
</script>

<style lang="scss" scoped>
.enum-input {
	width: 65%;
}

.add-btn {
	margin-left: 20px;
}

.option {
	padding-left: 10px;
	margin-bottom: 5px;

	&-btn {
		margin-right: 8px;

		&:hover {
			cursor: pointer;
		}

		& > .btn-icon:hover {
			transform: translateY(-1px);
		}
	}
}
</style>
