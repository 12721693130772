import { computed, ref } from 'vue'
import { toast } from '@/components/Toast'

import { useEditorStore } from '@/modules/editor/store'
import { useConfigStore } from '@/modules/mynd-config'
import { useMyndmapApi } from '@/modules/api'

import { UUID } from 'io-ts-types/lib/UUID'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

/**
 * Composition hook that enables saving forms to the backend
 * server.
 */
export function useFormSaving(id: UUID) {
	const store = useEditorStore()
	const configStore = useConfigStore()
	const config = computed(() => configStore.getters['getConfig'])
	const { saveForm, saveFormAdditionalInformation, saveFormDefinition } = useMyndmapApi(config)

	const form = computed(() => O.toNullable(store.state.form))
	const isSaving = ref(false)

	const saveAdditionalInformationChanges = async (formMappingUpdated?: boolean) => {
		if (isSaving.value) return

		if (form.value == null || !form.value?.additionalInformation) {
			toast('Unable to save additional information — please try again later')
			return
		}

		isSaving.value = true
		const result = await saveFormAdditionalInformation(id, form.value, formMappingUpdated)()
		E.fold(
			() => toast('Failed to save additional information'),
			() => {
				toast('Additional information saved successfully')
				store.commit('loadForm', form.value)
			}
		)(result)

		isSaving.value = false
	}

	const saveDefinitionChanges = async () => {
		if (isSaving.value) return

		if (form.value == null || !form.value?.definition) {
			toast('Unable to save form — please try again later')
			return
		}

		isSaving.value = true

		const result = await saveFormDefinition(id, form.value)()
		E.fold(
			() => toast('Failed to save form fields'),
			() => {
				toast('Form fields saved successfully')
				store.commit('loadForm', form.value)
			}
		)(result)

		isSaving.value = false
	}

	async function saveChanges() {
		if (isSaving.value) return

		if (form.value == null) {
			toast('Unable to save form — please try again later')
			return
		}

		isSaving.value = true
		const result = await saveForm(id, form.value)()

		E.fold(
			() => toast('Failed to save form'),
			() => {
				toast('Form saved successfully')
				store.commit('loadForm', form.value)
			}
		)(result)

		isSaving.value = false
	}

	return {
		isSaving,
		saveChanges,
		saveDefinitionChanges,
		saveAdditionalInformationChanges,
	}
}
