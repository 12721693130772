import { Ref, ref, isRef, watch, onUnmounted } from 'vue'
import { PayloadSender, State } from 'xstate'

import { Context, Schema, Event } from '@/modules/editor/toolbox/types'
import { KeyHandler, useHotkeys } from '@/modules/hotkeys'
import { Coordinates } from '@/components/Modal/types'
import { ZoomDirection } from './types'

import { constVoid } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as PIXI from 'pixi.js-legacy'

/**
 * Zoom in or out with the stage camera using the given scale factor, direction
 * ([[ZoomDirection.IN]] or [[ZoomDirection.OUT]]), the anchor point, and
 * PIXI.js container. Zoom-in and zoom-out operations parameterized by the same
 * scale factor are inverse operations. If the anchor point is a ref, then its
 * value will be unwrapped. Anchor point coordinates are specified relative to
 * the viewport:
 *
 * ```
 * anchorPoint ∈ { (x, y) : x ∈ [0, clientWidth) ∧ y ∈ [0, clientHeight) }
 * ```
 */
export function zoom(
	scale: number,
	direction: ZoomDirection,
	anchorPoint: Ref<Coordinates> | Coordinates,
	stage: PIXI.Container,
	zoomScale: Ref<number>
) {
	// zoomIn ∘ zoomOut === identity
	// zoomOut ∘ zoomIn === identity
	const scaleFactor = direction === ZoomDirection.IN ? scale : 1 / scale

	const newScale = {
		x: stage.scale.x * scaleFactor,
		y: stage.scale.y * scaleFactor,
	}

	// Unwrap anchor point parameter
	const oldAnchorPoint = isRef(anchorPoint) ? anchorPoint.value : anchorPoint

	// Compute the global position of the new anchor point by adding the anchor
	// point and pan offset vectors together, and then applying an adjustment to
	// the scale.
	const newAnchorPoint = {
		x: (oldAnchorPoint.x - stage.x) * (newScale.x / stage.scale.x),
		y: (oldAnchorPoint.y - stage.y) * (newScale.y / stage.scale.y),
	}

	// To compute the new pan offset, first we subtract the original anchor point
	// vector from the global position vector of the new anchor point.  Then, we
	// negate the resulting vector. The reason why we negate the resulting vector
	// is because panning works by offsetting the position of the stage. For
	// example, if we want to pan the camera to the right by 20 pixels, then the
	// x-ordinate of the stage needs to be offset by -20 pixels.
	const newPanOffset = {
		x: -(newAnchorPoint.x - oldAnchorPoint.x),
		y: -(newAnchorPoint.y - oldAnchorPoint.y),
	}

	// Update transformation matrix
	stage.scale.x = newScale.x
	stage.scale.y = newScale.y
	stage.x = newPanOffset.x
	stage.y = newPanOffset.y

	zoomScale.value = newScale.x
}

/**
 * Composition function that enables zooming in and out of a PIXI application.
 * Returns a Ref pointing to the current zoom scale.
 */
export function useZooming(
	app: Ref<O.Option<PIXI.Application>>,
	parent: Ref<O.Option<HTMLElement>>,
	state: Ref<State<Context, Event, Schema>>
) {
	const scaleFactor = 1.15 // 15%
	const zoomIn = ref<KeyHandler>(constVoid)
	const zoomOut = ref<KeyHandler>(constVoid)
	const zoomWithMouse = ref<(event: globalThis.Event) => void>(constVoid)
	const zoomScale = ref(1.0)

	const createZoomOperations = (app: PIXI.Application) => {
		const viewportCenter = {
			x: app.renderer.width / 2,
			y: app.renderer.height / 2,
		}

		// Zoom using the center of viewport as the anchor point
		zoomIn.value = () => zoom(scaleFactor, ZoomDirection.IN, viewportCenter, app.stage, zoomScale)
		zoomOut.value = () => zoom(scaleFactor, ZoomDirection.OUT, viewportCenter, app.stage, zoomScale)

		// Zoom using the mouse cursor position as the anchor point
		zoomWithMouse.value = (event: globalThis.Event) => {
			if (event instanceof WheelEvent) {
				if (state.value.matches('control.active')) {
					const mousePosition = {
						x: event.x,
						y: event.y,
					}
					const direction = event.deltaY > 0 ? ZoomDirection.OUT : ZoomDirection.IN
					zoom(scaleFactor, direction, mousePosition, app.stage, zoomScale)
				}
			}
		}
	}

	// Update event handlers for the zoom operations
	const bindZoomOperations = () => {
		if (O.isSome(app.value) && O.isSome(parent.value)) {
			createZoomOperations(app.value.value)
		}
	}

	// Zoom operations that use the center of the viewport as the anchor point
	// are dependent on the dimensions of the renderer. When the renderer
	// resizes, we need to redefine the zoom operations.
	window.addEventListener('resize', bindZoomOperations)

	watch(
		() => [app.value, parent.value],
		() => {
			if (O.isSome(app.value) && O.isSome(parent.value)) {
				createZoomOperations(app.value.value)

				// Zooming with mouse wheel
				parent.value.value.addEventListener('mousewheel', (event) => {
					zoomWithMouse.value(event)
				})
			}
		}
	)

	onUnmounted(() => {
		window.removeEventListener('resize', bindZoomOperations)
	})

	// Register keyboard shortcuts
	useHotkeys('shift + =, =', zoomIn)
	useHotkeys('shift + -, -', zoomOut)

	return { zoomScale }
}

/**
 * Composition function that enables camera panning on a PIXI application.
 * Registers DOM event listeners that enable the user to pan the camera
 * by scrolling or by dragging when the "Hand Tool" is selected.
 */
export function usePanning(
	app: Ref<O.Option<PIXI.Application>>,
	parent: Ref<O.Option<HTMLElement>>,
	state: Ref<State<Context, Event, Schema>>,
	send: PayloadSender<Event>
) {
	const bindPanOperations = (app: PIXI.Application, parent: HTMLElement) => {
		app.stage.on('mousedown', () => {
			send('START_PAN')

			// Register the listener for the 'mouseup' event globally so that
			// panning stops even when the mouse is outside of the browser.
			window.addEventListener('mouseup', () => send('STOP_PAN'), { once: true })
		})

		app.stage.on('mousemove', (event: PIXI.interaction.InteractionEvent) => {
			if (state.value.matches('tool.hand.panning.active')) {
				if (event.data.originalEvent instanceof MouseEvent) {
					app.stage.position.x += event.data.originalEvent.movementX
					app.stage.position.y += event.data.originalEvent.movementY
				}
			}
		})

		parent.addEventListener('mousewheel', (event) => {
			if (event instanceof WheelEvent) {
				// Disable multi-touch gestures
				event.preventDefault()

				if (state.value.matches('control.inactive')) {
					app.stage.position.x -= event.deltaX
					app.stage.position.y -= event.deltaY
				}
			}
		})
	}

	watch(
		() => [app.value, parent.value],
		() => {
			if (O.isSome(app.value) && O.isSome(parent.value)) {
				// Bind event handlers for the panning operations
				bindPanOperations(app.value.value, parent.value.value)
			}
		}
	)
}
